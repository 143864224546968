import React from "react";
import { HorizontalBar as LineChart } from "react-chartjs-2";
import { Spin } from "antd";
import { calcStepSize, copyObject } from "Core/Utils";

import OrgViewModel from "../../view-model/OrgViewModel";
import AuthStore from "../../Stores/AuthStore";
import DashboardStore from "../../Stores/DashboardStore";

const StatusColor = {
  ALIVE: "#00A854",
  NEW: "#108EE9",
  UNREACHABLE: "#F04134",
  REBOOTING: "#FFBF00",
};

const NodeProfiles = props => {
  const handleClick = chartElement => {
    const currentOrgId =
      props.match.params && props.match.params.id
        ? props.match.params.id
        : OrgViewModel && OrgViewModel.org && OrgViewModel.org.id
        ? OrgViewModel.org.id
        : AuthStore &&
          AuthStore.loggedInUser &&
          AuthStore.loggedInUser.organization &&
          AuthStore.loggedInUser.organization.id;

    let key =
      chartElement[0] && chartElement[0]._chart
        ? chartElement[0]._chart.data.labels[chartElement[0]._index]
        : null;
    let status_key =
      chartElement[0] && chartElement[0]._chart
        ? chartElement[0]._chart.data.datasets[chartElement[0]._datasetIndex]
            .label
        : null;
    if (Array.isArray(key)) {
      const keyLength = key.length;
      if (keyLength > 1) {
        key = key.join(" ");
      } else {
        key = key[0];
      }
    }

    if (key && status_key) {
      key = key.replace(/\s+/g, "");
      let baseUrl =
        `/dashboard/inodes/` + currentOrgId + `/profile/${key.toLowerCase()}`;
      let url = status_key
        ? `${baseUrl}/status/${status_key.toLowerCase()}`
        : baseUrl;
      props.history.push(url);
    }
  };

  let profilesCount = DashboardStore.countsByProfiles;
  let newNodeCounts = [
    profilesCount.edge.new,
    profilesCount.virtual.new,
    profilesCount.virtualedge.new,
  ];
  if (profilesCount.cloudgateway) {
    newNodeCounts.push(
      profilesCount.cloudgateway.new ? profilesCount.cloudgateway.new : 0,
    );
  }

  let unreachableNodeCounts = [
    profilesCount.edge.unreachable,
    profilesCount.virtual.unreachable,
    profilesCount.virtualedge.unreachable,
  ];
  if (profilesCount.cloudgateway) {
    unreachableNodeCounts.push(
      profilesCount.cloudgateway.unreachable
        ? profilesCount.cloudgateway.unreachable
        : 0,
    );
  }

  let aliveNodeCounts = [
    profilesCount.edge.alive,
    profilesCount.virtual.alive,
    profilesCount.virtualedge.alive,
  ];
  if (profilesCount.cloudgateway) {
    aliveNodeCounts.push(
      profilesCount.cloudgateway.alive ? profilesCount.cloudgateway.alive : 0,
    );
  }

  let rebootingNodeCounts = [
    profilesCount.edge.rebooting,
    profilesCount.virtual.rebooting,
    profilesCount.virtualedge.rebooting,
  ];
  if (profilesCount.cloudgateway) {
    rebootingNodeCounts.push(
      profilesCount.cloudgateway.rebooting
        ? profilesCount.cloudgateway.rebooting
        : 0,
    );
  }

  let newNodeBGColors = [
      StatusColor.NEW,
      StatusColor.NEW,
      StatusColor.NEW,
      StatusColor.NEW,
    ],
    aliveNodeBGColors = [
      StatusColor.ALIVE,
      StatusColor.ALIVE,
      StatusColor.ALIVE,
      StatusColor.ALIVE,
    ],
    unreachableNodeBGColors = [
      StatusColor.UNREACHABLE,
      StatusColor.UNREACHABLE,
      StatusColor.UNREACHABLE,
      StatusColor.UNREACHABLE,
    ],
    rebootingNodeBGColors = [
      StatusColor.REBOOTING,
      StatusColor.REBOOTING,
      StatusColor.REBOOTING,
      StatusColor.REBOOTING,
    ];
  let edgeProfile =
      profilesCount.edge.new +
      profilesCount.edge.alive +
      profilesCount.edge.unreachable +
      profilesCount.edge.rebooting,
    virtualProfile =
      profilesCount.virtual.new +
      profilesCount.virtual.alive +
      profilesCount.virtual.unreachable +
      profilesCount.virtual.rebooting,
    virtualEdgeProfile =
      profilesCount.virtualedge.new +
      profilesCount.virtualedge.alive +
      profilesCount.virtualedge.unreachable +
      profilesCount.virtualedge.rebooting,
    dataCounts = [edgeProfile, virtualProfile, virtualEdgeProfile];
  if (profilesCount.cloudgateway) {
    let unifiedCloudGatewayProfile =
      profilesCount.cloudgateway.new +
      profilesCount.cloudgateway.alive +
      profilesCount.cloudgateway.unreachable +
      profilesCount.cloudgateway.rebooting;
    dataCounts.push(unifiedCloudGatewayProfile);
  }

  let dataLables = ["Edge", "Virtual", "Virtual Edge"];
  if (profilesCount.cloudgateway) {
    dataLables.push(["Unified", "Cloud Gateway"]);
  }

  let dataSets = [];
  let dataset_obj = {
    label: "",
    data: [],
    pointStyle: "rectRot",
    backgroundColor: [],
    borderColor: "#dc7c3a",
    showLines: true,
    hoverBorderWidth: 1,
  };

  if (
    newNodeCounts.length > 0 &&
    newNodeCounts.reduce((x, y) => {
      return x + y;
    }) > 0
  ) {
    let obj_new = copyObject(dataset_obj);
    obj_new.label = "NEW";
    obj_new.data = newNodeCounts;
    obj_new.backgroundColor = newNodeBGColors;
    obj_new.borderColor = StatusColor.NEW;
    dataSets.push(obj_new);
  }
  if (
    aliveNodeCounts.length > 0 &&
    aliveNodeCounts.reduce((x, y) => {
      return x + y;
    }) > 0
  ) {
    let obj_alive = copyObject(dataset_obj);
    obj_alive.label = "ALIVE";
    obj_alive.data = aliveNodeCounts;
    obj_alive.backgroundColor = aliveNodeBGColors;
    obj_alive.borderColor = StatusColor.ALIVE;
    dataSets.push(obj_alive);
  }
  if (
    unreachableNodeCounts.length > 0 &&
    unreachableNodeCounts.reduce((x, y) => {
      return x + y;
    }) > 0
  ) {
    let obj_unreachable = copyObject(dataset_obj);
    obj_unreachable.label = "UNREACHABLE";
    obj_unreachable.data = unreachableNodeCounts;
    obj_unreachable.backgroundColor = unreachableNodeBGColors;
    obj_unreachable.borderColor = StatusColor.UNREACHABLE;
    dataSets.push(obj_unreachable);
  }
  if (
    rebootingNodeCounts.length > 0 &&
    rebootingNodeCounts.reduce((x, y) => {
      return x + y;
    }) > 0
  ) {
    let obj_rebooting = copyObject(dataset_obj);
    obj_rebooting.label = "REBOOTING";
    obj_rebooting.data = rebootingNodeCounts;
    obj_rebooting.backgroundColor = rebootingNodeBGColors;
    obj_rebooting.borderColor = StatusColor.REBOOTING;
    dataSets.push(obj_rebooting);
  }

  //Check if iNodes Total count based on profile count
  if (
    dataCounts.length > 0 &&
    dataCounts.reduce((x, y) => {
      return x + y;
    }) === 0
  ) {
    dataCounts = [];
  }

  const data = {
    labels: dataLables,
    datasets: dataSets,
    // These labels appear in the legend and in the tooltips when hovering different arcs
  };

  const options = {
    responsive: true,
    title: {
      display: true,
    },
    scales: {
      yAxes: [
        {
          barPercentage: 0.7,
          stacked: true,
          ticks: {
            fontColor:
              GLOBAL_SETTINGS && GLOBAL_SETTINGS.text_color
                ? GLOBAL_SETTINGS.text_color
                : "#365c6f",
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            stepSize: calcStepSize(
              Math.max(...dataCounts) - Math.min(...dataCounts),
              10,
            ),
          },
        },
      ],
      events: false,
      tooltips: {
        mode: "dataset",
      },
      hover: {
        animationDuration: 0,
      },
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontStyle: "bold",
        fontSize: 9,
        padding: 18,
        fontColor:
          GLOBAL_SETTINGS && GLOBAL_SETTINGS.text_color
            ? GLOBAL_SETTINGS.text_color
            : "#365c6f",
      },
      onHover: function(e) {
        e.target.style.cursor = "pointer";
      },
    },
    hover: {
      onHover: function(e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = "pointer";
        else e.target.style.cursor = "default";
      },
    },
  };
  return (
    <div>
      <div className="widget-title">{"iNodes by Profile"}</div>
      {dataCounts &&
      dataCounts.length > 0 &&
      AuthStore.IsPermitted("NODE:VIEW") ? (
        <div>
          <LineChart
            height={400}
            width={400}
            data={data}
            options={options}
            getElementAtEvent={handleClick}
          />
        </div>
      ) : (
        <div className="widget-nodata-msg">
          {DashboardStore.counts.inode !== 0 &&
          AuthStore.IsPermitted("NODE:VIEW") ? (
            <div className="chart-spinner">
              <Spin tip="Loading..." />
            </div>
          ) : (
            <i>No iNodes</i>
          )}
        </div>
      )}
    </div>
  );
};

export default NodeProfiles;
