import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import ActionBtn from "Core/API/ActionBtn";
import SerialForm from "components/Forms/SerialForm";
import SerialMove from "components/Forms/SerialMove";
import TableLayoutV1 from "../layout/TableLayoutV1";
import {
  highlightSearchText,
  getCurrentlocaleText,
  getDaysLeftInfo,
} from "Core/Utils";
import SearchInput from "components/UI-Components/SearchInput";
import { Row, Col, Checkbox, Button, Modal } from "antd";
import Icons from "components/UI-Components/Icons";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import SerialNumberController from "controller/SerialNumberController";
import propTypes from "prop-types";

@inject("AuthStore", "SerialNumberViewModel", "UiStore", "OrgViewModel")
@observer
class SerialList extends Component {
  constructor(props) {
    super(props);
    this.serialNumberModel = this.props.SerialNumberViewModel;
    this.searchTimeout = null;
    this.state = {
      selectedCerts: [],
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      currentPage: 1,
      currentSerialNumberRecord: null,
      showSerialNumberModal: false,
      isTableModalToggle: false,
    };
  }

  serialNumberList = (
    loading = true,
    page = 0,
    search = "",
    params = this.props.params || {},
  ) => {
    params.page = page;
    params.search = search;
    params.assigned = false;
    if (this.props.UiStore.showChildSerials) delete params.own;
    else params.own = true;
    SerialNumberController.getSerialNumbers(params, loading);
  };

  componentWillUnmount() {
    this.props.UiStore.showChildSerials = false;
  }
  componentDidMount() {
    this.props.UiStore.showChildSerials = this.props.cameFromDashboard
      ? true
      : false;
    this.serialNumberList(true);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.orgId != props.orgId) {
      this.serialNumberList(true, 0, this.state.searchText, props.params);
    }
  }

  resetState = () => {
    // on deselect all
    this.setState({
      selectedCerts: [],
    });
  };

  createAfterCall = resp => {
    this.setState({
      currentPage: 1,
    });
    this.serialNumberList(true, 0, this.state.searchText);
    this.resetState();
  };

  deleteAfterCall = () => {
    let page = this.state.currentPage ? this.state.currentPage : 0;
    if (this.serialNumberModel.pkiData.length == 0) {
      page--;
    }
    this.serialNumberList(true, page - 1, this.state.searchText);
    this.resetState();
  };

  setSelectedStates = (record = {}, selected, selectedRows) => {
    let selectedCerts = [];
    for (let i = 0; i < selectedRows.length; i++) {
      selectedCerts.push(selectedRows[i].id);
    }
    this.setState({ selectedCerts: selectedCerts });
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    if (selected) {
      this.setSelectedStates(null, selected, selectedRows);
    } else {
      // on deselect all
      this.resetState();
    }
  };

  handleTableChange = (record, selected, selectedRows) => {
    selectedRows.length == 0 ? this.resetState() : "";
    this.setSelectedStates(record, selected, selectedRows);
  };

  serialListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.serialNumberList(false, 0, searchText);
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({
      currentPage: 1,
      searchText: "",
    });
    this.serialNumberList(true, 0, "");
  };

  onTableChange = (pagination, filter, sorter) => {
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: sorter,
    });
    let page = pagination.current - 1;
    this.serialNumberList(true, page);
  };

  handleModalOk(e) {
    this.setState({
      isTableModalToggle: !this.state.isTableModalToggle,
    });
  }

  changeListview = e => {
    this.props.UiStore.showChildSerials = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.serialNumberList(true, 0, this.state.searchText);
    this.resetState();
  };

  handleRowClick = (record, index, event) => {
    this.setState({ currentSerialNumberRecord: record }, () => {
      this.setState({ showSerialNumberModal: true });
    });
  };

  handleSerialNumberModelCancel = () => {
    this.setState({
      showSerialNumberModal: false,
      currentSerialNumberRecord: null,
    });
  };

  getModalContentJSX = record => {
    setTimeout(() => {
      this.setState({
        modalTitle:
          this.state.currentSerialNumberRecord &&
          this.state.currentSerialNumberRecord.hardware_serial_number
            ? this.state.currentSerialNumberRecord.hardware_serial_number
            : "",
        modalContent: this.renderSerialNumberDetails(record),
      });
    }, 100);
  };

  renderSerialNumberDetails = () => {
    let serialNumberData = this.state.currentSerialNumberRecord;
    let container = serialNumberData ? (
      <div>
        <Row className="py-10">
          <Col span={6}>
            <strong>
              {getCurrentlocaleText("inode.systeminfo.serialnumber.text")}
            </strong>
          </Col>
          <Col span={18}>
            {serialNumberData.hardware_serial_number
              ? serialNumberData.hardware_serial_number
              : "-"}
          </Col>
        </Row>
        <Row className="py-10">
          <Col span={6}>
            <strong>{getCurrentlocaleText("prod-detail.text")}</strong>
          </Col>
          <Col span={18}>
            {serialNumberData.manufacturing &&
            serialNumberData.manufacturing.node_info &&
            serialNumberData.manufacturing.node_info.product &&
            serialNumberData.manufacturing.node_info.product.name
              ? serialNumberData.manufacturing.node_info.product.name
              : "-"}
          </Col>
        </Row>
        <Row className="py-10">
          <Col span={6}>
            <strong>{getCurrentlocaleText("mac-address.text")}</strong>
          </Col>
          <Col span={18}>
            <div>
              <div>
                <div>
                  <strong>{getCurrentlocaleText("wan-interface.text")}</strong>
                </div>
                {serialNumberData.manufacturing &&
                serialNumberData.manufacturing.netplan &&
                serialNumberData.manufacturing.netplan.wan
                  ? serialNumberData.manufacturing.netplan.wan.map(
                      (wanInterface, index) => {
                        return (
                          <div className="py-10" key={index}>
                            <span>
                              <span>{wanInterface.display_name}:&nbsp;</span>
                              <code>{wanInterface.hwaddr}</code>
                            </span>
                          </div>
                        );
                      },
                    )
                  : "-"}
              </div>
              <div>
                <div>
                  <strong>{getCurrentlocaleText("tan-interface.text")}</strong>
                </div>
                {serialNumberData.manufacturing &&
                serialNumberData.manufacturing.netplan &&
                serialNumberData.manufacturing.netplan.tan
                  ? serialNumberData.manufacturing.netplan.tan.map(
                      (tanInterface, index) => {
                        return (
                          <div className="py-10" key={index}>
                            <span>
                              <span>{tanInterface.display_name}:&nbsp;</span>
                              <code>{tanInterface.hwaddr}</code>
                            </span>
                          </div>
                        );
                      },
                    )
                  : "-"}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    ) : (
      <h3>{getCurrentlocaleText("serial.number.details.notfound.text")}</h3>
    );
    return container;
  };

  render() {
    const { selectedRowKeys, isTableModalToggle } = this.state;
    let pkiData = this.serialNumberModel.pkiData;
    this.columns = [
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "serial_list.number.column.header.text",
            )}
          >
            {getCurrentlocaleText("serial_list.number.column.header.text")}
          </span>
        ),
        className: "iot-pointer-cursor",
        dataIndex: "hardware_serial_number",
        key: "hardware_serial_number",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div>
              <span>
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(text, this.state.searchText)
                  : text}
              </span>
              {this.props.UiStore.showChildSerials && (
                <span className="px-15">
                  <i style={{ color: "#656D7A" }}>
                    {"Org - " + record.organization.name}
                  </i>
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("vendor-detail.text")}>
            {getCurrentlocaleText("vendor-detail.text")}
          </span>
        ),
        className: "iot-pointer-cursor",
        key: "vendor",
        width: "13%",
        render: (text, record, index) => {
          return (
            <div>
              {record.manufacturing &&
              record.manufacturing.node_info &&
              record.manufacturing.node_info.product &&
              record.manufacturing.node_info.product.vendor
                ? record.manufacturing.node_info.product.vendor
                : "-"}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("prod-detail.text")}>
            {getCurrentlocaleText("prod-detail.text")}
          </span>
        ),
        className: "iot-pointer-cursor",
        key: "product",
        width: "13%",
        render: (text, record, index) => {
          return (
            <div>
              {record.manufacturing &&
              record.manufacturing.node_info &&
              record.manufacturing.node_info.product &&
              record.manufacturing.node_info.product.name
                ? record.manufacturing.node_info.product.name
                : "-"}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("prod-serial.text")}>
            {getCurrentlocaleText("prod-serial.text")}
          </span>
        ),
        className: "iot-pointer-cursor",
        key: "product_serial",
        width: "13%",
        render: (text, record, index) => {
          return (
            <div>
              {record.manufacturing &&
              record.manufacturing.node_info &&
              record.manufacturing.node_info.product &&
              record.manufacturing.node_info.product.serial
                ? record.manufacturing.node_info.product.serial
                : "-"}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("manufacturing.node-version.text")}>
            {getCurrentlocaleText("manufacturing.node-version.text")}
          </span>
        ),
        className: "iot-pointer-cursor",
        key: "node_version",
        width: "13%",
        render: (text, record, index) => {
          return (
            <div>
              {record.manufacturing &&
              record.manufacturing.node_info &&
              record.manufacturing.node_info.node &&
              record.manufacturing.node_info.node.version
                ? record.manufacturing.node_info.node.version
                : "-"}
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "certificate.issused.by.and.expiry.text",
            )}
          >
            {getCurrentlocaleText("certificate.issused.by.and.expiry.text")}
          </span>
        ),
        className: "iot-pointer-cursor",
        key: "cert_issused_and_expiry",
        width: "28%",
        render: (text, record, index) => {
          return (
            <div>
              <div>
                {record.ca_name &&
                  record.ca_name.split(",")[0] &&
                  record.ca_name
                    .split(",")[0]
                    .substring(record.ca_name.indexOf("=") + 1)}
              </div>
              <div>{getDaysLeftInfo(record.not_after)}</div>
            </div>
          );
        },
      },
    ];

    let container = (
      <div style={{ minHeight: 250 }}>
        <div style={{ display: "inline-flex", width: "50%" }}>
          <span className="headertext">
            {getCurrentlocaleText("general.assets_serialnumber.label.text")}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 7,
            }}
          >
            <Checkbox
              checked={this.props.UiStore.showChildSerials}
              onChange={this.changeListview}
              disabled={
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("serial.list.table_view.checkbox.label")}
            </Checkbox>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchInput
              placeholder={getCurrentlocaleText(
                "serial_list.search.placeholder.text",
              )}
              onChange={this.serialListFilter}
              value={this.state.searchText}
              clearSearch={this.clearSearchFilter}
            />
          </div>
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.AuthStore.IsPermitted("SERIAL:CREATE") && (
              <div>
                <ActionBtn
                  type="primary"
                  title={
                    <span>
                      <Icons type="fa" name="FaBarcode" />{" "}
                      {getCurrentlocaleText("serial_list.add.button.text")}
                    </span>
                  }
                  resource={this.props.resource}
                  icon="AiOutlinePlus"
                  width={500}
                  iconButton
                  action={this.props.action ? this.props.action : "create"}
                  extendData={this.props.params || {}}
                  actionForm={SerialForm}
                  afterCall={this.createAfterCall}
                  controller={SerialNumberController}
                >
                  <Icons type="ai" name="AiOutlinePlus" style={{ margin: 0 }} />
                  {getCurrentlocaleText("serial_list.add.button.text")}
                </ActionBtn>
              </div>
            )}
          </div>
        </div>
        {/* Action Bar */}
        <div className="action-bar">
          <div className="action-bar-container">
            {/* Download SSHKey */}
            {this.props.AuthStore.IsPermitted("ORG:SUPPORT") && (
              <span className="action-bar-item">
                <span>
                  <Button
                    className="action-btn action-bar-button"
                    title={getCurrentlocaleText("download.default.sshkey.text")}
                    disabled={
                      this.state.selectedCerts.length === 0 ||
                      this.state.selectedCerts.length > 1
                    }
                    onClick={() => {
                      let pkicertId = this.state.selectedCerts[0];
                      let file = pkicertId + ".pem";
                      SerialNumberController.downloadDefaultSShKey(
                        pkicertId,
                        file,
                      );
                    }}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineDownload"
                      className="valign-middle"
                      style={{ marginRight: 5 }}
                    />
                    <span style={{ margin: 0 }}>
                      {getCurrentlocaleText("download.default.sshkey.text")}
                    </span>
                  </Button>
                </span>
              </span>
            )}
            {/* Move */}
            <span className="action-bar-item">
              <ActionBtn
                className="action-bar-button"
                resource="pki"
                action={"edit"}
                icon="AiOutlineUpload"
                formData={{
                  name: this.state.selectedCerts[0],
                  selectedCerts: this.state.selectedCerts,
                }}
                isMultipleSelected={
                  this.state.selectedCerts.length > 1 ? true : false
                }
                map={{
                  id: this.state.selectedCerts[0],
                  isMultipleSelected:
                    this.state.selectedCerts.length > 1 ? true : false,
                  selectedCerts: this.state.selectedCerts,
                }}
                iconButton
                iconClassName="valign-middle"
                iconBtnStyle={{ marginRight: 5 }}
                actionForm={SerialMove}
                afterCall={this.createAfterCall}
                disabled={
                  !this.props.AuthStore.IsPermitted("MOVE:SERIAL") ||
                  this.state.selectedCerts.length === 0
                }
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineUpload" />{" "}
                    {getCurrentlocaleText("move.text")}
                  </span>
                }
                controller={SerialNumberController}
                buttonName={getCurrentlocaleText("move.text")}
              >
                <Icons type="ai" name="AiOutlineUpload" style={{ margin: 0 }} />
                {getCurrentlocaleText("move.text")}
              </ActionBtn>
            </span>
            {/* Delete */}
            <span className="action-bar-item">
              <ActionBtn
                className="action-bar-button"
                buttonName="Delete"
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.title.text",
                      {
                        0:
                          this.state.selectedCerts.length > 1
                            ? "Serial Numbers"
                            : "Serial Number",
                      },
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.hover.text",
                      {
                        0:
                          this.state.selectedCerts.length > 1
                            ? "Serial Numbers"
                            : "Serial Number",
                      },
                    )}
                  </span>
                }
                messageContent={
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        this.state.selectedCerts.length > 1
                          ? "serial.multiple.delete.confirmation.message.text"
                          : "serial.delete.confirmation.message.text",
                      )}{" "}
                    </p>
                  </span>
                }
                warningMessage={getCurrentlocaleText(
                  this.state.selectedCerts.length > 1
                    ? "serial.multiple.delete.confirmation.message1.text"
                    : "serial.single.delete.confirmation.message1.text",
                )}
                orgId={this.props.orgId}
                icon="AiOutlineDelete"
                formData={this.state.selectedCerts.map(selectedCert => {
                  return { name: selectedCert };
                })}
                iconButton
                iconClassName="valign-middle"
                iconBtnStyle={{ marginRight: 5 }}
                resource="pki"
                afterCall={this.deleteAfterCall}
                action="delete"
                disabled={this.state.selectedCerts.length === 0}
                controller={SerialNumberController}
                cancelText={getCurrentlocaleText(
                  "inode.delete.confirmation.cancel.text",
                  {
                    0:
                      this.state.selectedCerts.length > 1
                        ? "Serial Numbers"
                        : "Serial Number",
                  },
                )}
                okText={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineDelete"
                      style={{ margin: 0 }}
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0:
                        this.state.selectedCerts.length > 1
                          ? "Serial Numbers"
                          : "Serial Number",
                    })}
                  </span>
                }
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            </span>
          </div>
        </div>
        {this.serialNumberModel.serialNumbers.length > 0 ? (
          <div>
            {
              <TableLayoutV1
                tableSize="small"
                columns={this.columns}
                dataSource={pkiData}
                pagination={{
                  pageSize:
                    this.serialNumberModel &&
                    this.serialNumberModel.pageable.size,
                  total:
                    this.serialNumberModel &&
                    this.serialNumberModel.pageable.total_count,
                  current: this.state.currentPage,
                  showSizeChanger: true,
                  pageSizeOptions: [25, 50, 100],
                }}
                selectedRows={this.handleTableChange}
                selectAll={this.onSelectAllChange}
                onChange={this.onTableChange}
                className="serialNumberList"
                getModalContent={record => {
                  return this.getModalContentJSX(record);
                }}
                showExpandColumn={false}
                handleModalOk={e => this.handleModalOk(e)}
                modalTitle={this.state.modalTitle}
                modalContent={this.state.modalContent}
                isTableModalToggle={isTableModalToggle}
                isModalLoading={false}
                disableFooter={true}
                rowClick={this.handleRowClick}
              />
            }
          </div>
        ) : (
          <div>
            {!this.serialNumberModel.loading && (
              <h3>{getCurrentlocaleText("no.serial.number.display.text")}</h3>
            )}
          </div>
        )}
      </div>
    );

    return (
      <LoadingComponent loading={this.serialNumberModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

SerialList.propTypes = {
  resource: propTypes.string,
  action: propTypes.string,
  orgId: propTypes.string,
  params: propTypes.object,
  map: propTypes.object,
  cache: propTypes.bool,
};

export default SerialList;
