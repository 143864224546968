/* 'MAIN MENU on the left of the app'
-------------------------------------------------------------- */

import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Menu } from "antd";
import { observer, inject } from "mobx-react";
import { computed } from "mobx";
import {
  isEllipsisActive,
  getCurrentlocaleText,
  checkforFeatureEnabled,
} from "Core/Utils";
import Icons from "components/UI-Components/Icons";
import TranslatedText from "components/UI-Components/TranslatedText";
import OrgController from "controller/OrgController";
import QuickAction from "components/layout/QuickAction/QuickAction";
import QuickActionBottom from "components/layout/QuickAction/QuickActionBottom";
import MenuContainer from "./MenuContainer";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const menuItem = {
  height: 40,
};

@inject("AuthStore", "ModelStore", "UiStore", "OrgViewModel")
@observer
class IoMenu extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS configured from the node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.ENV_CONFIG = ENV_CONFIG;
    this.state = {
      isOrgPermitted: false,
      defaultSelectedKeys: ["orgs"],
      selectedKeys: null,
      defaultOpenKeys: [],
      selectedOpenKeys: [],
      parrentOrg: null,
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      orglist: [],
      showNotFound: false,
    };
  }

  onCollapse = collapsed => {
    if (collapsed) {
      this.setState({ selectedOpenKeys: [] });
    } else {
      // To update the new selected state for default
      let key = MenuContainer.getActiveElement(this.selectedMenu);
      this.setState({ selectedOpenKeys: key });
    }
  };

  orgList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
  ) => {
    const currentOrgId = this.props.AuthStore.loggedInUser.organization
      ? this.props.AuthStore.loggedInUser.organization.id
      : "";
    let params = { org_id: currentOrgId };
    params.page = page;
    params.search = search;
    params.size = 2;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    OrgController.getOrgsForDropdown(params, true).then(res => {
      this.setState({
        orglist: res,
        showNotFound: res.length === 0 ? true : false,
      });
    });
  };

  orgListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.orgList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
      );
    }, 500);
  };

  clearSearchFilter = () => {
    this.setState({ searchText: "" });
  };

  onMenuClick = (item, key, keyPath) => {
    // on click the clicked menu will be selected.
    if (key) {
      this.setState({ selectedKeys: [key ? key : null], searchText: "" });
    }
    if (item && (item.key === "orgs" || item.key === "dashboard"))
      this.setState({ selectedOpenKeys: [], selectedKeys: [] });

    // To refresh knowledge base
    if (item.key === "kb") {
      let kbDocVersion =
        this.ENV_CONFIG &&
        this.ENV_CONFIG.KB_DOCUMENT_VERSION &&
        this.ENV_CONFIG.KB_DOCUMENT_VERSION.trim().length > 0
          ? this.ENV_CONFIG.KB_DOCUMENT_VERSION
          : "latest";
      this.props.UiStore.refreshUserGuide(kbDocVersion);
    }
  };

  renderSearchResults = () => {
    if (this.state.orglist.length > 0) {
      return this.state.orglist.map((item, i) => {
        return (
          <Menu.Item key={item.id} style={menuItem}>
            <NavLink to={this.getCurrentRoute(item.id)}>
              {
                <span>
                  <span style={{ lineHeight: "1.5", width: 100 }}>
                    {item.name}
                  </span>
                </span>
              }
            </NavLink>
          </Menu.Item>
        );
      });
    }
  };

  onSubMenuClick = keys => {
    let defaultsubMenukey = [
      "networks",
      "users",
      "homeOrg",
      "inodes",
      "services",
      "clusters",
      "events",
      "current-user",
    ];
    const latestOpenKey = keys.find(
      key => this.state.selectedOpenKeys.indexOf(key) === -1,
    );
    if (defaultsubMenukey.indexOf(latestOpenKey) === -1) {
      this.setState({ selectedOpenKeys: keys });
    } else {
      this.setState({
        selectedOpenKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  UNSAFE_componentWillMount() {
    //on browser back is handled
    window.onpopstate = () => {
      this.props.UiStore.SetStoreData(
        "currentRouteLocation",
        location.pathname,
      );
    };
    this.props.UiStore.SetStoreData("currentRouteLocation", location.pathname);
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.collapsed) {
      this.setState({ selectedOpenKeys: [] });
    } else if (this.props.collapsed === false) {
      // To update the new selected state for default
      let key = MenuContainer.getActiveElement(this.selectedMenu);
      this.setState({ selectedOpenKeys: key });
    }
    this.props.UiStore.SetStoreData("currentRouteLocation", location.pathname);
  }

  componentDidMount() {
    // To show tooltip for user if user name to long
    setTimeout(() => {
      let userElement = document.getElementById("logged-in-user");
      if (isEllipsisActive(userElement) > 0) {
        userElement.title = this.props.AuthStore.loggedInUser.username;
      }
    }, 1000);

    // To update the new selected state for default
    let key = MenuContainer.getActiveElement(this.selectedMenu);
    this.setState({ selectedOpenKeys: key });
  }

  @computed
  get selectedMenu() {
    // on page refresh depends upon the page location the side menu will be selected.
    if (this.props.UiStore.currentRouteLocation) {
      if (
        MenuContainer.getSelectedMenu(this.props.UiStore.currentRouteLocation)
      ) {
        if (
          MenuContainer.getSelectedMenu(
            this.props.UiStore.currentRouteLocation,
          ).includes("home")
        ) {
          return ["dashboard"];
        } else {
          return MenuContainer.getSelectedMenu(
            this.props.UiStore.currentRouteLocation,
          );
        }
      }
    }
    // To handle home link click
    if (this.props.UiStore.currentRouteLocation === "/") {
      return this.selectDefaultKey();
    }
    if (this.props.UiStore.currentRouteLocation === "/home") {
      return this.selectDefaultKey();
    }

    // on login depends upon the logged-in user the side menu should selected.
    if (!this.state.selectedKeys) {
      return this.selectDefaultKey();
    }
    return this.state.selectedKeys;
  }

  selectDefaultKey = () => {
    if (
      this.props.AuthStore.isCurrentUserAPISuccess &&
      this.props.AuthStore.IsPermitted("ORG:VIEW")
    ) {
      /* this.props.AuthStore.loggedInUser.permissions.includes("SUPER:ADMIN") ||*/ //LAT-3553 commented as temp fix
      if (
        this.props.AuthStore.isBetaAllowed &&
        this.props.AuthStore.allowedBetafeatures.dashboard
      ) {
        return ["dashboard"];
      } else return this.state.defaultSelectedKeys;
    } else {
      return this.props.AuthStore.IsPermitted("NODE:VIEW")
        ? ["inodes"]
        : ["users"];
    }
  };

  getCurrentRoute = orgId => {
    if (this.props.UiStore.currentRouteLocation) {
      if (this.props.UiStore.currentRouteLocation != "/") {
        let keys = this.props.UiStore.currentRouteLocation.split("/");
        keys.splice(2, 1, orgId);
        if (keys[1] === "dashboard") keys.splice(1, 1, "orgs");
        if (keys[1] === "home") keys.splice(1, 1, "orgs");
        if (keys.length >= 4) keys.splice(4);
        let route = keys.join("/");
        return route;
      } else {
        return location.pathname + "/orgs/" + orgId + "/" + "orgs";
      }
    } else {
      return (location.pathname = "/orgs/" + orgId + "/" + "orgs");
    }
  };

  @computed
  get openedMenu() {
    if (!this.props.UiStore.isSiderFold) {
      if (
        this.state.selectedOpenKeys &&
        this.state.selectedOpenKeys.length > 0 &&
        this.props.UiStore.currentRouteLocation
      ) {
        return this.state.selectedOpenKeys;
      }
      if (
        this.state.selectedOpenKeys.length === 0 &&
        this.props.UiStore.currentRouteLocation
      ) {
        if (
          this.props.UiStore.currentRouteLocation.split("/") &&
          this.props.UiStore.currentRouteLocation.split("/")[3]
        ) {
          return MenuContainer.getActiveElement(
            MenuContainer.getSelectedMenu(
              this.props.UiStore.currentRouteLocation,
            ),
          );
        } else if (
          this.props.UiStore.currentRouteLocation.split("/") &&
          this.props.UiStore.currentRouteLocation.split("/").length === 2
        ) {
          return MenuContainer.getActiveElement(
            MenuContainer.getSelectedMenu(
              this.props.UiStore.currentRouteLocation,
            ),
          );
        } else {
          return [];
        }
      }
      if (
        this.state.selectedOpenKeys.length === 0 &&
        this.props.UiStore.currentRouteLocation &&
        this.props.UiStore.currentRouteLocation.split("/") &&
        !this.state.selectedOpenKeys.includes(
          this.props.UiStore.currentRouteLocation.split("/")[3],
        )
      ) {
        if (
          this.props.UiStore.currentRouteLocation.split("/") &&
          this.props.UiStore.currentRouteLocation.split("/")[3]
        )
          return Array(
            MenuContainer.getActiveElement(
              MenuContainer.getSelectedMenu(
                this.props.UiStore.currentRouteLocation,
              ),
            ),
          );
        else return this.state.selectedOpenKeys;
      }
      if (this.props.UiStore.isSiderFold) {
        return [];
      } else {
        return this.state.defaultOpenKeys;
      }
    } else {
      return [];
    }
  }

  render() {
    /*------------------Trigger the current permission -------------------------------------------*/
    const iconStyle = {
      fontSize: 16,
      lineHeight: 1.3,
    };

    const menuItemStyle = {
      lineHeight: 1.5,
    };

    const currentOrgId =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
        ? this.props.match.params.id
        : this.props.OrgViewModel.org.id != undefined
        ? this.props.OrgViewModel.org.id
        : this.props.AuthStore.loggedInUser.organization &&
          this.props.AuthStore.loggedInUser.organization.id;

    let openKeys = this.props.UiStore.isSiderFold
      ? {}
      : {
          openKeys: this.openedMenu,
        };
    return (
      <div>
        <QuickAction {...this.props} />
        <Menu
          theme={
            this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.sidebar_mode
              ? this.GLOBAL_SETTINGS.sidebar_mode
              : "dark"
          }
          mode={this.props.UiStore.isSiderFold ? "vertical" : "inline"}
          defaultSelectedKeys={
            this.state.defaultSelectedKeys
              ? this.state.defaultSelectedKeys
              : this.selectedMenu
          }
          defaultOpenKeys={this.state.defaultOpenKeys}
          {...openKeys}
          selectedKeys={this.selectedMenu}
          onClick={this.onMenuClick}
          onOpenChange={this.onSubMenuClick}
          collapsed={this.props.UiStore.isSiderFold.toString()}
          className={
            this.props.UiStore.isSiderFold
              ? "scrollable-menu-collapsed"
              : "scrollable-menu"
          }
          style={{
            paddingBottom: "25px",
          }}
        >
          <Menu.Item key="dashboard" style={menuItem}>
            <Link to={"/dashboard/" + currentOrgId}>
              <span>
                <Icons
                  type="fa"
                  name="FaDesktop"
                  style={iconStyle}
                  className="middle"
                />
                <span className="nav-text" style={menuItemStyle}>
                  <TranslatedText
                    UItextID="dashboard.navigation_menu.text"
                    DynValues={{ 0: "" }}
                  />{" "}
                </span>
              </span>
            </Link>
          </Menu.Item>
          {this.props.AuthStore.IsPermitted("ORG:VIEW") ? (
            <Menu.Item key="orgs" style={menuItem}>
              <NavLink to={"/orgs/" + currentOrgId + "/orgs"}>
                <span>
                  <Icons
                    type="fa"
                    name="FaSitemap"
                    style={iconStyle}
                    className="middle"
                  />
                  <span className="nav-text" style={menuItemStyle}>
                    <TranslatedText UItextID="orgs.navigation_menu.text" />
                    {/* {"Child Orgs"} */}
                  </span>
                </span>
              </NavLink>
            </Menu.Item>
          ) : null}
          {this.props.AuthStore.IsPermitted("CLUSTER:VIEW") &&
          checkforFeatureEnabled(
            this.props.OrgViewModel.org.id != undefined
              ? this.props.OrgViewModel.org
              : this.props.AuthStore.loggedInUserOrg,
            "cluster",
          ) ? (
            <Menu.Item key="clusters" style={menuItem}>
              <NavLink to={"/orgs/" + currentOrgId + "/clusters"}>
                <span>
                  <Icons
                    type="bs"
                    name="BsBoundingBox"
                    style={iconStyle}
                    className="middle"
                  />
                  <span className="nav-text" style={menuItemStyle}>
                    <TranslatedText UItextID="clusters.navigation_menu.text" />
                  </span>
                </span>
              </NavLink>
            </Menu.Item>
          ) : null}
          {this.props.AuthStore.IsPermitted("NODE:VIEW") ? (
            <SubMenu
              key="inodes-sub-menu"
              title={
                <span>
                  <Icons
                    type="fa"
                    name="FaRegHdd"
                    style={iconStyle}
                    className="middle"
                  />
                  <span className="nav-text" style={menuItemStyle}>
                    <TranslatedText UItextID="inodes.navigation_menu.text" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="inodes" style={menuItem}>
                <NavLink to={"/orgs/" + currentOrgId + "/inodes"}>
                  <span>
                    <Icons
                      type="fa"
                      name="FaRegHdd"
                      style={iconStyle}
                      className="middle"
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      <TranslatedText UItextID="general.left_navigation.all_nodes.text" />
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="serials" style={menuItem}>
                <NavLink to={"/orgs/" + currentOrgId + "/serials"}>
                  <span>
                    <Icons
                      type="fa"
                      name="FaQrcode"
                      style={iconStyle}
                      className="middle"
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      {getCurrentlocaleText(
                        "general.assets_serialnumber.label.text",
                      )}
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>

              <Menu.Item key="certificates" style={menuItem}>
                <NavLink to={"/orgs/" + currentOrgId + "/certificates"}>
                  <span>
                    <Icons
                      type="fa"
                      name="FaKey"
                      style={iconStyle}
                      className="middle"
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      {getCurrentlocaleText(
                        "general.assets_certificates.label.text",
                      )}
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
            </SubMenu>
          ) : null}
          {this.props.AuthStore.IsPermitted("NETWORK:VIEW") ? (
            <SubMenu
              key="networks-sub-menu"
              title={
                <span>
                  <Icons
                    type="fa"
                    name="FaConnectdevelop"
                    style={iconStyle}
                    className="middle"
                  />
                  <span className="nav-text" style={menuItemStyle}>
                    Networks
                  </span>
                </span>
              }
            >
              <Menu.Item key="networks" style={menuItem}>
                <NavLink to={"/orgs/" + currentOrgId + "/networks"}>
                  <span>
                    <Icons
                      type="fa"
                      name="FaConnectdevelop"
                      style={iconStyle}
                      className="middle"
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      All Networks
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
              {this.props.AuthStore.IsPermitted("REPNET:ADMIIN") && (
                <Menu.Item key="repnet" style={menuItem}>
                  <NavLink to={"/orgs/" + currentOrgId + "/repnet"}>
                    <span>
                      <i
                        className="fa-regular fa-code-compare middle"
                        style={{ ...iconStyle, rotate: "90deg" }}
                      ></i>
                      <span className="nav-text pl-10" style={menuItemStyle}>
                        RepNet Table
                      </span>
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              <Menu.Item
                key="securitypolicies"
                title={getCurrentlocaleText(
                  "security_policy.left.menu.label.title",
                )}
                style={menuItem}
              >
                <NavLink
                  className="left-menu-overflow"
                  to={"/orgs/" + currentOrgId + "/securitypolicies"}
                >
                  <span>
                    <Icons
                      type="fa"
                      name="FaShieldAlt"
                      style={iconStyle}
                      className="middle"
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      {getCurrentlocaleText(
                        "security_policy.left.menu.label.title",
                      )}
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
            </SubMenu>
          ) : null}
          {this.props.AuthStore.IsPermitted("ORG:VIEW") ? (
            <SubMenu
              key="services-sub-menu"
              title={
                <span>
                  <Icons
                    type="hi"
                    name="HiOutlineShoppingBag"
                    style={iconStyle}
                    className="middle"
                  />
                  <span className="nav-text" style={menuItemStyle}>
                    Marketplace
                  </span>
                </span>
              }
            >
              {this.props.AuthStore.IsPermitted("SERVICE:CREATE") && (
                <Menu.Item key="servicelisting" style={menuItem}>
                  <NavLink to={"/orgs/" + currentOrgId + "/servicelisting"}>
                    <span>
                      <Icons
                        type="ai"
                        name="AiFillTool"
                        style={iconStyle}
                        className="middle"
                      />
                      <span className="nav-text" style={menuItemStyle}>
                        Service Listing
                      </span>
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              <Menu.Item key="myservices" style={menuItem}>
                <NavLink to={"/orgs/" + currentOrgId + "/myservices"}>
                  <span>
                    <Icons
                      type="fa"
                      name="FaToolbox"
                      style={iconStyle}
                      className="middle"
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      My Services
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
            </SubMenu>
          ) : null}
          {this.props.AuthStore.IsPermitted("PROFILES:VIEW") ? (
            <Menu.Item key="profiles" style={menuItem}>
              <Link to="/profiles">
                <span>
                  <Icons type="ai" name="AiOutlineApi" style={iconStyle} />
                  <span className="nav-text" style={menuItemStyle}>
                    <TranslatedText UItextID="profiles.navigation_menu.text" />
                  </span>
                </span>
              </Link>
            </Menu.Item>
          ) : null}
          {this.props.AuthStore.IsPermitted("ORG:VIEW") &&
          this.props.AuthStore.IsPermitted("USER:VIEW") ? (
            <SubMenu
              key="users-sub-menu"
              title={
                <span>
                  <Icons
                    type="md"
                    name="MdGroup"
                    style={iconStyle}
                    className="middle"
                  />
                  <span className="nav-text" style={menuItemStyle}>
                    <TranslatedText UItextID="users.navigation_menu.text" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="users" style={menuItem}>
                <NavLink to={"/orgs/" + currentOrgId + "/users"}>
                  <span>
                    <Icons
                      type="md"
                      name="MdGroup"
                      style={iconStyle}
                      className="middle"
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      {getCurrentlocaleText(
                        "general.left_navigation.all_users.text",
                      )}
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
              {this.props.AuthStore.IsPermitted("ORG:VIEW") &&
                this.props.AuthStore.IsPermitted("ROLE:VIEW") && (
                  <Menu.Item key="roles" style={menuItem}>
                    <NavLink to={"/orgs/" + currentOrgId + "/roles"}>
                      <span>
                        <Icons
                          type="ai"
                          name="AiOutlineIdcard"
                          style={iconStyle}
                        />
                        <span className="nav-text" style={menuItemStyle}>
                          {getCurrentlocaleText(
                            "general.left_navigation.all_roles.text",
                          )}
                        </span>
                      </span>
                    </NavLink>
                  </Menu.Item>
                )}
              {this.props.AuthStore.IsPermitted("ACTIVITY:VIEW") && (
                <Menu.Item key="activities" style={menuItem}>
                  <NavLink to={"/orgs/" + currentOrgId + "/activities"}>
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineSchedule"
                        className="middle"
                        style={{ fontSize: 16, marginRight: 0 }}
                      />
                      <Icons
                        type="md"
                        name="MdPerson"
                        className="event-header-icon"
                        style={{ marginRight: 3 }}
                      />
                      <span className="nav-text" style={menuItemStyle}>
                        {getCurrentlocaleText("activity.text")}
                      </span>
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
            </SubMenu>
          ) : null}
          {this.props.AuthStore.IsPermitted("EVENTS:VIEW") ? (
            <SubMenu
              key="events-sub-menu"
              title={
                <span>
                  <Icons
                    type="md"
                    name="MdEvent"
                    className="middle"
                    style={{ marginRight: 0, lineHeight: 1.3 }}
                  />
                  <Icons
                    type="fa"
                    name="FaCog"
                    style={{ fontSize: 8, marginLeft: -6 }}
                  />
                  <span
                    className={
                      !this.props.UiStore.isSiderFold
                        ? "event-menu-icon-text nav-text"
                        : "event-menu-icon-expanded-text nav-text"
                    }
                    style={menuItemStyle}
                  >
                    <TranslatedText UItextID="events.text" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="events-all-events" style={menuItem}>
                <NavLink to={"/events"}>
                  <span>
                    <Icons
                      type="md"
                      name="MdEvent"
                      className="middle"
                      style={{ marginRight: 0, lineHeight: 1.3 }}
                    />
                    <Icons
                      type="fa"
                      name="FaCog"
                      style={{ fontSize: 8, marginLeft: -6 }}
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      {getCurrentlocaleText("all.events")}
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
            </SubMenu>
          ) : null}
          {/**  Current user menu */}
          <SubMenu
            key="current-user-sub-menu"
            title={
              <span>
                <Icons
                  type="fa"
                  name="FaUser"
                  className="middle"
                  style={iconStyle}
                />
                <span className={"nav-text"} style={menuItemStyle}>
                  <TranslatedText UItextID="myaccount.text" />
                </span>
              </span>
            }
          >
            <Menu.Item key="current-user-profile" style={menuItem}>
              <NavLink to={"/profile"}>
                <span>
                  <Icons type="ai" name="AiOutlineSolution" style={iconStyle} />
                  <span className="nav-text" style={menuItemStyle}>
                    {getCurrentlocaleText("myprofile.text")}
                  </span>
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="current-user-managealerts" style={menuItem}>
              <NavLink to={"/managealerts"}>
                <span>
                  <Icons
                    type="fa"
                    name="FaBell"
                    className="middle"
                    style={iconStyle}
                  />
                  <span className="nav-text" style={menuItemStyle}>
                    {getCurrentlocaleText("manage_alerts.text")}
                  </span>
                </span>
              </NavLink>
            </Menu.Item>
            {checkforFeatureEnabled(
              this.props.OrgViewModel.org.id != undefined
                ? this.props.OrgViewModel.org
                : this.props.AuthStore.loggedInUserOrg,
              "webhook",
            ) && (
              <Menu.Item key="current-user-managewebhooks" style={menuItem}>
                <NavLink to={"/managewebhooks"}>
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineGlobal"
                      className="middle"
                      style={iconStyle}
                    />
                    <span className="nav-text" style={menuItemStyle}>
                      {getCurrentlocaleText("webhook.channel.title.text")}
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {checkforFeatureEnabled(
              this.props.OrgViewModel.org.id != undefined
                ? this.props.OrgViewModel.org
                : this.props.AuthStore.loggedInUserOrg,
              "apikey",
            ) && (
              <Menu.Item key="current-user-apiKeys" style={menuItem}>
                <NavLink to={"/keys"}>
                  <span>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        marginTop: -6,
                        fontSize: 8,
                      }}
                    >
                      {"API"}
                    </span>
                    <span style={{ display: "inline-block" }}>
                      <Icons
                        type="md"
                        name="MdVpnKey"
                        style={{
                          marginLeft: -1,
                          fontSize: 15,
                        }}
                        className={"valign-sub middle"}
                      />
                    </span>
                    <span className="nav-text" style={menuItemStyle}>
                      {getCurrentlocaleText("user.api_keys.leftmenu.text")}
                    </span>
                  </span>
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        </Menu>
        <QuickActionBottom {...this.props} />
      </div>
    );
  }
}
export default IoMenu;
