import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Checkbox, Menu } from "antd";
import { observer, inject } from "mobx-react";
import SearchInput from "components/UI-Components/SearchInput";
import {
  highlightSearchText,
  copyObject,
  getCurrentlocaleText,
  getTagKeyValues,
  checkforFeatureEnabled,
} from "Core/Utils";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import NetworkController from "controller/NetworkController";
import { ConnectedNodes } from "components/INodes";
import LabelProvider from "components/UI-Components/LabelProvider";
import ActionRoute from "../../Core/API/ActionRoute";
import NetworkMulticonnect from "../Forms/Components/Network/NetworkMulticonnect";
import ProfileController from "controller/ProfileController";
import InodeController from "controller/InodeController";
import DefaultRoute from "components/Forms/Components/Network/DefaultRoute";
import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import "../../assets/styles/Network.css";
import ActionBtn from "../../Core/API/ActionBtn";
import Icons from "../UI-Components/Icons";
import ClusterController from "controller/ClusterController";
import BulkConfigure from "components/BulkConfig/BulkConfigure";
import BulkConfigController from "../../controller/BulkConfigController";
import TableLayoutV1 from "../layout/TableLayoutV1";
@inject(
  "ModelStore",
  "AuthStore",
  "UiStore",
  "NetworkViewModel",
  "InodeViewModel",
  "OrgViewModel",
  "ClusterViewModel",
)
@observer
class Networks extends Component {
  constructor(props) {
    super(props);
    this.NetworkViewModel = this.props.NetworkViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.searchTimeout = null;
    this.state = {
      tabKey: "licenses",
      selectedLicenses: {},
      searchText_license: "",
      searchText_ssh: "",
      NetworkList: [],
      expandedRowKeys: [],
      searchText: "",
      filterInfo: null,
      sorterInfo: { sorter: {}, sort: {} },
      availableNetworks: {},
      isVirtualNode: {},
      isClusterNodes: [],
      showBulkConfig: false,
      currentPage: 1,
      isTableModalToggle: false,
      modalContent: "",
      modalTitle: "",
    };
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.params.org_id !== this.props.params.org_id) {
      this.NetworkList(
        true,
        0,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
        nextprops.params,
      );
      //Update iNode details
      this.updateiNodeDetails(nextprops.params.org_id);
    }
  }
  componentDidMount() {
    setTimeout(() => {
      let pathname = location.pathname;
      if (pathname) {
        let path = pathname.split("/");
        if (path[1] === "orgs") {
          this.setState({
            showBulkConfig: true,
          });
        }
      }
    }, 200);
  }
  setVnode = value => {
    this.setState({ selectedVnode: value });
  };

  fetchClusterDetails = () => {
    let params = {};
    // Find org id
    let orgId =
      this.props.params && this.props.params.org_id
        ? this.props.params.org_id
        : this.props.match &&
          this.props.match.params &&
          this.props.match.params.id
        ? this.props.match.params.id
        : null;
    // Filter with org
    if (orgId) {
      params.org_id = orgId;
    }

    if (this.props.UiStore.showChildClusters) {
      delete params.own;
    } else {
      params.own = true;
    }
    ClusterController.getClusters(params).then(response => {
      if (response && this.clusterModel.pageable.total_count) {
        let allParams = [],
          totalCount = this.clusterModel.pageable.total_count / 100;
        if (totalCount > 0) {
          for (let page = 0; page < totalCount; page++) {
            let params = {
              org_id: this.props.params && this.props.params.org_id,
              page: page,
              size: 100,
            };
            allParams.push(params);
          }
          ClusterController.getClusters(allParams, true, false);
        }
      }
    });
  };

  UNSAFE_componentWillMount() {
    this.fetchClusterDetails();
    this.props.UiStore.showChildNetworks = this.props.isFromDashboard
      ? true
      : false;
    this.NetworkList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
    // Update iNode details
    this.updateiNodeDetails(this.props.params.org_id);
  }
  updateiNodeDetails = org_id => {
    let params = {
      org_id: org_id,
    };
    (params.page = 0), (params.size = 1);
    InodeController.getInodesForDropdown(params, false).then(response => {
      if (response) {
        this.updateOrgsiNodeData(response.total_count / 100, []);
      }
    });
  };
  /**
   *  UPDATE all inodes Data
   */
  updateOrgsiNodeData(totalCount, availableNodes) {
    let isClusterNodes = [];
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        allParams.push({
          org_id: this.props.params && this.props.params.org_id,
          size: 100,
          page: page,
        });
      }
      InodeController.getAllInodesForDropdown(allParams, false).then(resp => {
        if (resp) {
          availableNodes = availableNodes.concat(resp);
          if (availableNodes.length > 0) {
            availableNodes.forEach(node => {
              if (node.cluster && node.cluster.id) {
                isClusterNodes.push(node);
              }
            });
          }
          this.setState({
            isClusterNodes: isClusterNodes,
          });
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.UiStore.showChildNetworks = false;
  }

  resetStates = () => {
    this.setState({
      selectedLicenses: {},
    });
  };

  NetworkListFilter = e => {
    clearTimeout(this.searchTimeout);
    let searchText = e.target.value;
    this.setState({ searchText: searchText });
    this.searchTimeout = setTimeout(() => {
      this.NetworkList(
        false,
        0,
        searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
      );
    }, 500);
  };
  clearSearchFilter = () => {
    this.setState({ currentPage: 1, searchText: "" });
    this.NetworkList(
      false,
      0,
      "",
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
  };
  onExpand = (expanded, record) => {
    let keys = this.state.expandedRowKeys;
    if (expanded) {
      this.fetchNetworkData(
        record.connected_networks ? record.connected_networks : [],
        record.id,
        record,
      );
      this.checkForVirtualNodes(record);
    }
    let index = keys.findIndex(val => {
      return val === record.id;
    });
    if (expanded) {
      if (index === -1) {
        keys.push(record.id);
      }
      this.setExpandedRowKeys(keys);
    } else {
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.setExpandedRowKeys(keys);
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  changeListview = e => {
    this.props.UiStore.showChildNetworks = e.target.checked;
    this.setState({
      currentPage: 1,
    });
    this.NetworkList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
  };

  onTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.columnKey && sorter.order) {
      if (sorter.order === "ascend") {
        sort.order = "ASC";
      } else {
        sort.order = "DESC";
      }
      sort.by = sorter.columnKey;
    }
    this.setState({
      currentPage: pagination.current,
      filterInfo: filter,
      sorterInfo: { sorter: sorter, sort: sort },
    });
    let page = pagination.current - 1;
    this.NetworkList(true, page, this.state.searchText, sort.by, sort.order);
  };
  NetworkList = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
    filter = null,
    forcecall = false,
    params = this.props.params || {},
  ) => {
    params = copyObject(params);
    params.page = page;
    params.search = search;
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }
    // For Filter
    if (filter) {
      let keys = Object.keys(filter);
      keys.forEach(key => {
        if (filter[key]) {
          params[key] = filter[key];
        }
      });
    }
    if (this.props.UiStore.showChildNetworks) {
      delete params.own;
    } else {
      params.own = true;
    }
    NetworkController.getNetworks(params, loading, forcecall).then(res => {
      this.setState({ NetworkList: res });
    });
  };

  lazyLoad = () => {
    let tab = this.state.tabKey;
    if (this.NetworkViewModel.pageable.next) {
      let page = this.NetworkViewModel.pageable.page + 1;
      let params = {};
      params.org_id = this.props.params.org_id;
      this.NetworkList(
        true,
        page,
        this.state.searchText,
        this.state.sorterInfo.sort.by,
        this.state.sorterInfo.sort.order,
        this.state.filterInfo,
        false,
        params,
      );
    }
  };

  expandRow = () => {
    let keys = this.NetworkViewModel.networks.map(item => {
      return item.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  fetchNetworkData = (connected_networks = [], network_id, record) => {
    let network_ids = [];
    if (connected_networks.length > 0) {
      connected_networks.forEach(connected_network => {
        network_ids.push(connected_network.network.id);
      });
      this.getNetworkInfo(network_ids, network_id, record);
    } else return [];
  };
  /**
   * GET Network Info
   */
  getNetworkInfo = (network_ids, network_id, record) => {
    NetworkController.getNetworkById(network_ids).then(response_data => {
      let updatedResponse = [];
      if (response_data) {
        response_data.forEach(network => {
          network.organization = record.organization;
          updatedResponse.push(network);
        });
      }

      this.setState({ [network_id]: updatedResponse });
      return response_data;
    });
  };
  checkForVirtualNodes = record => {
    let isVirtual = false,
      network_id = record.id;
    let data = this.props.InodeViewModel.inodes.filter(nodes => {
      if (record.node && record.node.id === nodes.id) return nodes;
    });
    if (data.length > 0) {
      ProfileController.getProfiles({
        org_id: record.organization.id,
        own: true,
      }).then(resp => {
        // Filtering single profile details
        ProfileController.getProfile(data[0] && data[0].profile.id).then(
          profileResponse => {
            this.setState({
              [network_id + "IsVirtual"]: profileResponse.config.vpn_server,
            });
          },
        );
      });
    } else {
      if (record.node && record.node.id) {
        InodeController.getInodeById(record.node.id).then(inode_resp => {
          let data = [];
          data.push(inode_resp);
          ProfileController.getProfiles({
            org_id: record.organization.id,
            own: true,
          }).then(resp => {
            // Filtering single profile details
            ProfileController.getProfile(data[0] && data[0].profile.id).then(
              profileResponse => {
                this.setState({
                  [network_id + "IsVirtual"]: profileResponse.config.vpn_server,
                });
              },
            );
          });
        });
      }
    }
  };

  getModalContentJSX = record => {
    this.setState({
      modalTitle: record && record.name ? record.name : "",
      modalContent: this.getMoreInfo(record),
    });
  };
  getMoreInfo(record) {
    let labels = record.metadata.labels ? record.metadata.labels : {};
    let connected_nodes =
      record && record.connected_networks ? record.connected_networks : [];
    let selectedVnode = connected_nodes.map(cnw => {
      return {
        node_id:
          cnw.node && cnw.node.id
            ? cnw.node.id
            : cnw.cluster && cnw.cluster.id
            ? cnw.cluster.id
            : null,
        network_id: cnw.network && cnw.network.id ? cnw.network.id : null,
        id: cnw.network && cnw.network.id ? cnw.network.id : null,
        organization: record.organization,
        node: cnw.node,
      };
    });
    let isCandidate = false;
    if (this.state.isClusterNodes) {
      let node = this.state.isClusterNodes.find(nodes => {
        return record.node && record.node.id === nodes.id;
      });
      isCandidate =
        node &&
        node.cluster_node_config &&
        node.cluster_node_config.is_candidate
          ? node.cluster_node_config.is_candidate
          : false;
    }
    return (
      <div key={record.id}>
        <Row className="network-inner-table-container mb-10">
          <Col span={12}>
            <strong>{"Label"}</strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={11}>
            <span>
              {getTagKeyValues(labels).length > 0 ? (
                <LabelProvider
                  key={record.id}
                  metadata={labels}
                  tags={getTagKeyValues(labels)}
                  showPopupLabel={false}
                  viewOnly={true}
                />
              ) : (
                "-"
              )}
            </span>
          </Col>
        </Row>
        <Row className="network-inner-table-container mb-10">
          <Col span={12}>
            <strong>{getCurrentlocaleText("network.form.cidr.text")}</strong>
          </Col>
          <Col span={1}>
            <strong>{":"}</strong>
          </Col>
          <Col span={11}>
            <span>
              {record.config &&
              record.config.network &&
              record.config.network.cidr
                ? record.config.network.cidr
                : "-"}
            </span>
          </Col>
        </Row>
        {!record.is_wan_network &&
          record.config.network.reserved &&
          record.config.network.reserved[0] &&
          record.config.network.reserved[0].start &&
          record.config.network.reserved[0].end && (
            <div className="network-inner-table-container mb-10">
              <div>
                <Row>
                  <Col span={12}>
                    <strong>
                      {getCurrentlocaleText(
                        "network.form.ipblock.heading.text",
                      )}
                    </strong>
                  </Col>
                  <Col span={1}>
                    <strong>{":"}</strong>
                  </Col>
                  <Col span={11}>
                    <span>{record.config.network.reserved[0].start}</span>
                    <span style={{ padding: "0 5px 0 5px" }}>&#8211;</span>
                    <span>{record.config.network.reserved[0].end}</span>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        {/**Tan Interface IP */}
        {
          <Row className="network-inner-table-container mb-10">
            <Col span={12}>
              <strong>
                {record.cluster
                  ? getCurrentlocaleText(
                      "network.form.cluster_interface.cidr.text",
                    )
                  : getCurrentlocaleText("network.form.interface.cidr.text")}
              </strong>
            </Col>
            <Col span={1}>
              <strong>{":"}</strong>
            </Col>
            <Col span={11}>
              <span>
                {record.config &&
                record.config.network &&
                record.config.network.tan_interface_ip
                  ? record.config.network.tan_interface_ip
                  : "-"}
              </span>
            </Col>
          </Row>
        }
        {record &&
          record.config &&
          record.config.network &&
          record.config.network.gateway && (
            <Row className="network-inner-table-container mb-10">
              <Col span={12}>
                <strong>
                  {getCurrentlocaleText("network.form.gatewayip.text")}
                </strong>
              </Col>
              <Col span={1}>
                <strong>{":"}</strong>
              </Col>
              <Col span={11}>{record.config.network.gateway}</Col>
            </Row>
          )}
        {record.config &&
        record.config.network &&
        record.config.network.vlan_id ? (
          <Row className="network-inner-table-container mb-10">
            <Col span={12}>
              <strong>
                {getCurrentlocaleText("network.form.vlan.id.text")}
              </strong>
            </Col>
            <Col span={1}>
              <strong>{":"}</strong>
            </Col>
            <Col span={11}>
              <span>{record.config.network.vlan_id}</span>
            </Col>
          </Row>
        ) : null}
        {!record.is_wan_network &&
          !(
            this.state[record.id + "IsVirtual"] &&
            this.state[record.id + "IsVirtual"]
          ) && (
            <Row className="network-inner-table-container mb-10">
              <Col span={12}>
                <strong>
                  {getCurrentlocaleText("default_destination_text")}
                </strong>
              </Col>
              <Col span={1}>
                <strong>{":"}</strong>
              </Col>
              <Col span={11}>
                <DefaultRoute
                  availableNetwork={
                    this.state[record.id]
                      ? this.state[record.id]
                      : this.state.NetworkList
                  }
                  selectedVnode={selectedVnode}
                  formData={record}
                  {...this.props}
                  showCascaderAlone={true}
                  className="nwk-default-config-width"
                />{" "}
              </Col>
            </Row>
          )}
        <Row className="network-inner-table-container" />
        {this.state[record.id] &&
          (!record.is_inheritance || isCandidate === true) && (
            <NetworkMulticonnect
              key={record.id}
              viewOnly={true}
              netWorkId={record.id}
              tanCIDR={record.config.network.cidr}
              isVirtualNode={
                record.is_default_network
                  ? true
                  : this.state[record.id + "IsVirtual"]
                  ? this.state[record.id + "IsVirtual"]
                  : false
              }
              setVnode={this.setVnode}
              {...this.props}
              currentNetwork={record}
              availableNetwork={this.state[record.id]}
              loadingState={() => {}}
              orgId={record.organization && record.organization.id}
              isFromAllNetworks={true}
              nodeId={
                record.node && record.node.id
                  ? record.node.id
                  : record.cluster && record.cluster.id
                  ? record.cluster.id
                  : null
              }
            />
          )}
      </div>
    );
  }

  deleteAfterCall = () => {
    this.NetworkList(
      true,
      0,
      this.state.searchText,
      this.state.sorterInfo.sort.by,
      this.state.sorterInfo.sort.order,
      this.state.filterInfo,
      false,
    );
  };
  onNewTemplate = () => {
    BulkConfigController.downloadNetworksTemplate();
  };

  downloadNetworks = (bulkConfig = false) => {
    let params = {};
    params.search = this.state.searchText;
    if (this.state.sorterInfo.sort.by && this.state.sorterInfo.sort.order) {
      params.sort = `${this.state.sorterInfo.sort.by}:${this.state.sorterInfo.sort.order}`;
    }
    if (this.props.UiStore.showChildUsers) {
      delete params.own;
    } else {
      params.own = true;
    }
    if (bulkConfig) {
      params.fields =
        "name,node_id,labels,cidr,start_ip,end_ip,tan_interface_ip,vlan_id,default_destination,inter_network_routing";
      params.is_wan_network = false;
      params.is_default_network = false;
    }
    if (this.props.orgId) {
      params.org_id = this.props.orgId;
    }

    BulkConfigController.downloadNetworks(params, false);
  };
  render() {
    const { isTableModalToggle } = this.state;
    let Networks = this.NetworkViewModel.NetworkListData;
    this.NetworksTableColumns = [
      {
        title: (
          <span title={getCurrentlocaleText("orgs.tablelist.namecolumn.text")}>
            {getCurrentlocaleText("orgs.tablelist.namecolumn.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        sorter: true,
        width: this.props.UiStore.showChildNetworks ? "20%" : "15%",
        sortOrder:
          this.state.sorterInfo.sorter.columnKey === "name" &&
          this.state.sorterInfo.sorter.order,
        render: (text, record, index) => {
          return (
            <div title={text} className="col-wrap">
              <span>
                {" "}
                {this.state.searchText.trim().length > 0
                  ? highlightSearchText(record.name, this.state.searchText)
                  : record.name}
              </span>
              {record.deleted_at && (
                <span className="iotium-danger iot-deleting-text-block">
                  {" "}
                  {getCurrentlocaleText("deleting.text")}
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span title={getCurrentlocaleText("myprofile.organization.text")}>
            {getCurrentlocaleText("myprofile.organization.text")}
          </span>
        ),
        dataIndex: "current_org",
        key: "orgs",
        width: "15%",
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <div title={record.organization.name} className="col-wrap">
              <Link to={"/dashboard/" + record.organization.id}>
                {record.organization.name}
              </Link>
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "network.form.interface.placeholder.text",
            )}
          >
            {getCurrentlocaleText("network.form.interface.placeholder.text")}
          </span>
        ),
        dataIndex: "interfaces",
        key: "interfaces",
        width: "10%",
        ellipsis: true,
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("general.table.inode_name.header.text")}
          >
            {getCurrentlocaleText("general.table.inode_name.header.text")}
          </span>
        ),
        dataIndex: "nodename",
        key: "nodename",
        width: this.props.UiStore.showChildNetworks ? "18%" : "16%",
        ellipsis: true,
        render: (text, record, index) => {
          let navLink;
          if (record.node && record.node.id) {
            // For Node
            navLink =
              "/orgs/" + record.organization.id + "/inodes/" + record.node.id;
          }
          let nodeName =
            record.node && record.node.name ? (
              record.node.name
            ) : (
              <p style={{ marginLeft: 15 }}>{"-"}</p>
            );
          return (
            <div title={nodeName} className="col-wrap">
              {navLink ? <Link to={navLink}>{nodeName}</Link> : nodeName}
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("network.form.remote.network.text")}
          >
            {getCurrentlocaleText("network.form.remote.network.text")}
          </span>
        ),
        dataIndex: "connected to",
        key: "peeringnws",
        width: this.props.UiStore.showChildNetworks ? "20%" : "15%",
        render: (text, record, index) => {
          let isCandidate = false;
          if (this.state.isClusterNodes) {
            let node = this.state.isClusterNodes.find(nodes => {
              return record.node && record.node.id === nodes.id;
            });
            isCandidate =
              node &&
              node.cluster_node_config &&
              node.cluster_node_config.is_candidate
                ? node.cluster_node_config.is_candidate
                : false;
          }
          return (
            <div>
              <ConnectedNodes
                canShowPopOverHelp={true}
                isVirtualNode={true}
                showRepNetwork={true}
                networkId={record.id}
                cursor="pointer"
                showPeers={!record.is_inheritance || isCandidate === true}
                isCluster={record.cluster && record.cluster.id ? true : false}
              />
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        width: "10%",
        render: (text, record, index) => {
          let actionViewNav, actionEditNav;
          if (record.node && record.node.id) {
            // For Node
            actionViewNav =
              "/orgs/" +
              record.organization.id +
              "/inodes/" +
              record.node.id +
              "/view/" +
              record.id;
            actionEditNav =
              "/orgs/" +
              record.organization.id +
              "/inodes/" +
              record.node.id +
              "/modify/" +
              record.id;
          } else {
            // For Cluster
            if (record.cluster && record.cluster.id) {
              actionViewNav =
                "/orgs/" +
                record.organization.id +
                "/clusters/" +
                record.cluster.id +
                "/view/" +
                record.id;
              actionEditNav =
                "/orgs/" +
                record.organization.id +
                "/clusters/" +
                record.cluster.id +
                "/modify/" +
                record.id;
            }
          }
          // Disable edit network action
          let disableEdit = false;
          // Deleted network, Disable edit
          if (record.deleted_at) {
            disableEdit = true;
          } else {
            // Inheritance network, Disable edit
            if (record.is_inheritance) {
              disableEdit = true;
            } else {
              // Cluster node wan network, Disable edit
              if (record.is_wan_network && this.state.isClusterNodes) {
                let node = this.state.isClusterNodes.find(nodes => {
                  return record.node && record.node.id === nodes.id;
                });
                disableEdit = node && node.cluster && node.cluster.id;
              }
            }
          }
          // Disable delete network action
          let disableDelete =
            record.deleted_at ||
            record.is_default_network ||
            record.is_wan_network ||
            record.is_inheritance;
          // View Action
          const view = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEye"
                actionLabel={getCurrentlocaleText("view.network.text")}
                iconButton
                title={getCurrentlocaleText("view.network.text")}
                route={actionViewNav}
                iconBtnStyle={{ fontSize: 14 }}
                disabled={
                  !(
                    this.props.AuthStore.IsPermitted("NETWORK:VIEW") ||
                    this.props.AuthStore.IsPermitted("ORG:SUPPORT")
                  )
                }
              />
            </span>
          );
          // Edit Action
          const edit = (
            <span>
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                iconButton
                title={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                route={actionEditNav}
                disabled={
                  disableEdit ||
                  !this.props.AuthStore.IsPermitted("NETWORK:CREATE")
                }
              />
            </span>
          );
          // Delete Action
          const remove = (
            <span>
              <ActionBtn
                className="inline"
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.title.text",
                      { 0: "Network" },
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.hover.text",
                      { 0: "Network" },
                    )}
                  </span>
                }
                icon="AiOutlineDelete"
                formData={record}
                map={{
                  id: record.id,
                }}
                resource="networks"
                iconButton
                action="delete"
                disabled={
                  disableDelete ||
                  !this.props.AuthStore.IsPermitted("NETWORK:DELETE")
                }
                afterCall={this.deleteAfterCall.bind()}
                controller={NetworkController}
                messageContent={
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        "network.delete.confirmation.message.text",
                      )}
                    </p>
                  </span>
                }
                warningMessage={getCurrentlocaleText(
                  "network.delete.warning.message.text",
                )}
                cancelText={getCurrentlocaleText(
                  "inode.delete.confirmation.cancel.text",
                  { 0: "Network" },
                )}
                okText={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineDelete"
                      style={{ margin: 0 }}
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0: "Network",
                    })}
                  </span>
                }
              >
                <Icons type="ai" name="AiOutlineDelete" style={{ margin: 0 }} />{" "}
                {getCurrentlocaleText("delete.confirmation.ok.text")}
              </ActionBtn>
            </span>
          );
          const menu = (
            <Menu>
              <Menu.Item key={0}>{view}</Menu.Item>
              <Menu.Item key={1}>{edit}</Menu.Item>
              <Menu.Item key={2}>{remove}</Menu.Item>
            </Menu>
          );
          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <DropdownActionBtn menu={menu} />
            </div>
          );
        },
      },
    ];
    if (!this.props.UiStore.showChildNetworks) {
      this.NetworksTableColumns.splice(1, 1);
    }

    // cluster column check only if cluster feature is enabled
    if (
      checkforFeatureEnabled(
        this.props.OrgViewModel.org.id != undefined
          ? this.props.OrgViewModel.org
          : this.props.AuthStore.loggedInUserOrg,
        "cluster",
      )
    ) {
      let cluster_column = {
        title: (
          <span title={getCurrentlocaleText("cluster.field.text")}>
            {getCurrentlocaleText("cluster.field.text")}
          </span>
        ),
        dataIndex: "cluster",
        key: "cluster_name",
        width: "16%",
        ellipsis: true,
        render: (text, record, index) => {
          let clusterLink =
            record.cluster && record.cluster.id
              ? `/orgs/${record.organization.id}/clusters/${record.cluster.id}`
              : null;
          return (
            <div
              title={record.cluster && record.cluster.name}
              className="col-wrap"
            >
              {record.cluster && record.cluster.id ? (
                <Link to={clusterLink}>
                  {record.cluster && record.cluster.name}
                </Link>
              ) : (
                <span style={{ marginLeft: 15 }}>{"-"}</span>
              )}
            </div>
          );
        },
      };
      this.NetworksTableColumns.splice(
        this.props.UiStore.showChildNetworks ? 2 : 1,
        0,
        cluster_column,
      );
    }

    let container = (
      <div className="nwk-table-container">
        <div className="list-container-div headertext">
          <span>{getCurrentlocaleText("networks.tabheader.label.text")}</span>
        </div>
        <div className="list-container-child">
          <div className="network-list-container-child-div network-list-container-child-div-margin">
            <Checkbox
              checked={this.props.UiStore.showChildNetworks}
              onChange={this.changeListview}
              disabled={
                this.props.OrgViewModel.org &&
                this.props.OrgViewModel.org.org_count > 0
                  ? false
                  : true
              }
            >
              {getCurrentlocaleText("network.list.table_view.checkbox.label")}
            </Checkbox>
          </div>
          <div className="network-list-container-child-div">
            <SearchInput
              placeholder={getCurrentlocaleText(
                "network.list.table.filter_all.placeholder.text",
              )}
              onChange={this.NetworkListFilter}
              value={this.state.searchText}
              clearSearch={this.clearSearchFilter}
            />
          </div>
        </div>
        {this.state.showBulkConfig && (
          <div>
            <BulkConfigure
              readOnly={!this.props.AuthStore.IsPermitted("NETWORK:CREATE")}
              getList={this.NetworkList}
              onAfterClick={this.props.onAfterClick}
              changeRoute={this.props.changeRoute}
              orgId={this.props.params.org_id}
              createTemplate={this.onNewTemplate}
              download={this.downloadNetworks}
              resource={"Network"}
            />
          </div>
        )}
        <div>
          <Row>
            <Col span={24}>
              {this.state.NetworkList && this.state.NetworkList.length > 0 ? (
                <TableLayoutV1
                  columns={this.NetworksTableColumns}
                  hideRowSelection={true}
                  dataSource={Networks}
                  pagination={{
                    pageSize:
                      this.NetworkViewModel &&
                      this.NetworkViewModel.pageable.size,
                    total:
                      this.NetworkViewModel &&
                      this.NetworkViewModel.pageable.total_count,
                    current: this.state.currentPage,
                  }}
                  className="inodeList"
                  expandedRowKeys={this.state.expandedRowKeys}
                  onExpand={this.onExpand}
                  getModalContent={record => {
                    return this.getModalContentJSX(record);
                  }}
                  onChange={this.onTableChange}
                  isTableModalToggle={isTableModalToggle}
                  modalContent={this.state.modalContent}
                  modalTitle={this.state.modalTitle}
                  disableFooter={true}
                />
              ) : (
                <div>
                  {!this.NetworkViewModel.loading && (
                    <h3> No Networks available</h3>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
    return (
      <LoadingComponent loading={this.NetworkViewModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default Networks;
