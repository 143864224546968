import { observable, action, computed } from "mobx";
import { isLoggedIn, ROOT_ORG } from "Core/Utils";

class AuthStore {
  @observable
  loggedInUser = {
    permissions: [],
    roles: [],
    organization: null,
  };
  @observable isBetaAllowed = false;
  @observable allowedBetafeatures = {};
  @observable loggedInUserOrg = null;
  @observable isCurrentOrgAPILoading = false;
  @observable isCurrentSessionExpired = false;
  // inialize the observables because of https://mobx.js.org/best/pitfalls.html
  constructor() {
    this.loggedInUser = {
      permissions: [],
      roles: [],
      organization: null,
    };
    this.loggedInUserOrg = null;
  }

  @action
  SetStoreData(type, data) {
    this[type] = data;
  }

  @action
  resetStore() {
    this.loggedInUser = {
      permissions: [],
      roles: [],
      organization: null,
    };
    this.loggedInUserOrg = null;
  }

  /*-----------------------TO GET COMPONENT LEVEL PERMISSION-------------------------*/
  @action
  IsPermitted(level) {
    switch (level) {
      case "ORG:VIEW":
        var allowed = ["ORG:ADMIN", "ORG:READ", "SUPER:ADMIN"];
        break;
      case "ORG:DELETE":
      case "ORG:CREATE":
        var allowed = ["ORG:ADMIN", "SUPER:ADMIN"];
        break;
      case "NODE:VIEW":
        var allowed = [
          "NODE:ADMIN",
          "NODE:READ",
          "NETWORK:READ",
          "NETWORK:ADMIN",
          "SERVICE:READ",
          "SERVICE:ADMIN",
          "NODE:REBOOT",
          "NETWORK:CONNECT_DISCONNECT",
          "SUPER:ADMIN",
        ];
        break;
      case "NODE:CREATE":
      case "NODE:DELETE":
        var allowed = ["NODE:ADMIN", "SUPER:ADMIN"];
        break;
      case "NETWORK:DIAGNOSTIC":
        var allowed = ["NODE:ADMIN", "SUPER:ADMIN", "NETWORK:DIAGNOSTIC"];
        break;
      case "NODE:REBOOT":
        var allowed = ["NODE:ADMIN", "NODE:REBOOT", "SUPER:ADMIN"];
        break;
      case "USER:VIEW":
        var allowed = [
          "USER:ADMIN",
          "USER:READ",
          "ROLE:ADMIN",
          "ROLE:READ",
          "ORG:ADMIN",
          "ORG:READ",
          "SUPER:ADMIN",
        ];
        break;
      case "USER:ADMIN":
        var allowed = ["USER:ADMIN", "SUPER:ADMIN"];
        break;
      case "USER:SHOW":
        var allowed = [
          "USER:ADMIN",
          "USER:READ",
          "ORG:ADMIN",
          "ORG:READ",
          "SUPER:ADMIN",
        ];
        break;
      case "USER:CREATE":
      case "USER:DELETE":
        var allowed = ["USER:ADMIN", "ORG:ADMIN", "SUPER:ADMIN"];
        break;
      case "ROLE:VIEW":
        var allowed = [
          "ROLE:ADMIN",
          "ROLE:READ",
          "ORG:ADMIN",
          "ORG:READ",
          "USER:READ",
          "USER:ADMIN",
          "SUPER:ADMIN",
        ];
        break;
      case "ROLE:CREATE":
        var allowed = ["ROLE:ADMIN", "ORG:ADMIN", "USER:ADMIN", "SUPER:ADMIN"];
        break;
      case "NETWORK:CONNECT_DISCONNECT":
        var allowed = [
          "NETWORK:ADMIN",
          "NETWORK:CONNECT_DISCONNECT",
          "NODE:READ",
          "NODE:ADMIN",
          "NETWORK:READ",
          "SERVICE:READ",
          "SERVICE:ADMIN",
          "SUPER:ADMIN",
        ];
        break;
      case "NETWORK:VIEW":
        var allowed = [
          "NETWORK:ADMIN",
          "NETWORK:CONNECT_DISCONNECT",
          "NODE:READ",
          "NODE:ADMIN",
          "NETWORK:READ",
          "SERVICE:READ",
          "SERVICE:ADMIN",
          "SUPER:ADMIN",
        ];
        break;
      case "NETWORK:CREATE":
      case "NETWORK:DELETE":
      case "REPNET:ADMIIN":
        var allowed = ["NETWORK:ADMIN", "NODE:ADMIN", "SUPER:ADMIN"];
        break;
      case "SERVICE:VIEW":
        var allowed = [
          "SERVICE:ADMIN",
          "SERVICE:READ",
          "NODE:ADMIN",
          "ORG:ADMIN",
          "ORG:READ",
          "SUPER:ADMIN",
        ];
        break;
      case "SERVICE:CREATE":
      case "SERVICE:DELETE":
        var allowed = [
          "SERVICE:ADMIN",
          "NODE:ADMIN",
          "ORG:ADMIN",
          "SUPER:ADMIN",
        ];
        break;
      case "SUPER:ADMIN":
        var allowed = ["SUPER:ADMIN"];
        break;
      case "BETA:FEATURE":
        var allowed = [];
        allowed = ["BETA:FEATURE"];
        return this.allowedBeta();
        break;
      case "SESSION:VIEW":
        var allowed = ["ORG:ADMIN", "ORG:SUPPORT", "SUPER:ADMIN", "USER:ADMIN"];
        break;
      case "MOVE:SERIAL":
        var allowed = ["ORG:ADMIN", "SUPER:ADMIN", "ORG:SUPPORT"];
        break;
      case "SHOW:DISCOVERY":
        var allowed = [
          "NETWORK:ADMIN",
          "NETWORK:CONNECT_DISCONNECT",
          "NODE:ADMIN",
          "SUPER:ADMIN",
          "ORG:SUPPORT",
        ];
        break;
      case "ORG:SUPPORT":
        var allowed = ["ORG:SUPPORT", "SUPER:ADMIN"];
        break;
      case "SECURITY:ADMIN":
        var allowed = [
          "NODE:ADMIN",
          "NETWORK:ADMIN",
          "SUPER:ADMIN",
          "ORG:ADMIN",
        ];
        break;
      case "DISCOVERY:ADMIN":
        var allowed = [
          "NODE:ADMIN",
          "NETWORK:ADMIN",
          "SUPER:ADMIN",
          "ORG:ADMIN",
        ];
        break;
      case "RESET:2FA":
        var allowed = ["ORG:SUPPORT", "SUPER:ADMIN", "ORG:ADMIN"];
        break;
      case "ACTIVITY:VIEW":
        var allowed = [
          "ORG:SUPPORT",
          "SUPER:ADMIN",
          "ORG:ADMIN",
          "ACTIVITY:VIEW",
        ];
        break;
      case "ACTIVITY:DOWNLOAD":
        var allowed = ["SUPER:ADMIN", "ACTIVITY:DOWNLOAD"];
        break;
      case "APIKEY:VIEW":
        var allowed = ["ORG:SUPPORT", "SUPER:ADMIN", "ORG:ADMIN", "USER:ADMIN"];
        break;
      case "APIKEY:REVOKE":
        var allowed = ["ORG:SUPPORT", "SUPER:ADMIN", "ORG:ADMIN", "USER:ADMIN"];
        break;
      case "SSHKEY:ADMIN":
        var allowed = ["ORG:ADMIN", "NODE:ADMIN", "SUPER:ADMIN"];
        break;
      case "IMAGE:DELETE":
        var allowed = ["SUPER:ADMIN", "NODE:ADMIN", "SERVICE:ADMIN"];
        break;
      case "EVENTS:VIEW":
        var allowed = [
          "NODE:READ",
          "NETWORK:READ",
          "SERVICE:READ",
          "SUPER:ADMIN",
          "ORG:ADMIN",
          "ORG:SUPPORT",
          "EVENT:VIEW",
        ];
        break;
      case "EVENTS:DOWNLOAD":
        var allowed = ["SUPER:ADMIN", "EVENT:DOWNLOAD"];
        break;
      case "UPGRADE_HISTORY:VIEW":
        var allowed = ["SUPER:ADMIN", "ORG:SUPPORT"];
        break;
      case "RELEASESYNC:VIEW":
        var allowed = ["SUPER:ADMIN", "ORG:SUPPORT"];
        break;
      case "SERVICE:RESTART":
        var allowed = ["SERVICE:ADMIN", "NODE:ADMIN", "NODE:REBOOT"];
        break;
      case "USERALERT:VIEW":
        var allowed = ["SUPER:ADMIN", "ORG:SUPPORT", "ORG:ADMIN"];
        break;
      case "CLUSTER:CREATE":
      case "CLUSTER:DELETE":
        var allowed = ["NODE:ADMIN", "SUPER:ADMIN"];
        break;
      case "CLUSTER:VIEW":
        var allowed = ["NODE:READ", "NODE:ADMIN", "SUPER:ADMIN"];
        break;
      case "INODELOG:VIEW":
        var allowed = ["SUPER:ADMIN", "NETWORK:DIAGNOSTIC"];
        break;
      case "SERIAL:CREATE":
        var allowed = ["SUPER:ADMIN", "PKI:ADMIN"];
        break;
      case "TECHDUMP:VIEW":
        var allowed = ["SUPER:ADMIN", "PKI:ADMIN", "NETWORK:DIAGNOSTIC"];
        break;
      case "KERNEL:VIEW":
        var allowed = [
          "SUPER:ADMIN",
          "PKI:ADMIN",
          "NETWORK:DIAGNOSTIC",
          "ORG:SUPPORT",
        ];
        break;
      case "HSN_REPLACE:ADMIN":
        var allowed = ["SUPER:ADMIN", "NODE:ADMIN", "ORG:SUPPORT"];
        break;
      case "REPNET_HIERARCHY:VIEW":
        var allowed = ["SUPER:ADMIN", "ORG:SUPPORT"];
        break;
      case "CLOUDROUTE:ADMIN":
        var allowed = ["SUPER:ADMIN", "CLOUDROUTE:ADMIN"];
        break;
      case "SERIAL:MOVE":
        var allowed = ["ORG:ADMIN", "PKI:MOVE", "SUPER:ADMIN", "PKI:ADMIN"];
        break;
      case "IOTIUM:ORG:ADMIN":
        return this.allowedIotiumOrgAdmin();
      //Default case null
      default:
        var allowed = [];
        break;
    }
    return this.isPermitted(allowed);
  }
  //*-----------------check beta features ---------------------------------*/
  allowedBeta() {
    if (
      this.isBetaAllowed ||
      this.loggedInUser.permissions.includes("SUPER:ADMIN")
    )
      return true;
    else return false;
  }
  /*---------------Checking the Userlevel permission ---------------------*/
  isPermitted = allowed => {
    let retValue = false;

    // If there is no permission to check then return false.
    if (!allowed || allowed.length === 0) {
      return false;
    }

    // Logined user information not update in store. so until show loading page.
    if (
      this.loggedInUser &&
      this.loggedInUser.permissions &&
      this.loggedInUser.permissions.length === 0
    ) {
      return true;
    }

    allowed.forEach(element => {
      // Skip check for other permission If already allowed for one permission
      if (!retValue) {
        retValue = this.loggedInUser.permissions.includes(element);
      }
    });
    return retValue;
  };

  allowedIotiumOrgAdmin() {
    if (
      this.loggedInUser &&
      this.loggedInUser.permissions &&
      this.loggedInUser.permissions.length > 0 &&
      (this.loggedInUser.permissions.includes("ORG:ADMIN") ||
        this.loggedInUser.permissions.includes("SUPER:ADMIN")) &&
      this.loggedInUser.organization &&
      this.loggedInUser.organization.id === ROOT_ORG
    ) {
      return true;
    } else {
      return false;
    }
  }

  isLoggedInUserOrgIsRoot() {
    return this.loggedInUser.organization &&
      this.loggedInUser.organization.id === ROOT_ORG
      ? true
      : false;
  }

  @computed
  get isCurrentUserAPISuccess() {
    if (
      this.loggedInUser &&
      this.loggedInUser.permissions &&
      this.loggedInUser.permissions.length > 0 &&
      this.loggedInUserOrg
    ) {
      return true;
    }
    return false;
  }

  /*----------------------------Checking Super Admin ------------------------------*/
  @computed
  get IsSuperAdmin() {
    let CurrentOrgData = this.loggedInUser
      ? this.loggedInUser.organization
      : [];
    let SuperAdminId = this.loggedInUser.organization
      ? CurrentOrgData.id
      : null;
    // ROOT_ORG IoTium Root Org ID
    if (SuperAdminId === ROOT_ORG) return true;
    else return false;
  }

  @computed
  get loggedIn() {
    if (isLoggedIn() && this.loggedInUser) {
      return true;
    } else {
      return false;
    }
  }
}

export default new AuthStore();
