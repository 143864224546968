import React, { Component } from "react";
import { observer, inject } from "mobx-react";
// Branding icon
import BrandingLogo from "../../assets/img/tag.svg";
import { Row, Col, Card, Button, Modal } from "antd";
import Icons from "components/UI-Components/Icons";
import BreadcrumbComponent from "components/UI-Components/BreadcrumbComponent";
import OrgSelector from "components/Orgs/OrgSelector";
import BrandingForm from "components/Forms/BrandingForm";
import HelpCard from "components/UI-Components/HelpCard";
import OrgController from "controller/OrgController";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import {
  copyObject,
  getCurrentlocaleText,
  isEmpty,
  checkforFeatureEnabled,
  getFontFamilyFromURL,
} from "Core/Utils";
import AccessDenied from "components/Containers/AccessDenied";

@inject("UiStore", "AuthStore", "OrgViewModel")
@observer
class BrandingPage extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.orgId =
      this.props.match && this.props.match.params
        ? this.props.match.params.id
        : null;
    this.state = {
      orgName: null,
      BreadcrumbList: [],
      org: null,
      visible: false,
      isBranding: false,
      isBrandingEnabled: true,
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    OrgController.getOrgById(this.orgId).then(response => {
      if (response) {
        let org = copyObject(response);
        let orgName = org.name;
        let BreadcrumbList = [
          {
            name: orgName,
            link: "dashboard/" + this.orgId,
          },
          {
            name: getCurrentlocaleText("branding.text"),
            link: window.location.pathname.substring(1),
          },
        ];
        let isBranding = false;
        if (org.branding && !isEmpty(org.branding)) {
          isBranding = true;
        }
        this.setState({
          BreadcrumbList: BreadcrumbList,
          orgName: orgName,
          org: org,
          isBranding: isBranding,
          isBrandingEnabled: checkforFeatureEnabled(org, "branding"),
          loading: false,
        });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.org !== this.state.org) {
      this.setState({ org: props.org });
    }
  }

  submitForm = () => {
    this.brandingForm.validateFields((err, values) => {
      let isEmptyBrandingForm =
        !values ||
        (!values.dark_logo_url &&
          !values.light_logo_url &&
          !values.light_logo_id &&
          !values.dark_logo_id);
      if (!err && !isEmptyBrandingForm) {
        // For Login background urls
        let login_background_urls = [];
        let login_background_ids = values.login_background_id
          ? values.login_background_id
          : [];
        if (values) {
          let keys = Object.keys(values).filter(key => {
            return key.startsWith("login_background_url_");
          });
          let logo_keys = Object.keys(values).filter(logo_key => {
            return logo_key.startsWith("login_background_id");
          });
          logo_keys.forEach(logo_key => {
            if (values[logo_key] && values[logo_key].length > 0) {
              login_background_ids.push(values[logo_key]);
            }
            delete values[logo_key];
          });
          if (login_background_ids.length > 0) {
            values["login_background_id"] = login_background_ids;
          }
          keys.forEach(key => {
            if (values[key] && values[key].length > 0) {
              login_background_urls.push(values[key]);
            }
            delete values[key];
          });
          if (!values.dark_logo_url && !values.dark_logo_id) {
            values["dark_logo_url"] = "";
          }
          if (!values.light_logo_url && !values.light_logo_id) {
            values["light_logo_url"] = "";
          }
          if (login_background_urls.length > 0) {
            values["login_background_url"] = login_background_urls;
          } else {
            values["login_background_url"] = [];
          }
          // For font family url
          if (values.font_source_url) {
            let font_name = "";
            let fonts = getFontFamilyFromURL(values.font_source_url);
            // Set the font-family.
            font_name = fonts.toString();
            values["font_family_name"] = font_name;
          } else if (values.font_source_url === "") {
            values["font_family_name"] = "";
          }
        }
        OrgController.configureBranding(this.orgId, values).then(response => {
          if (response) {
            this.UiStore.setSuccessMessage(
              getCurrentlocaleText("branding.success.message"),
            );
            this.cancelForm();
          }
        });
      }
      if (isEmptyBrandingForm) {
        this.UiStore.setErrorMessage(
          getCurrentlocaleText("branding.form.empty.error"),
        );
      }
    });
  };

  cancelForm = () => {
    try {
      window.history.back();
    } catch (e) {}
  };

  showResetModal = () => {
    this.setState({ visible: true });
  };

  handleResetOk = () => {
    OrgController.resetBranding(this.orgId).then(response => {
      if (response) {
        this.UiStore.setSuccessMessage(
          getCurrentlocaleText("branding.reset.success.message"),
        );
        try {
          window.history.back();
        } catch (e) {}
      }
    });
  };

  handleResetCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    let { orgName, BreadcrumbList } = this.state;

    let container = (
      <div>
        {this.state.isBrandingEnabled ? (
          <div>
            <Row gutter={2} type="flex" align="top" className="branding-page">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <Card
                  title={
                    <Row gutter={2} type="flex" align="top">
                      <Col span={12}>
                        <div className="iot-main-card-title">
                          <div style={{ display: "inline-block" }}>
                            <BrandingLogo width="32" height="32" />
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              position: "absolute",
                              marginLeft: 7,
                            }}
                          >
                            {getCurrentlocaleText("branding.text")}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {this.state.isBranding &&
                            this.props.AuthStore.IsPermitted("ORG:CREATE") && (
                              <Button
                                type="primary"
                                onClick={this.showResetModal}
                                title={getCurrentlocaleText(
                                  "branding.reset.button.title",
                                )}
                              >
                                <Icons
                                  type="ai"
                                  name="AiOutlineRollback"
                                  style={{ fontSize: 16 }}
                                />
                                {getCurrentlocaleText(
                                  "branding.reset.button.text",
                                )}
                              </Button>
                            )}
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  <Row gutter={2} type="flex" align="top">
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 13 }}
                      lg={{ span: 13 }}
                      xl={{ span: 12 }}
                      className="card-grid"
                      style={{
                        paddingRight: 20,
                        borderRight: "solid 1px rgba(0,0,0,0.2)",
                      }}
                    >
                      <div>
                        <BrandingForm
                          viewOnly={
                            !this.props.AuthStore.IsPermitted("ORG:CREATE")
                          }
                          orgId={this.orgId}
                          ref={c => (this.brandingForm = c)}
                          org={this.state.org}
                        />
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 11 }}
                      lg={{ span: 11 }}
                      xl={{ span: 12 }}
                    >
                      <HelpCard
                        customStyle={{
                          borderLeft: "none",
                        }}
                        span={24}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="col-center">
                      <div style={{ marginTop: 25 }}>
                        <Button
                          style={{ marginRight: 10 }}
                          title={
                            this.props.AuthStore.IsPermitted("ORG:CREATE")
                              ? getCurrentlocaleText("cancel.button.text")
                              : getCurrentlocaleText("close.form.button.text")
                          }
                          onClick={this.cancelForm}
                        >
                          {this.props.AuthStore.IsPermitted("ORG:CREATE")
                            ? getCurrentlocaleText("cancel.button.text")
                            : getCurrentlocaleText("close.form.button.text")}
                        </Button>
                        {this.props.AuthStore.IsPermitted("ORG:CREATE") && (
                          <Button
                            type="primary"
                            onClick={this.submitForm}
                            title={getCurrentlocaleText("save.button.text")}
                            disabled={
                              this.props.OrgViewModel.isLogoUploadPending
                            }
                          >
                            <Icons
                              type="ai"
                              name="AiOutlineSave"
                              style={{ margin: 0 }}
                            />{" "}
                            {getCurrentlocaleText("save.button.text")}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            {/** Branding Reset Modal */}
            <Modal
              title={
                <span>
                  <Icons
                    type="ai"
                    name="AiOutlineRollback"
                    style={{ fontSize: 16 }}
                  />{" "}
                  {getCurrentlocaleText("branding.reset.modal.title")}
                </span>
              }
              visible={this.state.visible}
              onOk={this.handleResetOk}
              onCancel={this.handleResetCancel}
              okText={getCurrentlocaleText("branding.reset.modal.oktext")}
              cancelText={getCurrentlocaleText(
                "branding.reset.modal.canceltext",
              )}
            >
              <p>{getCurrentlocaleText("branding.reset.modal.content")}</p>
            </Modal>
          </div>
        ) : (
          <AccessDenied />
        )}
      </div>
    );

    return (
      <div>
        <Row gutter={16} type="flex" align="middle">
          <Col lg={{ span: 18 }} xl={{ span: 20 }}>
            <h2 className="page-title">
              <Icons type="fa" name="FaSitemap" />
              {"  "}
              {orgName ? orgName : null}
            </h2>
            <BreadcrumbComponent
              {...this.props}
              BreadcrumbList={BreadcrumbList}
            />
          </Col>
          <Col lg={{ span: 6 }} xl={{ span: 4 }} className="flex flex-h-right">
            <OrgSelector {...this.props} />
          </Col>
        </Row>
        <LoadingComponent loading={this.state.loading}>
          {container}
        </LoadingComponent>
      </div>
    );
  }
}

export default BrandingPage;
