import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox, Button, Tabs } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";
import BreadcrumbComponent from "../UI-Components/BreadcrumbComponent";
import OrgSelector from "../Orgs/OrgSelector";
import Icons from "../UI-Components/Icons";
import OrgController from "../../controller/OrgController";
import UiStore from "../../Stores/UiStore";
import OrgViewModel from "../../view-model/OrgViewModel";
import SearchInput from "../UI-Components/SearchInput";
import RepNetTable from "./RepNetTable";
import RepNetViewModel from "../../view-model/RepNetViewModel";
import { downloadRepNet } from "../../controller/RepNetController";

const RepNet = props => {
  const [currentOrgDetails, setCurrOrgDetails] = useState();
  const [breadcrumbList, setBreadcrumbList] = useState();
  const [activeTab, setActiveTab] = useState();
  const [showChildNodes, setShowChildNodes] = useState(false);
  const [searchText, setSearchText] = useState("");
  const orgId = props.match.params.id;

  useEffect(() => {
    handleActiveTab(props.match.params.type);
  }, []);

  useEffect(() => {
    getOrgInfo();
  }, [orgId]);

  let getOrgInfo = () => {
    OrgController.getOrg(
      orgId,
      false,
      UiStore.parentOrgData && UiStore.parentOrgData.id ? false : true,
    ).then(res => {
      setBreadcrumbList([
        {
          name: res.name,
          link: `orgs/${res.id}/orgs`,
        },
        {
          name: getCurrentlocaleText("networks.tabheader.label.text"),
          link: `orgs/${res.id}/networks`,
        },
        {
          name: getCurrentlocaleText("repnet.table.title"),
          link: `orgs/${res.id}/repnet`,
        },
      ]);
      setCurrOrgDetails(res);
    });
  };

  const handleActiveTab = val => {
    let tab;
    switch (val) {
      case "fromVirtual":
        tab = "fromVirtual";
        break;
      case "edgeStaticRoutes":
        tab = "edgeStaticRoutes";
        break;
      case "fromEdge":
      default:
        tab = "fromEdge";
        break;
    }
    setActiveTab(tab);
  };

  const changeActiveTab = e => {
    handleActiveTab(e);
    props.history.push("/orgs/" + orgId + "/repnet/" + e);
  };

  setTimeout(() => {
    let ele = document.getElementById("alsoChild");
    if (ele) {
      ele.innerHTML = getCurrentlocaleText(
        "network.list.table_view.checkbox.br.label",
      );
    }
  }, 100);

  const handleExport = () => {
    let splitVal =
      activeTab &&
      RepNetViewModel &&
      RepNetViewModel.pageable &&
      RepNetViewModel.pageable[activeTab] &&
      RepNetViewModel.pageable[activeTab].self &&
      RepNetViewModel.pageable[activeTab].self.length
        ? RepNetViewModel.pageable[activeTab].self.split("?")
        : [];

    let params = splitVal && splitVal[1] ? splitVal[1] : "";
    downloadRepNet(activeTab, params);
  };

  return (
    <div className="repnet">
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <h2 className="page-title">
            <Icons type="fa" name="FaSitemap" />
            {"  "}
            {currentOrgDetails && currentOrgDetails.name
              ? currentOrgDetails.name
              : null}
          </h2>
          <BreadcrumbComponent {...props} BreadcrumbList={breadcrumbList} />
        </Col>
        <Col align="right">
          <OrgSelector {...props} className="org-selector-menu-padding" />
        </Col>
      </Row>
      <Row type="flex" align="middle" justify="space-between">
        <Col className="headertext">
          {getCurrentlocaleText("repnet.table.title")}
        </Col>
        <Col type="flex">
          <Row type="flex" align="middle" gutter="10">
            <Col>
              <Checkbox
                checked={showChildNodes}
                onChange={e => setShowChildNodes(e.target.checked)}
                className="all-center"
                disabled={
                  OrgViewModel.org && OrgViewModel.org.org_count > 0
                    ? false
                    : true
                }
              >
                <span id="alsoChild"></span>
              </Checkbox>
            </Col>
            <Col>
              {activeTab != "fromVirtual" && (
                <SearchInput
                  placeholder={getCurrentlocaleText("general.filter.any.text")}
                  className="rounded-filter"
                  size="large"
                  onChange={e => setSearchText(e.target.value)}
                  value={searchText}
                  clearSearch={e => setSearchText("")}
                />
              )}
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                className="btn-rounded"
                onClick={handleExport}
              >
                <i className="fa-solid fa-file-export pr-10"></i>
                {getCurrentlocaleText("bulk.job.export")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Tabs
        className="tab-no-border mt-10"
        activeKey={activeTab}
        onChange={e => changeActiveTab(e)}
      >
        <Tabs.TabPane
          tab={getCurrentlocaleText("repnet.tab.from.edge.title")}
          key="fromEdge"
        >
          <RepNetTable
            from="fromEdge"
            showChildNodes={showChildNodes}
            searchText={searchText}
            orgId={orgId}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={getCurrentlocaleText("repnet.tab.from.vir.title")}
          key="fromVirtual"
        >
          <RepNetTable
            from="fromVirtual"
            showChildNodes={showChildNodes}
            searchText={searchText}
            orgId={orgId}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={getCurrentlocaleText("repnet.tab.edge_static_routes.title")}
          key="edgeStaticRoutes"
        >
          <RepNetTable
            from="edgeStaticRoutes"
            showChildNodes={showChildNodes}
            searchText={searchText}
            orgId={orgId}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default RepNet;
