import React, { Component } from "react";
import { Card, Select, Spin, Row, Col, Modal, Button } from "antd";
import {
  customSort,
  getInterval,
  getCurrentlocaleText,
  humanizeMetric,
} from "Core/Utils";
import Chart from "react-google-charts";
import { observer, inject } from "mobx-react";
import { NetworkController } from "../../../controller/Controllers";
import Icons from "../../UI-Components/Icons";
import { LoadingOutlined } from "@ant-design/icons";

const Option = Select.Option;

@inject(
  "AuthStore",
  "EventViewModel",
  "ServiceStatsViewModel",
  "NodeStatsViewModel",
  "InodeViewModel",
)
@observer
class NetworkChart extends Component {
  constructor(props) {
    super(props);
    // GLOBAL_SETTINGS is configured form node js
    this.GLOBAL_SETTINGS = GLOBAL_SETTINGS;
    this.textColor =
      this.GLOBAL_SETTINGS && this.GLOBAL_SETTINGS.text_color
        ? this.GLOBAL_SETTINGS.text_color
        : "#365c6f";
    this.eventModel = this.props.EventViewModel;
    this.inodeModel = this.props.InodeViewModel;

    this.state = {
      interfaces: this.props.match.params.containerId
        ? this.props.ServiceStatsViewModel.getServiceNetworkStatsAndInterfaces
            .interfaces
        : this.props.NodeStatsViewModel.getStatsAndInterfaces.interfaces,
      loading: false,
      currentInterface: this.props.match.params.containerId
        ? this.props.ServiceStatsViewModel.getServiceNetworkStatsAndInterfaces
            .interfaces &&
          this.props.ServiceStatsViewModel.getServiceNetworkStatsAndInterfaces
            .interfaces.length > 0 &&
          this.props.ServiceStatsViewModel.getServiceNetworkStatsAndInterfaces
            .interfaces[0].name
        : this.inodeModel.interfacesList,
      data: this.props.match.params.containerId
        ? this.props.ServiceStatsViewModel.getServiceNetworkStatsAndInterfaces
            .stats
        : this.props.NodeStatsViewModel.getStatsAndInterfaces.stats,
      chartModalvisible: false,
      computed_networks: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (this.props != newprops) {
      this.setState({
        interfaces: newprops.match.params.containerId
          ? newprops.ServiceStatsViewModel.getServiceNetworkStatsAndInterfaces
              .interfaces
          : newprops.NodeStatsViewModel.getStatsAndInterfaces.interfaces,
        loading: newprops.loading,
        data: newprops.match.params.containerId
          ? newprops.ServiceStatsViewModel.getServiceNetworkStatsAndInterfaces
              .stats
          : newprops.NodeStatsViewModel.getStatsAndInterfaces.stats,
      });
      this.updateInterface();
    }
  }
  buildNodeEvents = (name, status) => {
    return (
      "<span style='color:#666'><table style='padding:2px;  font-size:12px;min-width:50px;'><tr><td>" +
      getCurrentlocaleText("resource.name.text") +
      ":</td><td>" +
      name +
      "</td></tr><tr><td>" +
      getCurrentlocaleText("inode_status_alerts.text") +
      ":</td><td><strong style='color:" +
      this.getStatusStyle(status) +
      "'>" +
      status +
      "</strong></td></tr></table></span>"
    );
  };

  buildServiceEvents = (name, status) => {
    return (
      "<span style='color:#666'><table style='padding:2px;  font-size:12px;min-width:50px;'><tr><td>" +
      getCurrentlocaleText("resource.name.text") +
      ":</td><td>" +
      name +
      "</td></tr><tr><td>" +
      getCurrentlocaleText("events.service_status.header.text") +
      ":</td><td><strong style='color:" +
      this.getStatusStyle(status) +
      "'>" +
      status +
      "</strong></td></tr></table></span>"
    );
  };

  buildNetworkEvents = (record, status) => {
    return (
      "<span style='color:#666'><table style='padding:2px;  font-size:12px;min-width:50px;'><tr><td>" +
      getCurrentlocaleText("resource.name.text") +
      ":</td><td><span>&nbsp;" +
      record.network.name +
      "</span><span>&nbsp;\u27F7&nbsp;" +
      record.peer_node.name +
      "/" +
      record.peer_network.name +
      "</span></td></tr><tr><td>" +
      getCurrentlocaleText("tunnel_status_alerts.text") +
      ":</td><td><strong style='color:" +
      this.getStatusStyle(status) +
      "'>" +
      status +
      "</strong></td></tr></table></span>"
    );
  };

  getStatusStyle = status => {
    if (status) {
      switch (status.toLowerCase()) {
        case "terminated":
        case "unreachable":
          return "#f04134";
        case "healthy":
        case "alive":
        case "connected":
          return "#00a854";
        case "reboot":
          return "#ffbf00";
        default:
          return "#ffbf00";
      }
    }
  };

  componentDidUpdate() {
    this.updateInterface();
  }

  getMinWindow = data => {
    if (data) {
      var min = Infinity;
      var max = -Infinity;
      for (var i = 0; i < data.length; i++) {
        // Find min, max.
        for (var j = 1; j < data[i].length; j++) {
          var val = data[i][j];
          if (val < min) {
            min = val;
          }
          if (val > max) {
            max = val;
          }
        }
      }
      // We don't want to show any values less than 0 so cap the min value at that.
      // At the same time, show 10% of the graph below the min value if we can.
      var minWindow = min - (max - min) / 10;
      if (minWindow < 0) {
        minWindow = 0;
      }

      return minWindow;
    } else {
      return 0;
    }
  };
  drawNetworkBytes = () => {
    let stats = this.state.data;
    let interfaces = this.state.interfaces;
    let interfaceName = this.props.NodeStatsViewModel.currentInterface
      ? this.props.NodeStatsViewModel.currentInterface
      : interfaces[0] && interfaces[0].name
      ? interfaces[0].name
      : null;
    let events = this.eventModel.eventList || [];
    if (events.length > 0 && !this.props.isFromOverview)
      stats = stats.concat(events);

    // Get interface index.
    var curInterfaceIndex = -1;
    var prevInterfaceIndex = -1;
    var data = [];
    let byteRxValues = 0;
    let byteTxValues = 0;

    for (var i = 1; i < stats.length; i++) {
      var cur = stats[i];
      var prev = stats[i - 1];
      if (
        cur.network &&
        cur.network.interfaces &&
        cur.network.interfaces.length > 0
      ) {
        curInterfaceIndex = this.getNetworkInterfaceIndex(
          interfaceName,
          cur.network.interfaces,
        );
      }
      if (
        prev.network &&
        prev.network.interfaces &&
        prev.network.interfaces.length > 0
      ) {
        prevInterfaceIndex = this.getNetworkInterfaceIndex(
          interfaceName,
          prev.network.interfaces,
        );
      }
      var intervalInSec =
        getInterval(cur.timestamp, prev.timestamp) / 1000000000;

      var elements = [];
      if (cur && cur.timestamp) {
        if (
          curInterfaceIndex >= 0 &&
          prevInterfaceIndex >= 0 &&
          cur.network &&
          cur.network.interfaces &&
          cur.network.interfaces.length > 0 &&
          cur.network.interfaces[curInterfaceIndex] &&
          cur.network.interfaces[curInterfaceIndex].name &&
          cur.network.interfaces[curInterfaceIndex].name !== "" &&
          cur.network.interfaces[curInterfaceIndex].tx_bytes >= 0 &&
          cur.network.interfaces[curInterfaceIndex].rx_bytes >= 0 &&
          prev.network &&
          prev.network.interfaces &&
          prev.network.interfaces.length > 0 &&
          prev.network.interfaces[prevInterfaceIndex] &&
          prev.network.interfaces[prevInterfaceIndex].name &&
          prev.network.interfaces[prevInterfaceIndex].name !== "" &&
          prev.network.interfaces[prevInterfaceIndex].tx_bytes >= 0 &&
          prev.network.interfaces[prevInterfaceIndex].rx_bytes >= 0
        ) {
          elements.push(new Date(cur.timestamp));
          let tx_bytes =
            (cur.network.interfaces[curInterfaceIndex].tx_bytes -
              prev.network.interfaces[prevInterfaceIndex].tx_bytes) /
            intervalInSec;
          let rx_bytes =
            (cur.network.interfaces[curInterfaceIndex].rx_bytes -
              prev.network.interfaces[prevInterfaceIndex].rx_bytes) /
            intervalInSec;

          if (tx_bytes > byteTxValues) byteTxValues = tx_bytes;
          if (rx_bytes > byteRxValues) byteRxValues = rx_bytes;
          elements.push(
            tx_bytes > 0
              ? { v: Math.round(tx_bytes), f: humanizeMetric(tx_bytes) }
              : { v: 0, f: humanizeMetric(0) },
          );
          elements.push(
            rx_bytes > 0
              ? { v: Math.round(rx_bytes), f: humanizeMetric(rx_bytes) }
              : { v: 0, f: humanizeMetric(0) },
          );
          if (events.length > 0 && !this.props.isFromOverview) {
            elements.push(undefined);
            elements.push(undefined);
            elements.push(undefined);
          }
        } else {
          elements.push(new Date(cur.timestamp));
          elements.push(null);
          elements.push(null);
          if (events.length > 0 && !this.props.isFromOverview) {
            elements.push(undefined);
            elements.push(undefined);
            elements.push(undefined);
          }
        }
      } else {
        let currentEventInfo = stats[i];
        let name = undefined;
        let status = undefined;
        elements.push(new Date(currentEventInfo.created_at));
        elements.push(undefined);
        elements.push(undefined);
        elements.push(0);
        if (currentEventInfo.type == "service") {
          name = currentEventInfo.service && currentEventInfo.service.name;
          status = currentEventInfo.status;
          elements.push(
            "point { size: 6; shape-type: square; fill-color: " +
              this.getStatusStyle(status) +
              "; visible: true; sides: 5; dent: 0.05; ",
          );
          elements.push(this.buildServiceEvents(name, status));
        } else if (currentEventInfo.type == "node") {
          name = currentEventInfo.node && currentEventInfo.node.name;
          status = currentEventInfo.status;
          elements.push(
            "point { size: 6; shape-type: triangle; fill-color: " +
              this.getStatusStyle(status) +
              "; visible: true; ",
          );
          elements.push(this.buildNodeEvents(name, status));
        } else if (currentEventInfo.type == "network") {
          name = currentEventInfo.network && currentEventInfo.network.name;
          status = currentEventInfo.status;
          elements.push(
            "point { size: 6; shape-type: star; fill-color: " +
              this.getStatusStyle(status) +
              "; visible: true; ",
          );
          elements.push(this.buildNetworkEvents(currentEventInfo, status));
        } else {
          elements.push(undefined);
          elements.push(undefined);
        }
      }
      if (elements.length > 0 && elements.length < 7) data.push(elements);
    }
    let computedMaxVal =
      byteRxValues > byteTxValues ? byteRxValues : byteTxValues;
    let ticks = [
      { v: 0, f: "0" },
      {
        v: computedMaxVal / 2,
        f: humanizeMetric(computedMaxVal / 2, false, true),
      },
      {
        v: computedMaxVal + computedMaxVal / 4,
        f: humanizeMetric(computedMaxVal + computedMaxVal / 4, false, true),
      },
    ];
    let display_name = this.props.NodeStatsViewModel.currentContainerDisplayName
      ? this.props.NodeStatsViewModel.currentContainerDisplayName
      : null;

    if (events.length === 0 || this.props.isFromOverview) {
      data[0] = [
        "Time",
        display_name ? display_name + " : Transmit" : "Transmit",
        display_name ? display_name + " : Receive" : "Receive",
      ];
    } else {
      data[0] = [
        "Time",
        display_name ? display_name + " : Transmit" : "Transmit",
        display_name ? display_name + " : Receive" : "Receive",
        "",
        { type: "string", role: "style" },
        { type: "string", role: "tooltip", p: { html: true } },
      ];
    }
    //  LAT-10761 To avoid unwanted error in rendering
    let indx = data.findIndex(item => {
      return (
        (item && item[0] && item[1] && item[1].v >= 0) ||
        (item[2] && item.v >= 0)
      );
    });
    return {
      data: computedMaxVal > 0 && data.length > 2 && indx !== -1 ? data : [],
      ticks: ticks,
    };
  };

  changeInterface = value => {
    this.setState({ currentInterface: value });
    this.props.NodeStatsViewModel.currentInterface = value;
  };

  // Get the index of the interface with the specified name.
  getNetworkInterfaceIndex = (interfaceName, interfaces) => {
    for (var i = 0; i < interfaces.length; i++) {
      if (interfaces[i].name == interfaceName) {
        return i;
      }
    }
    return -1;
  };

  updateInterface = () => {
    let interfaces = this.state.interfaces;
    if (interfaces.length > 0) {
      interfaces.forEach(data => {
        if (data.name.startsWith("n-")) {
          let computed_networks = this.state.computed_networks;
          if (!computed_networks.includes(data.name)) {
            this.checkForNeworkName(data.name);
          }
        }
      });
    }
  };

  checkForNeworkName = name => {
    if (name.startsWith("n-")) {
      let networkIds = name.split("_");
      let fromNetwork = {};
      let toNetwork = {};
      let computed_networks = this.state.computed_networks;
      if (networkIds.length === 2 && !computed_networks.includes(name)) {
        computed_networks.push(name);
        NetworkController.getSingleNetworkById(networkIds[0]).then(res => {
          fromNetwork = res;
          NetworkController.getSingleNetworkById(networkIds[1]).then(res2 => {
            toNetwork = res2;
            this.setState({
              [name]:
                fromNetwork.name +
                " \u27F7 " +
                toNetwork.node.name +
                "/" +
                toNetwork.name,
              isInterfaceLoaded: true,
              computed_networks: computed_networks,
            });
          });
        });
      }
    } else {
      return name;
    }
  };
  replaceInterfaceName = name => {
    if (name.startsWith("n-")) {
      return this.state[name] ? this.state[name] : <LoadingOutlined />;
    } else {
      let multinic_interfaces = this.props.InodeViewModel
        .multiNicInterfacesList;
      if (multinic_interfaces && multinic_interfaces.length > 0) {
        let interface_name = null;
        multinic_interfaces.forEach(multiNicInterface => {
          if (multiNicInterface.name == name) {
            interface_name = multiNicInterface.display_name + " (" + name + ")";
          }
        });
        return interface_name ? interface_name : name;
      }
      return name;
    }
  };
  componentWillUnmount() {
    this.props.NodeStatsViewModel.resetMetricsDashboardStore();
  }
  renderInterfaces = () => {
    let options = this.state.interfaces;
    if (options && options.length > 0)
      options = options.sort((x, y) => customSort(x.name, y.name));
    let interfaceOptions = [];

    let findDefaultInterfaceIndex = options.findIndex(item => {
      return item && item.name && item.name === "eth0";
    });

    if (
      findDefaultInterfaceIndex == -1 &&
      this.props.NodeStatsViewModel &&
      this.props.NodeStatsViewModel.service_list_view &&
      this.props.NodeStatsViewModel.service_list_view.length > 0
    ) {
      options.push({ name: "eth0" });
    }

    options.forEach((item, i) => {
      interfaceOptions.push(
        <Option key={i} value={item.name}>
          {this.replaceInterfaceName(item.name)}
        </Option>,
      );
    });
    return interfaceOptions;
  };

  render() {
    let chartData =
      (this.props.match &&
        this.props.match.params &&
        this.props.match.params.containerId) ||
      this.props.isFromOverview
        ? this.drawNetworkBytes()
        : this.props.NodeStatsViewModel.computeServiceNetworkStats
            .interface_stats;
    let interfaces = this.state.interfaces;
    let stats_data = this.state.data;
    let ChartInfo = props => {
      return !this.props.NodeStatsViewModel.loading &&
        !this.props.ServiceStatsViewModel.loading ? (
        stats_data &&
        stats_data.length > 0 &&
        interfaces &&
        interfaces.length > 0 &&
        chartData &&
        chartData.data &&
        chartData.data.length > 0 ? (
          <div
            className="iot-pointer-cursor"
            onClick={() => {
              if (this.props.isFromOverview)
                this.props.redirectToAdvnancedStats();
            }}
          >
            <Chart
              chartType="LineChart"
              height={this.props.isFromOverview ? 265 : 250}
              loader={
                <div className="chart-spinner">
                  <Spin tip="Loading..." />
                </div>
              }
              data={chartData && chartData.data ? chartData.data : chartData}
              chartEvents={[
                {
                  eventName: "ready",
                  callback: ({ chartWrapper, google }) => {
                    const chart = chartWrapper.getChart();
                    google.visualization.events.addListener(
                      chart,
                      "click",
                      e => {
                        let currentSelection = e.targetID.split("#");
                        if (
                          currentSelection &&
                          currentSelection.length > 1 &&
                          currentSelection[1]
                        ) {
                          let selectedPoint = parseInt(currentSelection[1]);
                          // to avoid negative index LAT-11383
                          if (selectedPoint === -1) {
                            selectedPoint = 0;
                          }

                          let elementId = chartWrapper
                            .getDataTable()
                            .getValue(selectedPoint, 0);
                          let element = document.getElementById(elementId);
                          if (element) {
                            this.setState({ chartModalvisible: false });

                            // Finding a class with (creates an array of results)
                            let x = document.getElementsByClassName(
                              "statsfocusable-events",
                            );

                            if (x.length > 0) {
                              // Removing a class
                              x[0].classList.remove("statsfocusable-events");
                            }
                            element.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                              inline: "center",
                            });
                            element.classList.add("statsfocusable-events");
                          } else {
                            let x = document.getElementsByClassName(
                              "statsfocusable-events",
                            );
                            if (x.length > 0) {
                              // Removing a class
                              x[0].classList.remove("statsfocusable-events");
                            }
                          }
                        }
                      },
                    );
                  },
                },
              ]}
              options={{
                curveType: "function",
                series:
                  this.eventModel &&
                  this.eventModel.eventList &&
                  this.eventModel.eventList.length <= 0
                    ? {
                        0: { color: "#6f9654" },
                        1: { color: "#1c91c0" },
                      }
                    : {
                        0: { color: "#6f9654" },
                        1: { color: "#1c91c0" },
                        [chartData.data &&
                        chartData.data[0] &&
                        chartData.data[0].length - 4]: { color: "" },
                      },
                allowHtml: true,
                theme: "material",
                interpolateNulls: false,
                tooltip: {
                  isHtml: true,
                },
                legend: {
                  position: "right",
                  textStyle: { color: this.textColor },
                },
                explorer: {
                  keepInBounds: true,
                  actions: ["dragToZoom", "rightClickToReset"],
                },
                annotations: { highContrast: false },
                focusTarget: "category",
                vAxis: {
                  legend: {
                    position: "left",
                  },
                  minValue: 2,
                  viewWindow: { min: 0 },
                  ticks: chartData && chartData.ticks ? chartData.ticks : {},
                  textStyle: { color: this.textColor },
                  titleTextStyle: { color: this.textColor },
                },
                hAxis: {
                  format: "hh:mm a",
                  textStyle: { color: this.textColor },
                },
                chartArea: props.chartArea,
              }}
            />
          </div>
        ) : (
          getCurrentlocaleText("stats.nometrics.text")
        )
      ) : (
        <div className="chart-spinner">
          <Spin tip="Loading..." />
        </div>
      );
    };
    return (
      <Card
        title={
          <span>
            <span>
              <Row>
                <Col span={this.props.isFromOverview ? 20 : 8}>
                  {getCurrentlocaleText("stats.card.interface.traffic.text")}
                </Col>
                {this.props.isFromOverview && (
                  <Col span={4}>
                    {getCurrentlocaleText("netstats.selector.time.text", {
                      0: "5",
                    })}
                  </Col>
                )}
                {!this.props.isFromOverview && (
                  <Col
                    span={this.props.isFromOverview ? 8 : 12}
                    className={
                      this.props.isFromOverview && "summary-interface-select"
                    }
                  >
                    {interfaces.length > 0 && (
                      <Select
                        value={
                          this.props.NodeStatsViewModel.currentInterface
                            ? this.props.NodeStatsViewModel.currentInterface
                            : this.inodeModel.interfacesList &&
                              !this.props.match.params.containerId
                            ? this.inodeModel.interfacesList
                            : interfaces[0] && interfaces[0].name
                            ? interfaces[0].name
                            : null
                        }
                        onChange={this.changeInterface}
                        style={{
                          width: 300,
                          float: "right",
                          marginTop: 10,
                        }}
                        notFoundContent={getCurrentlocaleText(
                          "general.notfound.placeholder.text",
                        )}
                      >
                        {this.renderInterfaces()}
                      </Select>
                    )}
                  </Col>
                )}
                <Col span={4}>
                  <span
                    className="stats-maximize-icon"
                    onClick={() => {
                      this.setState({
                        chartModalvisible: true,
                      });
                    }}
                  >
                    <Icons
                      type="ai"
                      name="AiOutlineFullscreen"
                      title={"Maximize"}
                    />
                  </span>
                </Col>
              </Row>
            </span>
          </span>
        }
        bordered={false}
        hoverable={false}
      >
        {this.state.chartModalvisible && (
          <Modal
            title={
              <span>
                <Row>
                  <Col span={this.props.isFromOverview ? 20 : 8}>
                    {getCurrentlocaleText("stats.card.interface.traffic.text")}
                  </Col>
                  {this.props.isFromOverview && (
                    <Col span={4}>
                      {getCurrentlocaleText("netstats.selector.time.text", {
                        0: "5",
                      })}
                    </Col>
                  )}
                  {!this.props.isFromOverview && (
                    <Col
                      span={this.props.isFromOverview ? 8 : 14}
                      className={
                        this.props.isFromOverview && "summary-interface-select"
                      }
                    >
                      {interfaces.length > 0 && (
                        <Select
                          value={
                            this.props.NodeStatsViewModel.currentInterface
                              ? this.props.NodeStatsViewModel.currentInterface
                              : this.inodeModel.interfacesList &&
                                !this.props.match.params.containerId
                              ? this.inodeModel.interfacesList
                              : interfaces[0] && interfaces[0].name
                              ? interfaces[0].name
                              : null
                          }
                          onChange={this.changeInterface}
                          style={{
                            width: 300,
                            float: "right",
                            marginTop: 10,
                          }}
                          notFoundContent={getCurrentlocaleText(
                            "general.notfound.placeholder.text",
                          )}
                        >
                          {this.renderInterfaces()}
                        </Select>
                      )}
                    </Col>
                  )}
                </Row>
              </span>
            }
            visible={true}
            bodyStyle={{ minHeight: 250 }}
            width={1200}
            maskClosable={false}
            onCancel={() => {
              this.setState({ chartModalvisible: false });
            }}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  this.setState({
                    chartModalvisible: false,
                  });
                }}
              >
                Close
              </Button>,
            ]}
          >
            <ChartInfo
              chartArea={{
                left: "8%",
                top: "5%",
                width: "100%",
                height: "85%",
                right: "15%",
                bottom: "10%",
              }}
            />
          </Modal>
        )}
        <ChartInfo
          chartArea={{
            left: "10%",
            top: "8%",
            width: "65%",
            height: "70%",
            right: "25%",
          }}
        />
      </Card>
    );
  }
}

export default NetworkChart;
