import React, { Component } from "react";
import {
  Input,
  InputNumber,
  Switch,
  Select,
  Row,
  Col,
  Card,
  Radio,
  Button,
  Icon,
  Checkbox,
} from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import { getCurrentlocaleText, IOTIUM_USER } from "Core/Utils";
import { OrgController } from "../../controller/Controllers";
import PopOverHelp from "components/UI-Components/PopOverHelp";
import { ROOT_ORG } from "Core/Utils";
import Icons from "../UI-Components/Icons";

const FormItem = Form.Item;
let id = 0;
const Option = Select.Option;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
@inject("ModelStore", "UiStore", "AuthStore")
@observer
class PolicyForm extends Component {
  constructor(props) {
    super(props);
    this.UiStore = this.props.UiStore;
    this.state = {
      formData: {
        light_logo_url: null,
        dark_logo_url: null,
        favicon_url: null,
        login_background_url: [],
        privacy_policy_url: null,
        sidebar_mode: "dark",
        primary_color: "#365c6f",
        text_color: "#365c6f",
        font_family_name: "",
        font_source_url: null,
      },
      orgId: null,
      is2FaEnabled: false,
      is2FaForced: "OPTIONAL",
      headlessEnabled: false,
      vlanEnabled: false,
      apikeyEnabled: false,
      upgradeChannelSelected: "STABLE",
      branding: false,
      supportCapability: false,
      cloudroute_configure_capability: false,
      cliConfigRollback: false,
      childOrganization: false,
      isMe: false,
      oneArmModeEnabled: false,
      hwMonitoringEnabled: false,
      cluster: false,
      webhook: false,
      max_node_per_cluster: 0,
      max_cluster_per_organization: 0,
      max_candidate_per_cluster: 0,
      max_webhook_per_user: 0,
      max_webhook_subscription_per_user: 0,
      rules: [],
      max_allowed_login_failures: 3,
      expire_password_in_days: 15,
      password_reuse_prevention: 1,
      min_to_expire_verify_email: 1,
      min_to_expire_forgot_password: 1,
      min_to_expire_session: 10,
      max_firewall_selector: 0,
      max_network_limit: 0,
      max_network_per_node: 0,
      max_route_limit: 0,
      max_firewall_group_limit: 0,
      max_rules_per_firewallgroup: 0,
      max_labels_per_resource: 0,
      activity_max_download_per_user: 0,
      event_max_download_per_user: 0,
      custom_service: false,
      service_listener: false,
      max_service_per_node: 1,
      representationNetworkAutomation: false,
      unifiedCloudGateway: false,
    };
  }

  componentDidMount() {
    let blockEditPolicy = this.state.blockEditPolicy;
    // Block org policy edit if logged in user org himself.
    if (
      this.props.AuthStore.loggedInUser &&
      this.props.AuthStore.loggedInUser.organization &&
      this.props.AuthStore.loggedInUser.organization.id &&
      this.props.initialVal &&
      this.props.initialVal.id ===
        this.props.AuthStore.loggedInUser.organization.id
    ) {
      blockEditPolicy = true;
    }
    // Alow org policy edit only if logged in user is IOTIUM Admin plus user
    if (
      this.props.AuthStore.loggedInUser &&
      this.props.AuthStore.loggedInUser.id === IOTIUM_USER
    ) {
      blockEditPolicy = false;
    }
    this.setState({
      isView: this.props.action === "view" ? true : this.props.viewOnly,
      blockEditPolicy: blockEditPolicy,
    });
    this.refreshOrgs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orgId != this.props.orgId) {
      this.refreshOrgs();
    }
  }

  refreshOrgs = () => {
    if (this.props.orgId) {
      OrgController.getOrgById(this.props.orgId, true).then(response => {
        if (response) {
          let currentUserId = this.UiStore.currentUser
            ? this.UiStore.currentUser.id
            : null;
          this.setState({
            initialVal: response,
            isMe:
              response.is_me && currentUserId !== IOTIUM_USER
                ? response.is_me
                : false,
          });
        }
        if (response && response.policy) {
          let is2FaEnabled,
            is2FaForced,
            headlessEnabled,
            vlanEnabled,
            upgradeChannelSelected,
            apikeyEnabled,
            branding,
            supportCapability,
            cloudroute_configure_capability,
            cliConfigRollback,
            childOrganization,
            oneArmModeEnabled,
            hwMonitoringEnabled,
            cluster,
            webhook,
            unifiedCloudGateway,
            representationNetworkAutomation;
          is2FaEnabled =
            response.policy.two_factor && response.policy.two_factor.enable
              ? true
              : false;
          is2FaForced =
            response.policy.two_factor && response.policy.two_factor.force
              ? "REQUIRED"
              : "OPTIONAL";
          headlessEnabled =
            response.policy.headless_mode &&
            response.policy.headless_mode.enable
              ? true
              : false;
          vlanEnabled =
            response.policy.vlan_support && response.policy.vlan_support.enable
              ? true
              : false;
          cloudroute_configure_capability =
            response.policy.cloudroute_configure_capability &&
            response.policy.cloudroute_configure_capability.enable
              ? true
              : false;
          upgradeChannelSelected =
            response.policy &&
            response.policy.software_upgrade &&
            response.policy.software_upgrade.channel;
          apikeyEnabled =
            response.policy.apikey && response.policy.apikey.enable
              ? true
              : false;
          branding =
            response.policy.branding && response.policy.branding.enable
              ? true
              : false;
          supportCapability =
            response.policy.support_capability &&
            response.policy.support_capability.enable
              ? true
              : false;
          cliConfigRollback =
            response.policy.cli_config_rollback &&
            response.policy.cli_config_rollback.enable &&
            response.policy.cli_config_rollback.enable == true
              ? true
              : false;
          childOrganization =
            response.policy.child_organization &&
            response.policy.child_organization.enable
              ? true
              : false;
          (oneArmModeEnabled =
            response.policy.one_arm_mode && response.policy.one_arm_mode.enable
              ? true
              : false),
            (unifiedCloudGateway =
              response.policy &&
              response.policy.unified_cloud_gateway &&
              response.policy.unified_cloud_gateway.enable
                ? true
                : false),
            (hwMonitoringEnabled =
              response.policy.inode_hw_monitoring &&
              response.policy.inode_hw_monitoring.enable
                ? true
                : false),
            (cluster =
              response.policy.cluster && response.policy.cluster.enable
                ? true
                : false);
          webhook =
            response.policy.webhook && response.policy.webhook.enable
              ? true
              : false;
          representationNetworkAutomation =
            response.policy.network &&
            response.policy.network.representation_network_automation &&
            response.policy.network.representation_network_automation.enable
              ? true
              : false;
          if (
            response.policy.software_upgrade &&
            response.policy.software_upgrade.channel &&
            response.policy.software_upgrade.channel === "OFF"
          ) {
            this.setState({ softwareUpgrade: false });
            upgradeChannelSelected = null;
          } else if (
            response.policy &&
            (!response.policy.software_upgrade ||
              !response.policy.software_upgrade.channel)
          ) {
            // set default value as ON stable for ORG level software upgrade policy on next update
            this.setState({ softwareUpgrade: true });
            this.props.form.setFields({
              upgrade_policy: {
                value: true,
              },
              upgrade_policy_channel: {
                value: "STABLE",
              },
            });
          } else {
            this.setState({ softwareUpgrade: true });
          }
          this.setState(
            {
              is2FaEnabled: is2FaEnabled,
              is2FaForced: is2FaForced,
              headlessEnabled: headlessEnabled,
              vlanEnabled: vlanEnabled,
              apikeyEnabled: apikeyEnabled,
              upgradeChannelSelected: upgradeChannelSelected,
              branding: branding,
              supportCapability: supportCapability,
              cloudroute_configure_capability: cloudroute_configure_capability,
              cliConfigRollback: cliConfigRollback,
              childOrganization: childOrganization,
              oneArmModeEnabled: oneArmModeEnabled,
              hwMonitoringEnabled: hwMonitoringEnabled,
              unifiedCloudGateway: unifiedCloudGateway,
              cluster: cluster,
              webhook: webhook,
              max_node_per_cluster:
                response.policy &&
                response.policy.cluster &&
                response.policy.cluster.max_node_per_cluster
                  ? response.policy.cluster.max_node_per_cluster
                  : 0,
              max_cluster_per_organization:
                response.policy &&
                response.policy.cluster &&
                response.policy.cluster.max_cluster_per_organization
                  ? response.policy.cluster.max_cluster_per_organization
                  : 0,
              max_candidate_per_cluster:
                response.policy &&
                response.policy.cluster &&
                response.policy.cluster.max_candidate_per_cluster
                  ? response.policy.cluster.max_candidate_per_cluster
                  : 0,
              max_webhook_per_user:
                response.policy &&
                response.policy.webhook &&
                response.policy.webhook.max_webhook_per_user
                  ? response.policy.webhook.max_webhook_per_user
                  : 0,
              max_webhook_subscription_per_user:
                response.policy &&
                response.policy.webhook &&
                response.policy.webhook.max_webhook_subscription_per_user
                  ? response.policy.webhook.max_webhook_subscription_per_user
                  : 0,
              pwd_rules:
                response.policy &&
                response.policy.password &&
                response.policy.password.rules
                  ? response.policy.password.rules
                  : [],
              max_allowed_login_failures:
                response.policy &&
                response.policy.password &&
                response.policy.password.max_allowed_login_failures
                  ? response.policy.password.max_allowed_login_failures
                  : 0,
              expire_password_in_days:
                response.policy &&
                response.policy.password &&
                response.policy.password.expire_password_in_days
                  ? response.policy.password.expire_password_in_days
                  : 0,
              password_reuse_prevention:
                response.policy &&
                response.policy.password &&
                response.policy.password.password_reuse_prevention
                  ? response.policy.password.password_reuse_prevention
                  : 0,
              min_to_expire_verify_email:
                response.policy &&
                response.policy.verify_email &&
                response.policy.verify_email.minutes_to_expire
                  ? response.policy.verify_email.minutes_to_expire
                  : 0,
              min_to_expire_forgot_password:
                response.policy &&
                response.policy.forgot_password &&
                response.policy.forgot_password.minutes_to_expire
                  ? response.policy.forgot_password.minutes_to_expire
                  : 0,
              min_to_expire_session:
                response.policy &&
                response.policy.session &&
                response.policy.session.minutes_to_expire
                  ? response.policy.session.minutes_to_expire
                  : 0,
              max_firewall_selector:
                response.policy &&
                response.policy.network &&
                response.policy.network.max_firewall_selector
                  ? response.policy.network.max_firewall_selector
                  : 0,
              max_network_limit:
                response.policy &&
                response.policy.network &&
                response.policy.network.max_network_limit
                  ? response.policy.network.max_network_limit
                  : 0,
              max_network_per_node:
                response.policy &&
                response.policy.network &&
                response.policy.network.max_network_per_node
                  ? response.policy.network.max_network_per_node
                  : 0,
              max_route_limit:
                response.policy &&
                response.policy.network &&
                response.policy.network.max_route_limit
                  ? response.policy.network.max_route_limit
                  : 0,
              max_firewall_group_limit:
                response.policy &&
                response.policy.firewall &&
                response.policy.firewall.max_firewall_group_limit
                  ? response.policy.firewall.max_firewall_group_limit
                  : 0,
              max_rules_per_firewallgroup:
                response.policy &&
                response.policy.firewall &&
                response.policy.firewall.max_rules_per_firewallgroup
                  ? response.policy.firewall.max_rules_per_firewallgroup
                  : 0,
              max_labels_per_resource:
                response.policy && response.policy.max_labels_per_resource
                  ? response.policy.max_labels_per_resource
                  : 0,
              activity_max_download_per_user:
                response.policy &&
                response.policy.download &&
                response.policy.download.activity &&
                response.policy.download.activity.max_download_per_user
                  ? response.policy.download.activity.max_download_per_user
                  : 0,
              event_max_download_per_user:
                response.policy &&
                response.policy.download &&
                response.policy.download.events &&
                response.policy.download.events.max_download_per_user
                  ? response.policy.download.events.max_download_per_user
                  : 0,
              custom_service: true,
              max_service_per_node:
                response.policy &&
                response.policy.service &&
                response.policy.service.max_service_per_node
                  ? response.policy.service.max_service_per_node
                  : 1,
              service_listener:
                response.policy &&
                response.policy.service &&
                response.policy.service.listener &&
                response.policy.service.listener.enable
                  ? response.policy.service.listener.enable
                  : false,
              representationNetworkAutomation: representationNetworkAutomation,
            },
            () => {
              if (this.props.org && !this.props.org.is_central_auth) {
                for (var i = 0; i < this.state.pwd_rules.length; i++) {
                  this.addRule();
                }
              }
            },
          );
        }
      });
    } else {
      // set default value as ON stable for ORG level software upgrade policy
      this.setState({ softwareUpgrade: true });
      this.props.form.setFields({
        upgrade_policy: {
          value: true,
        },
        upgrade_policy_channel: {
          value: "STABLE",
        },
      });
    }
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
    setTimeout(() => {
      let elm = document.querySelector(".help-content");
      if (elm) {
        elm.innerHTML =
          data && data.data && data.data[0] && data.data[0].content
            ? data.data[0].content
            : "";
      }
    }, 10);
  };

  handleValChange = (value, key) => {
    this.setState({ [key]: value });

    this.props.form.setFields({
      [key]: {
        value: value,
      },
    });
  };

  onSwUpgradeChange = () => {
    this.setState({
      softwareUpgrade: !this.state.softwareUpgrade,
    });
    if (!this.state.upgradeChannelSelected) {
      this.setState({
        upgradeChannelSelected: "STABLE",
      });
    }
  };

  rulesContent() {
    let { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator("rules", { initialValue: [] });
    const rules = getFieldValue("rules");

    const formItems = rules.map((k, index) => (
      <div key={k} className="inline-rules">
        <FormItem
          required={true}
          style={{ width: "100%", paddingRight: "10px" }}
        >
          {getFieldDecorator(`[rule_data[${k}.rule]]`, {
            initialValue: this.state.pwd_rules[index]
              ? this.state.pwd_rules[index].rule
              : "",
            validateTrigger: ["onChange", "onBlur"],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Rules (Regex) is required",
              },
            ],
          })(
            <Input placeholder="Ex: (.*[0-9].*)" style={{ marginRight: 8 }} />,
          )}
        </FormItem>
        <FormItem required={true} style={{ width: "100%" }}>
          {getFieldDecorator(`[rule_data[${k}.message]]`, {
            initialValue: this.state.pwd_rules[index]
              ? this.state.pwd_rules[index].message
              : "",
            validateTrigger: ["onChange", "onBlur"],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Rules (message) is required",
              },
            ],
          })(<Input placeholder="Ex: Password must contain one number" />)}
        </FormItem>
        <div className="ant-row ant-form-item">
          <Icon
            className="dynamic-delete-button p-1"
            type="minus-circle-o"
            onClick={() => this.removeRule(index)}
          />
        </div>
      </div>
    ));

    let content = (
      <div style={{ marginBottom: "10px" }}>
        {rules.length > 0 && (
          <div className="inline-rules">
            <div>
              {getCurrentlocaleText("network.password.policy.rules.text")}
              <PopOverHelp
                isHelpOnForm={true}
                height={60}
                width={250}
                placement={"top"}
                helpData={{
                  title: getCurrentlocaleText(
                    "network.password.policy.rules.title",
                  ),
                  data: [
                    {
                      subtitle: "",
                      content: getCurrentlocaleText(
                        "network.password.policy.rules.message",
                      ),
                    },
                  ],
                }}
              />
            </div>
            <div>
              {getCurrentlocaleText("network.password.policy.rules.msg")}
              <PopOverHelp
                isHelpOnForm={true}
                height={85}
                width={250}
                placement={"top"}
                helpData={{
                  title: getCurrentlocaleText(
                    "network.password.policy.rules.msg.title",
                  ),
                  data: [
                    {
                      subtitle: "",
                      content: getCurrentlocaleText(
                        "network.password.policy.rules.msg.content",
                      ),
                    },
                  ],
                }}
              />
            </div>
          </div>
        )}
        {formItems}
        <div style={{ textAlign: "right" }}>
          <Button
            style={{ margin: "10px 0px" }}
            size="small"
            block
            type="dashed"
            onClick={this.addRule}
          >
            <Icons type="fa" name="FaPlus" />
            {getCurrentlocaleText("network.password.policy.add.rule.text")}
          </Button>
        </div>
      </div>
    );
    return content;
  }

  removeRule = index => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("rules");
    // can use data-binding to set
    form.setFieldsValue({
      rules: keys.filter((key, i) => i !== index),
    });
    let newData = [];
    if (this.state.pwd_rules != null) {
      newData = [
        ...this.state.pwd_rules.slice(0, index),
        ...this.state.pwd_rules.slice(index + 1),
      ];
    }

    this.setState({
      pwd_rules: newData,
    });
  };

  addRule = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("rules");

    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      rules: nextKeys,
    });
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    const { isView, blockEditPolicy, isMe } = this.state;
    let isCentralAuthOrg =
      this.props.org && this.props.org.is_central_auth ? true : false;
    return (
      <Form
        noValidate
        onSubmit={this.props.onSubmit}
        className="policies-logo-form"
      >
        <Row>
          <Col span={12}>
            <div>
              <Card title="General" bordered={false}>
                <div>
                  {/* Custom branding org level policy */}
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    tooltip={{
                      title: getCurrentlocaleText("branding.field.text"),
                    }}
                    label={
                      <span>
                        {getCurrentlocaleText("branding.field.text")}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={235}
                          width={300}
                          placement="right"
                          helpData={{
                            title: getCurrentlocaleText("branding.field.text"),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "branding.field.help.text1",
                                ),
                              },
                              {
                                subtitle: "",
                                content: (
                                  <p style={{ marginTop: 10 }}>
                                    {getCurrentlocaleText(
                                      "branding.field.help.text2",
                                    )}
                                  </p>
                                ),
                              },
                            ],
                          }}
                        />
                        :
                      </span>
                    }
                  >
                    {getFieldDecorator("branding", {
                      initialValue: this.state.branding,
                    })(
                      <Switch
                        checked={this.state.branding}
                        onChange={() => {
                          this.setState({
                            branding: !this.state.branding,
                          });
                        }}
                        disabled={isView || blockEditPolicy || isMe}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span title={getCurrentlocaleText("labels.text")}>
                        {getCurrentlocaleText("labels.text") + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("label_policy", {
                      initialValue: true,
                    })(
                      <Switch
                        checked={true}
                        disabled={true}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span>
                        {getCurrentlocaleText("supportCapability.field.text")}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={250}
                          width={400}
                          placement="right"
                          helpData={{
                            title: getCurrentlocaleText(
                              "supportCapability.field.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: (
                                  <div>
                                    <p style={{ marginTop: 5 }}>
                                      {getCurrentlocaleText(
                                        "supportCapability.field.help.text1",
                                      )}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      {getCurrentlocaleText(
                                        "supportCapability.field.help.text2",
                                      )}
                                    </p>
                                    <b>
                                      {getCurrentlocaleText(
                                        "supportCapability.field.help.text3",
                                      )}
                                    </b>
                                    <ul>
                                      <li>
                                        {getCurrentlocaleText(
                                          "supportCapability.field.help.text4",
                                        )}
                                      </li>
                                      <li>
                                        {getCurrentlocaleText(
                                          "supportCapability.field.help.text5",
                                        )}
                                      </li>
                                      <li>
                                        {getCurrentlocaleText(
                                          "supportCapability.field.help.text6",
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                ),
                              },
                            ],
                          }}
                        />
                        :
                      </span>
                    }
                  >
                    {getFieldDecorator("supportCapability", {
                      initialValue: this.state.supportCapability,
                    })(
                      <Switch
                        checked={this.state.supportCapability}
                        onChange={() => {
                          this.setState({
                            supportCapability: !this.state.supportCapability,
                          });
                        }}
                        disabled={isView || isMe}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span>
                        {getCurrentlocaleText("childOrganization.field.text")}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={80}
                          width={300}
                          placement="right"
                          helpData={{
                            title: getCurrentlocaleText(
                              "childOrganization.field.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "childOrganization.field.help.text",
                                ),
                              },
                            ],
                          }}
                        />
                        :
                      </span>
                    }
                  >
                    {getFieldDecorator("childOrganization", {
                      initialValue: this.state.childOrganization,
                    })(
                      <Switch
                        checked={this.state.childOrganization}
                        onChange={() => {
                          this.setState({
                            childOrganization: !this.state.childOrganization,
                          });
                        }}
                        disabled={isView || isMe}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "org.policy.labels_max_count.field.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "org.policy.labels_max_count.field.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("max_labels_per_resource", {
                      initialValue: this.state.max_labels_per_resource,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(val, "max_labels_per_resource");
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={32}
                        min={0}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span>
                        {getCurrentlocaleText("cliConfigRollback.field.text") +
                          " : "}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={85}
                          width={300}
                          placement="right"
                          helpData={{
                            title: getCurrentlocaleText(
                              "cliConfigRollback.field.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: (
                                  <div>
                                    <p style={{ marginTop: 5 }}>
                                      {getCurrentlocaleText(
                                        "cliConfigRollback.field.help.text",
                                      )}
                                    </p>
                                  </div>
                                ),
                              },
                            ],
                          }}
                        />
                      </span>
                    }
                  >
                    {getFieldDecorator("cliConfigRollback", {
                      initialValue: this.state.cliConfigRollback,
                    })(
                      <Switch
                        disabled={isView || isMe}
                        checked={this.state.cliConfigRollback}
                        onChange={() => {
                          this.setState({
                            cliConfigRollback: !this.state.cliConfigRollback,
                          });
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                </div>
              </Card>
              <Card
                title={getCurrentlocaleText("cluster.field.text")}
                bordered={false}
              >
                {/* Cluster org level policy */}
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span>
                      {getCurrentlocaleText("cluster.field.text")}
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={130}
                        width={300}
                        placement="right"
                        helpData={{
                          title: getCurrentlocaleText("cluster.field.text"),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "cluster.field.help.text1",
                              ),
                            },
                          ],
                        }}
                      />
                      :
                    </span>
                  }
                >
                  {getFieldDecorator("cluster", {
                    initialValue: this.state.cluster,
                  })(
                    <Switch
                      checked={this.state.cluster}
                      onChange={() => {
                        this.setState({
                          cluster: !this.state.cluster,
                        });
                      }}
                      disabled={isView || blockEditPolicy || isMe}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                {/** CLUSTER */}
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "org.policy.max_node_per_cluster.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "org.policy.max_node_per_cluster.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_node_per_cluster", {
                    initialValue: this.state.max_node_per_cluster,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_node_per_cluster");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={128}
                      min={1}
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "org.policy.cluster_max_count.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "org.policy.cluster_max_count.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_cluster_per_organization", {
                    initialValue: this.state.max_cluster_per_organization,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(
                          val,
                          "max_cluster_per_organization",
                        );
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={8}
                      min={1}
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "org.policy.candidate_per_cluster_max_count.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "org.policy.candidate_per_cluster_max_count.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_candidate_per_cluster", {
                    initialValue: this.state.max_candidate_per_cluster,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_candidate_per_cluster");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={8}
                      min={1}
                    />,
                  )}
                </FormItem>
              </Card>
              <Card
                title={getCurrentlocaleText("alertform.inode.text")}
                bordered={false}
              >
                {/* Headless mode org level policy */}
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span>
                      {getCurrentlocaleText(
                        "org.form.headless.mode.label.text",
                      ) + " : "}
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={220}
                        width={350}
                        placement={"rightTop"}
                        helpData={{
                          title: getCurrentlocaleText(
                            "org.form.headless.mode.label.text",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "headless.help.message",
                                {
                                  0: this.name,
                                },
                              ),
                            },
                            {
                              subtitle: "",
                              content: (
                                <p style={{ marginTop: 10 }}>
                                  {getCurrentlocaleText(
                                    "headless.help.message2",
                                  )}
                                </p>
                              ),
                            },
                          ],
                        }}
                      />
                      :
                    </span>
                  }
                >
                  {getFieldDecorator("headless_mode", {
                    initialValue: this.state.headlessEnabled,
                  })(
                    <Switch
                      checked={this.state.headlessEnabled}
                      onChange={() => {
                        this.setState({
                          headlessEnabled: !this.state.headlessEnabled,
                        });
                      }}
                      disabled={isView || blockEditPolicy || isMe}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  label={
                    <span
                      title={getCurrentlocaleText("node.software.upgrade.text")}
                    >
                      {getCurrentlocaleText("node.software.upgrade.text") +
                        " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("upgrade_policy", {
                    initialValue: this.state.softwareUpgrade,
                  })(
                    <Switch
                      checked={this.state.softwareUpgrade}
                      onChange={this.onSwUpgradeChange}
                      disabled={
                        !this.props.AuthStore.IsPermitted("SUPER:ADMIN") ||
                        isView ||
                        blockEditPolicy ||
                        this.props.orgId == ROOT_ORG ||
                        isMe
                      }
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                {this.state.softwareUpgrade && (
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "org.form.upgrade.channel.label.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "org.form.upgrade.channel.label.text",
                        )}
                      </span>
                    }
                    colon={true}
                  >
                    {getFieldDecorator("upgrade_policy_channel", {
                      initialValue: this.state.upgradeChannelSelected,
                    })(
                      <Select
                        onChange={this.upgradeChannelOnSelect}
                        disabled={
                          !this.props.AuthStore.IsPermitted("SUPER:ADMIN") ||
                          isView ||
                          blockEditPolicy ||
                          !this.state.softwareUpgrade ||
                          isMe
                        }
                      >
                        <Option value={"ALPHA"}>
                          {getCurrentlocaleText(
                            "org.form.upgrade.channel.alpha.label.text",
                          )}
                        </Option>
                        <Option value={"BETA"}>
                          {getCurrentlocaleText(
                            "org.form.upgrade.channel.beta.label.text",
                          )}
                        </Option>
                        <Option value={"STABLE"}>
                          {getCurrentlocaleText(
                            "org.form.upgrade.channel.stable.label.text",
                          )}
                        </Option>
                      </Select>,
                    )}
                  </FormItem>
                )}
                {/* One-arm mode org level policy */}
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span>
                      {getCurrentlocaleText("onearmmode.field.text")}
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={240}
                        width={300}
                        placement="right"
                        helpData={{
                          title: getCurrentlocaleText("onearmmode.field.text"),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "onearmmode.field.help.text1",
                              ),
                            },
                            {
                              subtitle: "",
                              content: (
                                <p style={{ marginTop: 10 }}>
                                  {getCurrentlocaleText(
                                    "onearmmode.field.help.text2",
                                  )}
                                </p>
                              ),
                            },
                          ],
                        }}
                      />
                      :
                    </span>
                  }
                >
                  {getFieldDecorator("one_arm_mode", {
                    initialValue: this.state.oneArmModeEnabled,
                  })(
                    <Switch
                      checked={this.state.oneArmModeEnabled}
                      onChange={() => {
                        this.setState({
                          oneArmModeEnabled: !this.state.oneArmModeEnabled,
                        });
                      }}
                      disabled={isView || blockEditPolicy || isMe}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText("unified.cloud.gateway.text")}
                    >
                      {getCurrentlocaleText("unified.cloud.gateway.text")}
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={130}
                        width={300}
                        placement="right"
                        helpData={{
                          title: getCurrentlocaleText(
                            "unified.cloud.gateway.text",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "unified.cloud.gateway.help.text",
                              ),
                            },
                          ],
                        }}
                      />
                    </span>
                  }
                >
                  {getFieldDecorator("unified_cloud_gateway", {
                    initialValue: this.state.unifiedCloudGateway,
                  })(
                    <Switch
                      checked={this.state.unifiedCloudGateway}
                      onChange={() => {
                        this.setState({
                          unifiedCloudGateway: !this.state.unifiedCloudGateway,
                        });
                      }}
                      disabled={isView || blockEditPolicy || isMe}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                {/**Representation network automation org level enable or disable */}
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "representation.network.automation.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "representation.network.automation.field.text",
                      )}
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={110}
                        width={280}
                        placement="right"
                        helpData={{
                          title: getCurrentlocaleText(
                            "representation.network.automation.field.text",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "representation.network.automation.field.help.text",
                              ),
                            },
                          ],
                        }}
                      />
                    </span>
                  }
                >
                  {getFieldDecorator("representationNetworkAutomation", {
                    initialValue: this.state.representationNetworkAutomation,
                  })(
                    <Switch
                      checked={this.state.representationNetworkAutomation}
                      onChange={() => {
                        this.setState({
                          representationNetworkAutomation: !this.state
                            .representationNetworkAutomation,
                        });
                      }}
                      disabled={isView || blockEditPolicy || isMe}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                {this.state.representationNetworkAutomation && (
                  <FormItem
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    colon={false}
                  >
                    {getFieldDecorator("cloudroute_configure_capability", {
                      initialValue:
                        this.state.cloudroute_configure_capability ||
                        this.props.orgId == ROOT_ORG,
                    })(
                      <Checkbox
                        checked={
                          this.state.cloudroute_configure_capability ||
                          this.props.orgId == ROOT_ORG
                        }
                        onChange={() => {
                          this.setState({
                            cloudroute_configure_capability: !this.state
                              .cloudroute_configure_capability,
                          });
                        }}
                        disabled={
                          isView || isMe || this.props.orgId == ROOT_ORG
                        }
                      >
                        <span>
                          {getCurrentlocaleText(
                            "configureRepresentation.field.text",
                          )}
                          <PopOverHelp
                            isHelpOnForm={true}
                            height={140}
                            width={400}
                            placement="right"
                            helpData={{
                              title: getCurrentlocaleText(
                                "configureRepresentation.field.text",
                              ),
                              data: [
                                {
                                  subtitle: "",
                                  content: (
                                    <div>
                                      <p style={{ marginTop: 5 }}>
                                        {getCurrentlocaleText(
                                          "configureRepresentation.field.help.text",
                                        )}
                                      </p>
                                    </div>
                                  ),
                                },
                              ],
                            }}
                          />
                        </span>
                      </Checkbox>,
                    )}
                  </FormItem>
                )}
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText("node.hw.monitoring.text")}
                    >
                      {getCurrentlocaleText("node.hw.monitoring.text")}&nbsp;:
                    </span>
                  }
                >
                  {getFieldDecorator("inode_hw_monitoring", {
                    initialValue: this.state.hwMonitoringEnabled,
                  })(
                    <Switch
                      checked={this.state.hwMonitoringEnabled}
                      onChange={() => {
                        this.setState({
                          hwMonitoringEnabled: !this.state.hwMonitoringEnabled,
                        });
                      }}
                      disabled={isView || blockEditPolicy || isMe}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
              </Card>
              <Card
                title={getCurrentlocaleText(
                  "node.list.table.node_services.label.text",
                )}
                bordered={false}
              >
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span>
                      {getCurrentlocaleText("services.listener.label") + " : "}
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={110}
                        width={250}
                        placement="right"
                        helpData={{
                          title: getCurrentlocaleText(
                            "services.listener.label",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "services.listener.help_text",
                              ),
                            },
                          ],
                        }}
                      />
                    </span>
                  }
                >
                  {getFieldDecorator("service_listener", {
                    initialValue: this.state.service_listener,
                  })(
                    <Switch
                      checked={this.state.service_listener}
                      onChange={() => {
                        this.setState({
                          service_listener: !this.state.service_listener,
                        });
                      }}
                      disabled={isView || isMe}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText("max_service_node.label")}
                    >
                      {getCurrentlocaleText("max_service_node.label") + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_service_per_node", {
                    initialValue: this.state.max_service_per_node,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_service_per_node");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={256}
                      min={1}
                    />,
                  )}
                </FormItem>
              </Card>
              <Card
                title={getCurrentlocaleText(
                  "user.api_keys.download.button.text",
                )}
                bordered={false}
              >
                <FormItem
                  {...{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 6 },
                  }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "user.api_keys.download.button.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "user.api_keys.download.button.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("download_policy", {
                    initialValue: true,
                  })(
                    <Switch
                      checked={true}
                      disabled={true}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                <FormItem
                  {...{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 6 },
                  }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "org.policy.download_activity_per_user.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "org.policy.download_activity_per_user.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("activity_max_download_per_user", {
                    initialValue: this.state.activity_max_download_per_user,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(
                          val,
                          "activity_max_download_per_user",
                        );
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={50}
                      min={0}
                    />,
                  )}
                </FormItem>
                <FormItem
                  {...{
                    labelCol: { span: 14 },
                    wrapperCol: { span: 6 },
                  }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "org.policy.download_event_per_user.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "org.policy.download_event_per_user.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("event_max_download_per_user", {
                    initialValue: this.state.event_max_download_per_user,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(
                          val,
                          "event_max_download_per_user",
                        );
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={50}
                      min={0}
                    />,
                  )}
                </FormItem>
              </Card>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ marginLeft: 10 }}>
              {/* Network count allowed */}
              <Card
                title={getCurrentlocaleText("general.component.networks.text")}
                bordered={false}
              >
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "general.component.networks.text",
                      )}
                    >
                      {getCurrentlocaleText("general.component.networks.text") +
                        " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("network_policy", {
                    initialValue: true,
                  })(
                    <Switch
                      checked={true}
                      disabled={true}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "network.policy.max_count.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "network.policy.max_count.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_network_limit", {
                    initialValue: this.state.max_network_limit,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_network_limit");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={10240}
                      min={1}
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "network.policy.max_network_per_node.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "network.policy.max_network_per_node.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_network_per_node", {
                    initialValue: this.state.max_network_per_node,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_network_per_node");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={512}
                      min={1}
                    />,
                  )}
                </FormItem>
                <FormItem
                  {...{
                    labelCol: { span: 16 },
                    wrapperCol: { span: 8 },
                  }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "network.policy.csp_max_count.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "network.policy.csp_max_count.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_firewall_selector", {
                    initialValue: this.state.max_firewall_selector,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_firewall_selector");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={512}
                      min={0}
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "network.policy.route_max_count.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "network.policy.route_max_count.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_route_limit", {
                    initialValue: this.state.max_route_limit,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_route_limit");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={128}
                      min={0}
                    />,
                  )}
                </FormItem>
                <div>
                  {/* Vlan support org level policy */}
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span>
                        {getCurrentlocaleText(
                          "org.form.vlan.support.label.text",
                        )}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={220}
                          width={330}
                          placement={"rightTop"}
                          helpData={{
                            title: getCurrentlocaleText(
                              "org.form.vlan.support.label.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "vlan.help.message1",
                                ),
                              },
                              {
                                subtitle: "",
                                content: (
                                  <p style={{ marginTop: 10 }}>
                                    {getCurrentlocaleText("vlan.help.message2")}
                                  </p>
                                ),
                              },
                            ],
                          }}
                        />
                        :
                      </span>
                    }
                  >
                    {getFieldDecorator("vlan_support", {
                      initialValue: this.state.vlanEnabled,
                    })(
                      <Switch
                        checked={this.state.vlanEnabled}
                        onChange={() => {
                          this.setState({
                            vlanEnabled: !this.state.vlanEnabled,
                          });
                        }}
                        disabled={isView || blockEditPolicy || isMe}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                </div>
              </Card>
              <Card
                title={getCurrentlocaleText("general.component.firewall.text")}
                bordered={false}
              >
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "general.component.firewall.text",
                      )}
                    >
                      {getCurrentlocaleText("general.component.firewall.text") +
                        " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("csp_policy", {
                    initialValue: true,
                  })(
                    <Switch
                      checked={true}
                      disabled={true}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "network.policy.org_csp_max_count.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "network.policy.org_csp_max_count.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_firewall_group_limit", {
                    initialValue: this.state.max_firewall_group_limit,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(val, "max_firewall_group_limit");
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={512}
                      min={0}
                    />,
                  )}
                </FormItem>
                <FormItem
                  labelCol={{ span: 16 }}
                  wrapperCol={{ span: 8 }}
                  colon={false}
                  label={
                    <span
                      title={getCurrentlocaleText(
                        "network.policy.rules_max_count.field.text",
                      )}
                    >
                      {getCurrentlocaleText(
                        "network.policy.rules_max_count.field.text",
                      ) + " : "}
                    </span>
                  }
                >
                  {getFieldDecorator("max_rules_per_firewallgroup", {
                    initialValue: this.state.max_rules_per_firewallgroup,
                  })(
                    <InputNumber
                      onChange={val => {
                        this.handleValChange(
                          val,
                          "max_rules_per_firewallgroup",
                        );
                      }}
                      disabled={this.props.viewOnly || isMe}
                      max={64}
                      min={1}
                    />,
                  )}
                </FormItem>
              </Card>
              <Card
                title={getCurrentlocaleText("users.navigation_menu.text")}
                bordered={false}
              >
                {/* Two Factor authentication (2FA) org level policy */}
                <div>
                  <FormItem
                    colon={false}
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    label={
                      <span>
                        {getCurrentlocaleText("profile.user.2fa.title")}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={310}
                          width={350}
                          placement={"rightTop"}
                          helpData={{
                            title: getCurrentlocaleText(
                              "profile.user.2fa.title",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "general.2fa.help.message.text",
                                ),
                              },
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "general.2fa.help.message2.text",
                                ),
                              },
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "general.2fa.help.message3.text",
                                ),
                              },
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "general.2fa.help.message4.text",
                                ),
                              },
                            ],
                          }}
                        />
                        :
                      </span>
                    }
                  >
                    {getFieldDecorator("two_factor_policy", {
                      initialValue: this.state.is2FaEnabled,
                    })(
                      <div>
                        <Switch
                          checked={this.state.is2FaEnabled}
                          onChange={() => {
                            let value = !this.state.is2FaEnabled;
                            this.setState({
                              is2FaEnabled: value,
                            });
                            this.props.updateTwoFactor(value);
                          }}
                          disabled={isView || blockEditPolicy || isMe}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          onFocus={() => {
                            this.updateHelp({
                              title: getCurrentlocaleText(
                                "profile.user.2fa.title",
                              ),
                              data: [
                                {
                                  subtitle: "",
                                  content: getCurrentlocaleText(
                                    "general.2fa.help.message.text",
                                  ),
                                },
                                {
                                  subtitle: "",
                                  content: (
                                    <p style={{ marginTop: 10 }}>
                                      {getCurrentlocaleText(
                                        "general.2fa.help.message2.text",
                                      )}
                                    </p>
                                  ),
                                },
                              ],
                            });
                          }}
                        />
                        {/*  Two Factor authentication (2FA) org level policy Required or optional */}
                        {this.state.is2FaEnabled && (
                          <RadioGroup
                            style={{ marginLeft: 8 }}
                            size="small"
                            value={this.state.is2FaForced}
                            onChange={valueText => {
                              this.setState({
                                is2FaForced: valueText.target.value,
                              });
                              this.props.updateTwoFactorForce(
                                this.state.is2FaForced === "REQUIRED"
                                  ? false
                                  : true,
                              );
                            }}
                            disabled={isView || blockEditPolicy || isMe}
                          >
                            <RadioButton
                              value={"OPTIONAL"}
                              disabled={isView || blockEditPolicy || isMe}
                            >
                              {"Optional"}
                            </RadioButton>
                            <RadioButton
                              disabled={isView || blockEditPolicy || isMe}
                              value={"REQUIRED"}
                            >
                              {"Required"}
                            </RadioButton>
                          </RadioGroup>
                        )}
                      </div>,
                    )}
                  </FormItem>
                  {/* Apikey org level policy */}
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span>
                        {getCurrentlocaleText("org.form.api.access.label.text")}
                        <PopOverHelp
                          isHelpOnForm={true}
                          height={220}
                          width={350}
                          placement={"rightTop"}
                          helpData={{
                            title: getCurrentlocaleText(
                              "org.form.api.access.label.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "apiaccess.help.message1",
                                ),
                              },
                              {
                                subtitle: "",
                                content: (
                                  <p style={{ marginTop: 10 }}>
                                    {getCurrentlocaleText(
                                      "apiaccess.help.message2",
                                    )}
                                  </p>
                                ),
                              },
                            ],
                          }}
                        />
                        :
                      </span>
                    }
                  >
                    {getFieldDecorator("apikey", {
                      initialValue: this.state.apikeyEnabled,
                    })(
                      <Switch
                        checked={this.state.apikeyEnabled}
                        onChange={() => {
                          this.setState({
                            apikeyEnabled: !this.state.apikeyEnabled,
                          });
                        }}
                        disabled={isView || blockEditPolicy || isMe}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "general.component.webhook.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "general.component.webhook.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("webhook", {
                      initialValue: this.state.webhook,
                    })(
                      <Switch
                        checked={this.state.webhook}
                        onChange={() => {
                          this.setState({
                            webhook: !this.state.webhook,
                          });
                        }}
                        disabled={isView || blockEditPolicy || isMe}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.policy.webhook_max_count.field.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.policy.webhook_max_count.field.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("max_webhook_per_user", {
                      initialValue: this.state.max_webhook_per_user,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(val, "max_webhook_per_user");
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={20}
                        min={1}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.policy.webhook_subscription_max_count.field.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.policy.webhook_subscription_max_count.field.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("max_webhook_subscription_per_user", {
                      initialValue: this.state
                        .max_webhook_subscription_per_user,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(
                            val,
                            "max_webhook_subscription_per_user",
                          );
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={20}
                        min={1}
                      />,
                    )}
                  </FormItem>
                </div>
              </Card>
              {!isCentralAuthOrg && (
                <Card
                  title={getCurrentlocaleText("network.password.policy.text")}
                  bordered={false}
                >
                  {this.rulesContent()}

                  <FormItem
                    {...{
                      labelCol: { span: 14 },
                      wrapperCol: { span: 6 },
                    }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.password.policy.login.failure.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.password.policy.login.failure.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("max_allowed_login_failures", {
                      initialValue: this.state.max_allowed_login_failures,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(
                            val,
                            "max_allowed_login_failures",
                          );
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={128}
                        min={3}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    {...{
                      labelCol: { span: 14 },
                      wrapperCol: { span: 6 },
                    }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.password.policy.expire.pwd.days.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.password.policy.expire.pwd.days.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("expire_password_in_days", {
                      initialValue: this.state.expire_password_in_days,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(val, "expire_password_in_days");
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={365}
                        min={15}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    {...{
                      labelCol: { span: 14 },
                      wrapperCol: { span: 6 },
                    }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.password.policy.pwd.reuse.prev.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.password.policy.pwd.reuse.prev.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("password_reuse_prevention", {
                      initialValue: this.state.password_reuse_prevention,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(
                            val,
                            "password_reuse_prevention",
                          );
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={365}
                        min={1}
                      />,
                    )}
                  </FormItem>
                </Card>
              )}
              {!isCentralAuthOrg && (
                <Card
                  title={getCurrentlocaleText("network.session.policy.text")}
                  bordered={false}
                >
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.session.policy.verify.email.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.session.policy.verify.email.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("min_to_expire_verify_email", {
                      initialValue: this.state.min_to_expire_verify_email,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(
                            val,
                            "min_to_expire_verify_email",
                          );
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={64800}
                        min={1}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.session.policy.forgot.email.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.session.policy.forgot.email.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("min_to_expire_forgot_password", {
                      initialValue: this.state.min_to_expire_forgot_password,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(
                            val,
                            "min_to_expire_forgot_password",
                          );
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={64800}
                        min={1}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText(
                          "network.session.policy.session.email.text",
                        )}
                      >
                        {getCurrentlocaleText(
                          "network.session.policy.session.email.text",
                        ) + " : "}
                      </span>
                    }
                  >
                    {getFieldDecorator("min_to_expire_session", {
                      initialValue: this.state.min_to_expire_session,
                    })(
                      <InputNumber
                        onChange={val => {
                          this.handleValChange(val, "min_to_expire_session");
                        }}
                        disabled={this.props.viewOnly || isMe}
                        max={480}
                        min={10}
                      />,
                    )}
                  </FormItem>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const PoliciesForm = Form.create()(PolicyForm);
export default PoliciesForm;
