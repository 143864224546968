import React, { Component } from "react";
import { Row, Col, Card, Skeleton } from "antd";
import { inject, observer } from "mobx-react";
const moment = require("moment");

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import InodeController from "../../controller/InodeController";
import OrgController from "../../controller/OrgController";

@inject("InodeViewModel", "OrgViewModel")
@observer
export class CTDSourceDest extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.orgId = this.props.orgId;
    this.nodeId = this.props.nodeId;
    this.sourceColor = "#d11f25";
    this.destnColor = "#ed7829";
    this.map = {};
    mapboxgl.accessToken =
      this.EnvConfig && this.EnvConfig.MAPBOX_TOKEN
        ? this.EnvConfig.MAPBOX_TOKEN
        : "pk.eyJ1Ijoia2FubmFuMTIzNDUiLCJhIjoiY2p0c3hqNXVkMTV0aTN6bnIzZHA3eGlheSJ9.dr7PUwb77tqQT3eOHwfJ5Q";
    this.state = {
      sourceLatLong: [],
      destnLatLong: [],
      wholeResult: {},
    };
    this.mapContainer = React.createRef();
    this.refreshData = "";
  }

  componentDidMount() {
    clearInterval(this.refreshData);
    this.getData();
    this.refreshData = setInterval(() => {
      this.getData();
    }, 5 * 60 * 1000);

    // DOC: https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/light-v11",
      // zoom: 0.3,
      // center: [0, 0],
      projection: "mercator",
      fitBoundsOptions: { padding: 100 },
    }).addControl(new mapboxgl.NavigationControl());

    // disable map rotation using right click + drag
    this.map.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();
  }

  componentWillUnmount() {
    clearInterval(this.refreshData);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate &&
      this.props.endDate &&
      (this.props.startDate !== prevProps.startDate ||
        this.props.endDate !== prevProps.endDate)
    ) {
      this.getData();
    }
  }

  getData = () => {
    let params = {};
    if (this.props.startDate && this.props.endDate) {
      params = {
        start_date: this.props.startDate.toISOString(),
        end_date: this.props.endDate.toISOString(),
        size: 5,
      };
    }

    this.props.requestFrom == "inodeModel"
      ? this.getNodeThreatsByCountry(params)
      : this.getOrgThreatsByCountry(params);
  };

  getNodeThreatsByCountry = params => {
    this.inodeModel.threatsByCountryLoading = true;
    InodeController.getThreatsByCountry(this.nodeId, params)
      .then(resp => {
        this.getmapdata(resp);
        this.inodeModel.setThreatsByCountry(resp);
        this.inodeModel.threatsByCountryLoading = false;
      })
      .catch(err => {
        this.inodeModel.threatsByCountryLoading = false;
      });
  };

  getOrgThreatsByCountry = params => {
    this.orgModel.threatsByCountryLoading = true;
    OrgController.getThreatsByCountry(this.orgId, params)
      .then(resp => {
        this.getmapdata(resp);
        this.orgModel.setThreatsByCountry(resp);
        this.orgModel.threatsByCountryLoading = false;
      })
      .catch(err => {
        this.orgModel.threatsByCountryLoading = false;
      });
  };

  getmapdata(wholeResult) {
    // Remove the layer and point if it exists
    if (this.map.getLayer("points-layer")) {
      this.map.removeLayer("points-layer");
    }
    if (this.map.getSource("points")) {
      this.map.removeSource("points");
    }

    let filterSrcLatLong =
      wholeResult.source_country &&
      wholeResult.source_country.results.filter(
        obj => obj.longitude !== 0 && obj.latitude !== 0,
      );
    let sourceLatLong = filterSrcLatLong.map(obj => [
      obj.longitude,
      obj.latitude,
    ]);
    let sourceFeatureLatLong = filterSrcLatLong.map(obj => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [obj.longitude + 0.00003, obj.latitude], // Source Lat adjusted to avoid overlapping
      },
      properties: {
        color: this.sourceColor,
        count: obj.count,
      },
    }));

    let filterDstnLatLong =
      wholeResult.dest_country &&
      wholeResult.dest_country.results.filter(
        obj => obj.longitude !== 0 && obj.latitude !== 0,
      );
    let destnLatLong = filterDstnLatLong.map(obj => [
      obj.longitude,
      obj.latitude,
    ]);
    let destnFeatureLatLong = filterDstnLatLong.map(obj => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [obj.longitude, obj.latitude],
      },
      properties: {
        color: this.destnColor,
        count: obj.count,
      },
    }));

    this.setState({ sourceLatLong, destnLatLong, wholeResult });

    let fitBound = this.calcBoundsFromCoordinates([
      ...sourceLatLong,
      ...destnLatLong,
    ]);

    this.map.fitBounds(fitBound, {
      padding: 50,
    });
    this.map.addSource("points", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [...sourceFeatureLatLong, ...destnFeatureLatLong],
      },
    });
    this.map.addLayer({
      id: "points-layer",
      type: "circle",
      source: "points",
      paint: {
        "circle-radius": [
          "case",
          ["<=", ["get", "count"], 5],
          5, // If value <= 5, set radius to 5 pixels
          ["<=", ["get", "count"], 6],
          7, // If value <= 10, set radius to 10 pixels
          ["<=", ["get", "count"], 10],
          10, // If value <= 10, set radius to 10 pixels
          15, // For all other cases (value > 10), set radius to 15 pixels
        ],

        "circle-color": ["get", "color"],
        // "circle-opacity": 0.5,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#ededed",
      },
    });
  }

  getSWCoordinates(coordinatesCollection) {
    const lowestLng = Math.min(
      ...coordinatesCollection.map(coordinates => coordinates[0]),
    );
    const lowestLat = Math.min(
      ...coordinatesCollection.map(coordinates => coordinates[1]),
    );
    return [lowestLng, lowestLat];
  }

  getNECoordinates(coordinatesCollection) {
    const highestLng = Math.max(
      ...coordinatesCollection.map(coordinates => coordinates[0]),
    );
    const highestLat = Math.max(
      ...coordinatesCollection.map(coordinates => coordinates[1]),
    );
    return [highestLng, highestLat];
  }

  calcBoundsFromCoordinates(coordinatesCollection) {
    return [
      this.getSWCoordinates(coordinatesCollection),
      this.getNECoordinates(coordinatesCollection),
    ];
  }

  render() {
    const { wholeResult } = this.state;
    let model = this.nodeId ? "inodeModel" : "orgModel";

    let sourceListContent =
      wholeResult &&
      wholeResult.source_country &&
      wholeResult.source_country.results &&
      wholeResult.source_country.results.map((obj, index) => (
        <div key={index} className="justify-space-between pb-5">
          <div>
            {obj.city && obj.city != "-" && (
              <>
                {obj.city}
                <span>, </span>
              </>
            )}
            {obj.country_code}
          </div>
          <div>{obj.count}</div>
        </div>
      ));
    let destnListContent =
      wholeResult &&
      wholeResult.dest_country &&
      wholeResult.dest_country.results &&
      wholeResult.dest_country.results.map((obj, index) => (
        <div key={index} className="justify-space-between pb-5">
          <div>
            {obj.city && obj.city != "-" && (
              <>
                {obj.city}
                <span>, </span>
              </>
            )}
            {obj.country_code}
          </div>
          <div>{obj.count}</div>
        </div>
      ));

    return (
      <Card
        style={{ flex: 1 }}
        cover={
          <div
            style={{
              paddingBottom: "15px",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            <div className="map-container-wrapper">
              <div ref={this.mapContainer} className="map-container" />
            </div>
          </div>
        }
      >
        {this[model].threatsByCountryLoading && (
          <Row>
            <Col span={10}>
              <Skeleton active block size="small" className="py-15" />
            </Col>
            <Col span={12} offset={2}>
              <Skeleton active size="small" className="py-15" />
            </Col>
          </Row>
        )}
        {!this[model].threatsByCountryLoading &&
          sourceListContent &&
          destnListContent &&
          sourceListContent.length == 0 &&
          destnListContent.length == 0 && (
            <div className="all-center p-15">No Threats found</div>
          )}
        {!this[model].threatsByCountryLoading &&
          sourceListContent &&
          destnListContent &&
          (sourceListContent.length > 0 || destnListContent.length > 0) && (
            <Row>
              <Col span={10}>
                <div
                  className="justify-space-between"
                  style={{ fontWeight: 500 }}
                >
                  <div>
                    <div
                      className="small-circle"
                      style={{ backgroundColor: this.sourceColor }}
                    ></div>
                    Source Region
                  </div>
                  <div>Counts</div>
                </div>
                {sourceListContent}
              </Col>
              <Col push={2} span={12}>
                <div
                  className="justify-space-between"
                  style={{ fontWeight: 500 }}
                >
                  <div>
                    <div
                      className="small-circle"
                      style={{ backgroundColor: this.destnColor }}
                    ></div>
                    Destination Region
                  </div>
                  <div>Counts</div>
                </div>
                {destnListContent}
              </Col>
            </Row>
          )}
      </Card>
    );
  }
}
