import React, { Component } from "react";
import { message } from "antd";
import {
  Row,
  Col,
  Select,
  Button,
  Modal,
  Radio,
  Collapse,
  Switch,
  Spin,
} from "antd";
import { Form } from "@ant-design/compatible";
import { observer, inject } from "mobx-react";
import NetworkContainer from "components/Containers/NetworkContainer";
import NetworkMulticonnect from "./Components/Network/NetworkMulticonnect";
import NetworkHairpinConfirmDialog from "./Components/Network/NetworkHairpinConfirmDialog";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import Input from "components/UI-Components/InputBox";
import {
  isCidr,
  focusFirstInput,
  getCIDRdata,
  isRestrictedCIDR,
  isCidrRange,
  isTanCidrRange,
  getNodeVersion,
  getCurrentlocaleText,
  copyObject,
  getOrgPolicyCounInfo,
  markCollapseHeader,
  MultiTANBaseVersion,
  checkforFeatureEnabled,
  StaticRoutesBaseVersion,
  VirtualRoutesBaseVersion,
  dynamicNetworkModeBaseVersion,
  isMasteriNode,
  isIPRangeOverlaps,
  InterTanRouteBaseVersion,
} from "Core/Utils";
import LabelProvider from "components/UI-Components/LabelProvider";
import CidrProvider from "components/UI-Components/CidrProvider";
import RouteInfo from "components/INodes/RouteInfo";

/*------------------N/W CIDR ----------------------*/
import * as ip from "ip";
import OrgController from "controller/OrgController";
import ClusterViewModel from "view-model/ClusterViewModel";
import FirewallGroupController from "controller/FirewallGroupController";
import Icons from "components/UI-Components/Icons";
import WanFirewall from "components/Containers/WanFirewall";
import RouteConfig from "components/Forms/Components/Network/RouteConfig";
import DefaultRoute from "components/Forms/Components/Network/DefaultRoute";
import HelpCard from "components/UI-Components/HelpCard";
import ServiceController from "controller/ServiceController";
import PopOverHelp from "../UI-Components/PopOverHelp";

const FormItem = Form.Item;
const Option = Select.Option;
const baseVersion = 1059.0;
const formItemLayout = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 },
};
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;

@inject(
  "UiStore",
  "AuthStore",
  "InodeViewModel",
  "NetworkViewModel",
  "ProfileViewModel",
  "OrgViewModel",
  "FirewallGroupViewModel",
  "ServiceViewModel",
)
@observer
class NetworkFormPage extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.serviceViewModel = this.props.ServiceViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.profileModel = this.props.ProfileViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.FirewallGroupViewModel = this.props.FirewallGroupViewModel;
    this.state = {
      selectedVnode: null,
      cidrError: null,
      isVirtualNode: this.props.isVirtualNode,
      showRepNetwork: true,
      isvalidrepCidr: false,
      cidrRangeInfo: null,
      tanCidrRangeInfo: null,
      repCidrRangeInfo: null,
      formData: {},
      tanCIDR: null,
      tanInterfaceCIDR: null,
      gatewayIP: null,
      RemoteNetworkloading: true,
      tags: [],
      metadata: {},
      firewallLables: {},
      firewallLabel_options: [],
      isDisabled: false,
      selectors: [],
      currentNetwork: {},
      showHairpinConfirm: false,
      currentHairpinState: "hairpin-deny",
      NextHairpinState: "hairpin-allow",
      enableSecurityPolicyDebugger: true,
      NetworkLabels: [],
      invalidSelector: false,
      invalidLabels: [],
      isUnreachableNode: false,
      selectedLabels: [],
      allowFirewall: true,
      initialServiceAddressVal: "AUTO",
      firewall_validate_state: "",
      validateMessage: "",
      showCirdUpdateWarning: false,
      isNetworkStateChanged: false,
      loading_services: true,
      isVlanEnabled: false,
      vlanVal: "disabled",
      vlanId: null,
      orgInitialVal: {},
      network_type: "STATIC",
      enableInterTan: false,
      enableVrrpTan: false,
      hideVrrpselect: true,
      itrLinkAddress: "",
      isStaticType: true,
      isCidrExist: false,
      isTanCidrExist: false,
      isOneArmEnabled: false,
      tanCidrChanged: false,
      isReservedEndTempChange: false,
      isReservedStartTempChange: false,
      reservedRangeErrorCodes: null,
      preferredIPAddressExists: false,
      availableInodes: [],
    };

    this.service_Ip_Addressing = [
      { value: "MANUAL", name: "Static" },
      { value: "AUTO", name: "Dynamic" },
    ];

    this.vlan_Options = [
      { value: "disabled", name: "Disabled" },
      { value: "enabled", name: "Enabled" },
      // Disabled for now, may be used later
      // { value: "tagged", name: "Tagged"},
      // { value: "untagged", name: "Untagged"},
    ];
  }

  componentWillUnmount() {
    if (
      this.props.formData &&
      this.props.formData.connected_networks &&
      this.props.formData.connected_networks[0] &&
      this.props.action != "create"
    ) {
      this.updateHelp({
        title: getCurrentlocaleText("network.routeinfo.help.title"),
        data: [
          {
            subtitle: "",
            content: (
              <RouteInfo
                tanCIDR={
                  this.props.formData.connected_networks[0]
                    .representation_network
                    ? this.props.formData.connected_networks[0]
                        .representation_network.value
                    : this.props.formData
                    ? this.props.formData.config.network.cidr
                    : null
                }
                ViNodeId={
                  this.props.formData
                    ? this.props.formData.connected_networks[0] &&
                      this.props.formData.connected_networks[0].node
                      ? this.props.formData.connected_networks[0].node.id
                      : this.props.formData.connected_networks &&
                        this.props.formData.connected_networks[0] &&
                        this.props.formData.connected_networks[0].cluster &&
                        this.props.formData.connected_networks[0].cluster.id
                    : null
                }
              />
            ),
          },
        ],
      });
    }
    this.props.UiStore.showRepNetwork = false;
    this.props.UiStore.firewall_selector = null;
    this.props.UiStore.defaultRoutes = [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showInterfaces !== this.props.showInterfaces) {
      if (this.props.formData) {
        this.props.form.setFields({
          attached_interfaces: {
            value:
              this.props.formData.attached_to &&
              this.props.formData.attached_to.interfaces
                ? this.props.formData.attached_to.interfaces[0]
                : null,
          },
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (
      this.props.formData &&
      newprops.formData &&
      JSON.stringify(newprops.formData) !== JSON.stringify(this.props.formData)
    ) {
      this.setState({
        allowFirewall: newprops.cluster_id
          ? true
          : NetworkContainer.checkforiNodeVersion(this.inodeModel.inode),
      });

      // Update help content
      setTimeout(() => {
        let elm = document.querySelector(
          ".tan_interface_ip .help-container .help-content",
        );
        if (elm) {
          elm.innerHTML = this.state.isStaticType
            ? getCurrentlocaleText(
                "network.form.interface.cidr.static.help.content",
              )
            : getCurrentlocaleText("network.form.interface.cidr.help.content", {
                0: newprops.cluster_id ? "master iNode" : "iNode",
              });
        }
      }, 50);

      //Check for service with static preferred IP
      if (
        this.state.preferredIPAddressExists === false &&
        newprops.formData &&
        newprops.formData.id
      ) {
        let services = this.serviceViewModel.services;
        if (!services || services.length === 0) {
          setTimeout(() => {
            this.serviceList();
          }, 100);
          services = this.serviceViewModel.services;
          services.forEach(x => {
            if (
              x.spec &&
              x.spec.networks &&
              x.spec.networks.length > 0 &&
              x.spec.networks[0].network_id &&
              x.spec.networks[0].network_id === newprops.formData.id &&
              x.spec.networks[0].ip_address &&
              x.spec.networks[0].ip_address.length > 0
            ) {
              this.setState({
                preferredIPAddressExists: true,
              });
            }
          });
        } else {
          services.forEach(x => {
            if (
              x.spec &&
              x.spec.networks &&
              x.spec.networks.length > 0 &&
              x.spec.networks[0].network_id &&
              x.spec.networks[0].network_id === newprops.formData.id &&
              x.spec.networks[0].ip_address &&
              x.spec.networks[0].ip_address.length > 0
            ) {
              this.setState({
                preferredIPAddressExists: true,
              });
            }
          });
        }
      }
    }
  }

  UNSAFE_componentWillMount() {
    this.props.UiStore.SetStoreData("helpData", "");
    this.props.UiStore.showRepNetwork = false;
  }

  getTagKeyValues = labels => {
    let tags = [];
    if (labels.name) {
      delete labels.name;
    }
    for (let key in labels) {
      if ((key && key.charAt(0) != "_") || key === "_iotium.firewall.name")
        tags.push(key + " : " + labels[key]);
    }
    return tags;
  };

  getSelectorValues = selectors => {
    let Selectedselectors = [];
    if (selectors.name) {
      delete selectors.name;
    }
    for (let key in selectors) {
      if ((key && key.charAt(0) != "_") || key === "_iotium.firewall.name")
        Selectedselectors.push(key + " : " + selectors[key]);
    }
    return Selectedselectors;
  };

  componentDidMount() {
    this.getFirewallGroups();
    focusFirstInput();
    this.updateForm(this.props);
    if (!this.props.cluster_id) {
      this.inodeModel.inode &&
        this.inodeModel.inode.type &&
        this.setState({ network_type: this.inodeModel.inode.type });
    }

    this.inodeModel.inode &&
      this.inodeModel.inode.config &&
      this.inodeModel.inode.config.network &&
      this.inodeModel.inode.config.network.cidr &&
      this.setState({ isCidrExist: true });

    this.inodeModel.inode &&
      this.inodeModel.inode.config &&
      this.inodeModel.inode.config.network &&
      this.inodeModel.inode.config.network.tan_interface_ip &&
      this.setState({
        isTanCidrExist: true,
        tanInterfaceCIDR: this.inodeModel.inode.config.network.tan_interface_ip,
      });
    this.inodeModel.inode &&
      this.inodeModel.inode.config &&
      this.inodeModel.inode.config.network &&
      this.inodeModel.inode.config.network.gateway &&
      this.setState({
        gatewayIP: this.inodeModel.inode.config.network.gateway,
      });

    this.inodeModel.inode &&
      this.inodeModel.inode.node_state === "UNREACHABLE" &&
      this.props.action != "create" &&
      this.setState({ isUnreachableNode: true });

    this.setState({
      allowFirewall: this.props.cluster_id
        ? true
        : NetworkContainer.checkforiNodeVersion(this.inodeModel.inode),
    });
    OrgController.getOrg(
      this.inodeModel.inode.organization
        ? this.inodeModel.inode.organization.id
        : this.props.orgId || this.props.match.params.id,
      false,
      this.props.UiStore.parentOrgData && this.props.UiStore.parentOrgData.id
        ? false
        : true,
    ).then(res => {
      if (res.parent_organization)
        this.props.UiStore.parentOrgData = res.parent_organization;
      else this.props.UiStore.parentOrgData = {};
    });
    this.getorgInfo();

    // Update help content
    setTimeout(() => {
      let elm = document.querySelector(
        ".tan_interface_ip .help-container .help-content",
      );
      if (elm) {
        elm.innerHTML = this.state.isStaticType
          ? getCurrentlocaleText(
              "network.form.interface.cidr.static.help.content",
            )
          : getCurrentlocaleText("network.form.interface.cidr.help.content", {
              0: this.props.cluster_id ? "master iNode" : "iNode",
            });
      }
    }, 50);
    //Check for service with static preferred IP
    if (
      this.state.preferredIPAddressExists === false &&
      this.props.formData &&
      this.props.formData.id
    ) {
      let services = this.serviceViewModel.services;
      if (!services || services.length === 0) {
        setTimeout(() => {
          this.serviceList();
        }, 100);
        services = this.serviceViewModel.services;
        services.forEach(x => {
          if (
            x.spec &&
            x.spec.networks &&
            x.spec.networks.length > 0 &&
            x.spec.networks[0].network_id &&
            x.spec.networks[0].network_id === this.props.formData.id &&
            x.spec.networks[0].ip_address &&
            x.spec.networks[0].ip_address.length > 0
          ) {
            this.setState({
              preferredIPAddressExists: true,
            });
          }
        });
      } else {
        services.forEach(x => {
          if (
            x.spec &&
            x.spec.networks &&
            x.spec.networks.length > 0 &&
            x.spec.networks[0].network_id &&
            x.spec.networks[0].network_id === this.props.formData.id &&
            x.spec.networks[0].ip_address &&
            x.spec.networks[0].ip_address.length > 0
          ) {
            this.setState({
              preferredIPAddressExists: true,
            });
          }
        });
      }
    }
  }

  serviceList = () => {
    ServiceController.getServicesForiNodes([this.props.nodeId]).then(resp => {
      if (this.state.preferredIPAddressExists === false) {
        let services = this.serviceViewModel.services;
        services.forEach(x => {
          if (
            x.spec &&
            x.spec.networks &&
            x.spec.networks.length > 0 &&
            x.spec.networks[0].network_id &&
            x.spec.networks[0].network_id === this.props.formData.id &&
            x.spec.networks[0].ip_address &&
            x.spec.networks[0].ip_address.length > 0
          ) {
            this.setState({
              preferredIPAddressExists: true,
            });
          }
        });
      }
    });
  };

  getorgInfo = () => {
    OrgController.getOrgById(this.props.orgId, true).then(response => {
      if (response) {
        this.setState({ orgInitialVal: response });
      }
    });
  };

  updateForm = props => {
    let formData = props.formData;
    if (
      ClusterViewModel.cluster &&
      ClusterViewModel.cluster.config &&
      ClusterViewModel.cluster.config.election_network_id &&
      formData.id &&
      ClusterViewModel.cluster.config.election_network_id == formData.id
    ) {
      this.setState({
        enableVrrpTan: true,
        hideVrrpselect: true,
      });
    }

    if (
      ClusterViewModel.cluster &&
      ClusterViewModel.cluster.config &&
      !ClusterViewModel.cluster.config.election_network_id
    ) {
      this.setState({
        hideVrrpselect: false,
      });
    } else {
      if (
        (ClusterViewModel.cluster &&
          ClusterViewModel.cluster.config &&
          ClusterViewModel.cluster.config.election_network_id &&
          formData.id != undefined &&
          ClusterViewModel.cluster.config.election_network_id !==
            formData.id) ||
        (ClusterViewModel.cluster &&
          ClusterViewModel.cluster.config &&
          ClusterViewModel.cluster.config.election_network_id &&
          formData.id == undefined)
      ) {
        if (
          ClusterViewModel.cluster.nodes &&
          ClusterViewModel.cluster.nodes.length >= 0 &&
          ClusterViewModel.cluster.status &&
          ClusterViewModel.cluster.status.nodes &&
          ClusterViewModel.cluster.status.nodes.length >= 0
        ) {
          this.setState({
            hideVrrpselect: true,
          });
        } else {
          this.setState({
            hideVrrpselect: false,
          });
        }
      }
    }
    let nodesCount =
      ClusterViewModel.cluster &&
      ClusterViewModel.cluster.status &&
      ClusterViewModel.cluster.status.nodes
        ? ClusterViewModel.cluster.status.nodes
        : [];
    nodesCount.forEach(node => {
      // Validation for network bounded time and current time different check to allow VRRP type selection.
      var currentTime = new Date().getTime();
      var boundedTime = new Date(
        ClusterViewModel.cluster.config.bound_at,
      ).getTime();
      var boundedTimeDiff = currentTime - boundedTime;
      var isboundAtTime = boundedTimeDiff > 300000;

      if (
        ClusterViewModel.cluster &&
        ClusterViewModel.cluster.config &&
        ClusterViewModel.cluster.config.election_network_id &&
        ClusterViewModel.cluster.config.election_network_id ==
          node.election_network_id &&
        formData.id &&
        ClusterViewModel.cluster.config.election_network_id == formData.id
      ) {
        this.setState({
          hideVrrpselect: true,
        });
      } else {
        this.setState({
          hideVrrpselect: false,
        });
      }
      if (
        !(
          ClusterViewModel.cluster &&
          ClusterViewModel.cluster.config &&
          ClusterViewModel.cluster.config.election_network_id &&
          ClusterViewModel.cluster.config.election_network_id ==
            node.election_network_id &&
          formData.id &&
          ClusterViewModel.cluster.config.election_network_id == formData.id
        ) &&
        isboundAtTime
      ) {
        this.setState({
          hideVrrpselect: false,
        });
      }
    });

    if (formData && formData.type) {
      this.setState({
        network_type: formData.type,
      });
      if (formData.type != "STATIC") {
        this.setState({ isStaticType: false });
      }
    }
    if (
      formData &&
      formData.config &&
      formData.config.network &&
      formData.config.network.cidr
    ) {
      this.setState({ isCidrExist: true });
    }
    if (
      formData &&
      formData.config &&
      formData.config.network &&
      formData.config.network.tan_interface_ip
    ) {
      this.setState({
        isTanCidrExist: true,
        tanInterfaceCIDR: formData.config.network.tan_interface_ip,
      });
    }
    if (
      formData &&
      formData.config &&
      formData.config.network &&
      formData.config.network.gateway
    ) {
      this.setState({
        gatewayIP: formData.config.network.gateway,
      });
    }
    if (
      formData &&
      formData.policy &&
      formData.policy.inter_network_routing &&
      formData.policy.inter_network_routing.enable_inter_tan
    ) {
      this.setState({
        enableInterTan: formData.policy.inter_network_routing.enable_inter_tan,
        itrLinkAddress: formData.policy.inter_network_routing.link_address,
      });
    }

    // For default routes
    if (formData && formData.connected_networks) {
      let selectedVnode = formData.connected_networks.map(cnw => {
        return {
          node_id: cnw.node && cnw.node.id ? cnw.node.id : null,
          cluster_id: cnw.cluster && cnw.cluster.id ? cnw.cluster.id : null,
          network_id: cnw.network && cnw.network.id ? cnw.network.id : null,
        };
      });
      this.setState({ selectedVnode: selectedVnode });
    }
    if (formData && formData.metadata && formData.metadata.labels) {
      this.setState({ metadata: formData.metadata.labels });
      this.props.form.setFields({
        metadata: {
          value: formData.metadata,
        },
      });

      this.setState({ tags: this.getTagKeyValues(formData.metadata.labels) });
    }
    if (formData && formData.policy && formData.policy.firewall) {
      this.setState({
        enableSecurityPolicyDebugger: formData.policy.firewall.debug
          ? formData.policy.firewall.debug
          : false,
      });
      this.props.onDebuggerChange(
        formData.policy.firewall.debug ? formData.policy.firewall.debug : false,
      );
    }
    if (formData && formData.firewall_selector) {
      this.props.UiStore.firewall_selector = {
        firewall_selector: formData.firewall_selector,
      };
      this.setState({
        selectors: this.getSelectorValues(
          formData.firewall_selector.match_label,
        ),
        selectedLabels: this.updateSelectedKeys(
          this.getSelectorValues(formData.firewall_selector.match_label),
        ),
      });
      let selected_Policy = this.getSelectorValues(
        formData.firewall_selector.match_label,
      );
      this.props.form.setFields({
        firewall_selector: {
          value: selected_Policy,
        },
      });
    }
    if (
      formData &&
      formData.firewall_selector &&
      formData.firewall_selector.match_label &&
      formData.firewall_selector.match_label["_iotium.firewall.hairpin"]
    ) {
      this.props.form.setFields({
        hairpinning: {
          value:
            formData.firewall_selector.match_label["_iotium.firewall.hairpin"],
        },
      });
      this.setState({
        currentHairpinState:
          formData.firewall_selector.match_label["_iotium.firewall.hairpin"],
      });
    } else {
      this.props.form.setFields({
        hairpinning: {
          value: "hairpin-deny",
        },
      });
      this.setState({ currentHairpinState: "hairpin-deny" });
    }

    if (
      formData &&
      formData.config &&
      formData.config.network &&
      formData.config.network.vlan_id &&
      formData.config.network.vlan_id >= 1
    ) {
      this.setState({
        isVlanEnabled: true,
        vlanVal: "enabled",
        vlanId: formData.config.network.vlan_id,
      });
    }

    //_iotium.firewall.hairpin
    if (props.formData && props.formData.config && !this.props.isVirtualNode) {
      this.props.form.setFields({
        name: {
          value: formData.name,
        },
        attached_interfaces: {
          value:
            formData.attached_to && formData.attached_to.interfaces
              ? formData.attached_to.interfaces[0]
              : null,
        },
        network_type: {
          value: formData.type,
        },
        cidr: {
          value: formData.config.network.cidr,
        },
        vlan_enabled: {
          value: formData.config.network.vlan_id === 0 ? "disabled" : "enabled",
        },
        vlan_id: {
          value:
            formData.config.network.vlan_id === 0
              ? null
              : formData.config.network.vlan_id,
        },
        service_addressing: {
          value: formData.config.network.service_addressing
            ? formData.config.network.service_addressing
            : "AUTO",
        },
        "reserved.start": {
          value:
            formData.config.network.reserved &&
            formData.config.network.reserved[0].start,
        },
        "reserved.end": {
          value:
            formData.config.network.reserved &&
            formData.config.network.reserved[0].end,
        },
      });

      if (
        formData.connected_networks &&
        formData.connected_networks.length > 0
      ) {
        if (formData.connected_networks[0].representation_network) {
          this.props.form.setFields({
            representation_network: {
              value:
                formData.connected_networks[0].representation_network.value,
            },
          });
          this.cidrRangeInfo(
            formData.connected_networks[0].representation_network.value,
            "repCidrRangeInfo",
          );
          this.tanCidrRangeInfo(
            formData.connected_networks[0].representation_network.value,
            "repCidrRangeInfo",
          );
        }
        this.setState({ showRepNetwork: true });
      }
      if (!formData.is_wan_network && formData.config.network.cidr) {
        // To show cidr range for infomation
        this.cidrRangeInfo(formData.config.network.cidr, "cidrRangeInfo");
        this.tanCidrRangeInfo(
          formData.config.network.tan_interface_ip,
          "tanCidrRangeInfo",
        );
        this.setState({
          currentCIDR: getCIDRdata(formData.config.network.cidr).networkAddress,
        });
      }
    } else if (this.props.isVirtualNode) {
      this.props.form.setFields({
        name: {
          value: formData.name,
        },
        attached_to: {
          value:
            formData.attached_to && formData.attached_to.interfaces
              ? formData.attached_to.interfaces[0]
              : null,
        },
        network_type: {
          value: formData && formData.type ? formData.type : "STATIC",
        },
        cidr: {
          value:
            formData.config &&
            formData.config.network &&
            formData.config.network.cidr,
        },
      });
    }
    // View form
    if (this.props.action === "view" && formData) {
      this.props.form.setFields({
        name: {
          value: formData.name,
        },
        attached_to: {
          value:
            formData.attached_to && formData.attached_to.interfaces
              ? formData.attached_to.interfaces[0]
              : null,
        },
        network_type: {
          value: formData.type,
        },
        cidr: {
          value:
            formData.config &&
            formData.config.network &&
            formData.config.network.cidr
              ? formData.config.network.cidr
              : "N/A",
        },
        "reserved.start": {
          value:
            formData.config &&
            formData.config.network &&
            formData.config.network.reserved
              ? formData.config.network.reserved[0].start
              : "N/A",
        },
        "reserved.end": {
          value:
            formData.config &&
            formData.config.network &&
            formData.config.network.reserved
              ? formData.config.network.reserved[0].end
              : "N/A",
        },
      });
    }
    // Gateway ip
    if (
      formData &&
      formData.config &&
      formData.config.network &&
      formData.config.network.gateway
    ) {
      this.props.form.setFields({
        gateway: {
          value: formData.config.network.gateway,
        },
      });
    }
    this.isDisabled(props);
    this.markSecurityHeader(formData);
    this.inodeModel.inode &&
      this.inodeModel.inode.node_state === "UNREACHABLE" &&
      this.props.action != "create" &&
      this.setState({ isUnreachableNode: true });

    this.inodeModel.inode.networkType &&
      this.setState({ networkType: this.inodeModel.inode.networkType });
  };
  /**
   *
   */
  updateSelectedKeys = values => {
    let selectedLabels = [];
    if (values.length > 0) {
      values.forEach(val => {
        selectedLabels.push(val.split(":")[0].trim());
      });
    }
    return selectedLabels;
  };
  /**
   * HAIRPIN CHANGE
   */
  handleHairpinChange = e => {
    this.setState({ showHairpinConfirm: true });
  };
  cancelHairpinChange = () => {
    this.setState({ showHairpinConfirm: false });
    this.props.form.setFields({
      hairpinning: {
        value: this.state.currentHairpinState,
      },
    });
  };
  confirmHairpinChange = () => {
    this.props.form.setFields({
      hairpinning: {
        value:
          this.state.currentHairpinState === "hairpin-allow"
            ? "hairpin-deny"
            : "hairpin-allow",
      },
    });
    this.setState({
      showHairpinConfirm: false,
      currentHairpinState:
        this.state.currentHairpinState === "hairpin-allow"
          ? "hairpin-deny"
          : "hairpin-allow",
    });
  };

  hidewarningModal = e => {
    /* Reset CIDR , Stat IP, End IP and TAN Interface Ip on discord changes */
    this.props.form.setFields({
      cidr: { value: this.props.formData.config.network.cidr },
      "reserved.start": {
        value:
          this.props.formData.config.network.reserved &&
          this.props.formData.config.network.reserved.length > 0
            ? this.props.formData.config.network.reserved[0].start
            : null,
      },
      "reserved.end": {
        value:
          this.props.formData.config.network.reserved &&
          this.props.formData.config.network.reserved.length > 0
            ? this.props.formData.config.network.reserved[0].end
            : null,
      },
      tan_interface_ip: {
        value: this.props.formData.config.network.tan_interface_ip,
      },
    });
    this.setState({
      tanCIDR: this.props.formData.config.network.cidr,
      tanInterfaceCIDR: this.props.formData.config.network.tan_interface_ip,
      showCirdUpdateWarning: false,
      loading_services: true,
    });
  };

  submitForm = () => {
    if (this.props.formData) {
      //(!(this.props.formData && this.props.formData.is_wan_network)) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let isCidrChanged =
              this.props.formData.config &&
              this.props.formData.config.network &&
              values.cidr &&
              this.props.formData.config.network.cidr !== values.cidr
                ? true
                : false,
            isTanCidrChanged =
              this.props.formData.config &&
              this.props.formData.config.network &&
              this.props.formData.config.network.tan_interface_ip !==
                values.tan_interface_ip
                ? true
                : false;
          if (isTanCidrChanged) {
            setTimeout(() => {
              this.setState({
                tanCidrChanged: true,
              });
            }, 10);
          }
          if (
            isCidrChanged &&
            this.props.formData &&
            this.props.formData.type &&
            this.props.formData.type.toLowerCase() === "static" &&
            this.props.formData.config &&
            this.props.formData.config.network &&
            this.props.formData.config.network.service_addressing &&
            this.props.formData.config.network.service_addressing.toLowerCase() ==
              "manual"
          ) {
            /* when service_addressing is 'manual' and user updated cidr, warn user to update ip in all services */
            try {
              let params = { network_id: this.props.netWorkId };
              ServiceController.getServiceCount(params).then(res => {
                if (res && res > 0) {
                  this.setState({
                    loading_services: false,
                    showCirdUpdateWarning: true,
                  });
                } else {
                  this.validateForm();
                }
              });
            } catch (err) {
              console.error(err);
            }
          } else {
            this.validateForm();
          }
        }
      });
    } else {
      this.validateForm();
    }
  };

  cidrEditwarningContent = () => {
    return (
      <div>
        {" "}
        <p>{getCurrentlocaleText("network.edit.cidrchange.warning")}</p>
      </div>
    );
  };

  validateForm = () => {
    if (this.props.formData) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (values.firewall_selector) {
            let obj = this.props.UiStore.firewall_selector.firewall_selector
              .match_label;
            obj["_iotium.firewall.hairpin"] = values.hairpinning;
            let firewall_selector = {
              firewall_selector: { match_label: obj },
            };
            this.props.UiStore.firewall_selector = firewall_selector;
          }
          delete values.hairpinning;
          this.props.onSubmit(values);
          this.setState({ formValues: values });
        }
        return values;
      });
    } else {
      this.props.form.validateFields(["firewall_selector"], (err, values) => {
        if (!err) {
          this.networkModel.loading = true;
          values = {
            ...values,
            metadata: this.props.form.getFieldValue("metadata"),
            firewall_selector: this.props.form.getFieldValue("metadata"),
          };
          this.props.onSubmit(values, this.props.formData.is_wan_network);
        }
      });
    }
  };

  /**
   * Handle On Deselect
   */
  handleOnDeselect = value => {
    this.clearUnselectedLabels(
      value,
      this.state.selectedLabels,
      this.state.selectors,
    );
    let index1 = this.state.firewallLabel_options.indexOf(value);
    let availableTag = this.state.firewallLabel_options;

    if (
      this.state.firewallLabel_options.indexOf(value) != -1 &&
      this.state.NetworkLabels.indexOf(value) === -1
    ) {
      availableTag.splice(index1);
      this.setState({ firewallLabel_options: availableTag });
    }
  };
  /**
   * Check for Unselected Labels
   */
  clearUnselectedLabels = (value, selectedLabels, availabelSelectors) => {
    let key = value.split(":")[0].trim(),
      index = availabelSelectors.indexOf(value);
    availabelSelectors.splice(index, 1);
    selectedLabels.splice(selectedLabels.indexOf(key), 1);
    return {
      selected_values: selectedLabels,
      available_selectors: availabelSelectors,
    };
  };
  /**
   * Method to handel change
   */
  handleChange = values => {
    let fw_selector_values = {};
    let availableTag = this.state.firewallLabel_options;
    let resolvedTag = this.state.NetworkLabels;
    let selectedLabels = this.state.selectedLabels;
    values.forEach(value => {
      if (availableTag.indexOf(value) === -1) {
        availableTag.push(value);
        if (!NetworkContainer.checkForValidLabel(value))
          this.setState({
            invalidSelector: true,
            firewallLabel_options: availableTag,
          });
        else this.setState({ firewallLabel_options: availableTag });
      }

      let data = value.split(":");
      if (data.length > 1) fw_selector_values[data[0].trim()] = data[1].trim();
      if (selectedLabels.indexOf(data[0]) === -1) selectedLabels.push(data[0]);
    });
    this.setState({
      NetworkLabels: resolvedTag,
      selectedLabels: selectedLabels,
      selectors: values,
    });
    //firewallSelector
    let firewall_selector = {
      firewall_selector: { match_label: fw_selector_values },
    };
    this.props.form.setFields({
      firewall_selector: {
        firewall_selector: { match_label: fw_selector_values },
      },
    });
    this.props.UiStore.firewall_selector = firewall_selector;
  };

  handleVlanChange = value => {
    let vlan = value.target.value;
    let isVlanEnabled = vlan != "disabled";
    this.setState({
      isVlanEnabled: isVlanEnabled,
    });
    if (vlan == "disabled") {
      this.props.form.setFields({
        vlan_id: {
          value: null,
        },
      });
    }
  };

  handleTypeChange = value => {
    let type = value.target.value;
    let isStatic = type === "STATIC" ? true : false;
    this.setState(
      {
        isStaticType: isStatic,
        network_type: type,
        isCidrExist: false,
        isTanCidrExist: false,
        tanCIDR: null,
        tanInterfaceCIDR: null,
        cidrRangeInfo: null,
        tanCidrRangeInfo: null,
      },
      () => {
        this.props.form.setFields({
          network_type: {
            value: type,
          },
          tan_interface_ip: {
            value: null,
          },
          gateway: { value: null },
        });
        if (!isStatic) {
          this.props.form.setFields({
            service_addressing: {
              value: "AUTO",
            },
          });
        }
      },
    );
  };

  isDisabled = props => {
    let givenProps = props ? props : this.props;
    if (givenProps.action === "view") this.setState({ isDisabled: true });
    else if (
      givenProps.action === "create" &&
      givenProps.UiStore.showRepNetwork
    )
      this.setState({ isDisabled: true });
    else if (givenProps.isVirtualNode) this.setState({ isDisabled: true });
    else if (givenProps.formData && givenProps.formData.is_wan_network)
      this.setState({ isDisabled: true });
    else this.setState({ isDisabled: false });
  };

  /**
   * Handle show hide
   */
  handleFW = () => {
    this.setState({ showRules: !this.state.showRules });
  };

  setVnode = value => {
    this.setState({ selectedVnode: value });
    if (value != null) {
      if (parseFloat(getNodeVersion(this.inodeModel.inode)) >= baseVersion)
        this.setState({ showRepNetwork: true });
      this.props.form.setFields({
        vNode: {
          value: value,
        },
      });
    } else {
      this.props.form.resetFields(["vNode"]);

      if (parseFloat(getNodeVersion(this.inodeModel.inode)) >= baseVersion)
        this.setState({ showRepNetwork: false });
    }
  };

  /**-----------------------------------------------------------------------------**/
  checkForPolicy = (rule, value, callback) => {
    let invalidLabels = [];
    if (value && value.length > 0) {
      if (
        value.length >
        getOrgPolicyCounInfo(this.orgModel.org, "custom_policy_count")
      ) {
        callback(
          getCurrentlocaleText(
            "security_policy.selector.maximum_limit.group.warning.text",
            {
              0: getOrgPolicyCounInfo(this.orgModel.org, "custom_policy_count"),
            },
          ),
        );
        this.setState({
          firewall_validate_state: "error",
          validateMessage: getCurrentlocaleText(
            "security_policy.selector.maximum_limit.group.warning.text",
            {
              0: getOrgPolicyCounInfo(this.orgModel.org, "custom_policy_count"),
            },
          ),
        });
      } else {
        value.forEach(val => {
          if (
            !NetworkContainer.checkForValidLabel(val) &&
            !val.includes("_iotium.")
          )
            invalidLabels.push(val);
        });
        this.setState({ invalidLabels: invalidLabels });
        if (invalidLabels.length > 0) {
          callback(getCurrentlocaleText("general.label.input.error1.text"));
          this.setState({
            firewall_validate_state: "error",
            validateMessage: getCurrentlocaleText(
              "general.label.input.error1.text",
            ),
          });
        } else {
          if (
            NetworkContainer.checkForMultiplePolicies(
              value,
              this.state.firewallLabel_options,
            )
          ) {
            this.setState({
              firewall_validate_state: "warning",
              validateMessage: getCurrentlocaleText(
                "secuirty_policy.multiple_resolve.warning.text",
              ),
            });
            callback();
          } else {
            callback();
            this.setState({ firewall_validate_state: "", validateMessage: "" });
          }
        }
      }
    } else {
      if (
        NetworkContainer.checkForMultiplePolicies(
          value,
          this.state.firewallLabel_options,
        )
      ) {
        this.setState({
          firewall_validate_state: "warning",
          validateMessage: getCurrentlocaleText(
            "secuirty_policy.multiple_resolve.warning.text",
          ),
        });
        callback();
      } else {
        callback();
        this.setState({ firewall_validate_state: "", validateMessage: "" });
      }
    }
  };

  /*-----------------------------CIDR validation ----------------------------------*/
  checkCidr = (rule, value, callback) => {
    let form = this.props.form;
    this.setState({ tanCIDR: value });
    if (value == null) {
      callback();
    }
    let isCidrValid = value && isCidr(value),
      currentCIDR = getCIDRdata(value);
    if (!isCidrValid) {
      callback(getCurrentlocaleText("network.form.cidr.errormessage2.text"));
    }
    if (currentCIDR) {
      if (isRestrictedCIDR(currentCIDR)) {
        callback(getCurrentlocaleText("network.form.cidr.errormessage3.text"));
      }
    }
    if (isCidrValid) {
      let isCidrIPInRange = isTanCidrRange(value, value);
      if (isCidrIPInRange) {
        callback(getCurrentlocaleText("network.form.cidr.errormessage4.text"));
      }
      let mask = value.split("/")[1];
      let tan_interface_ip = this.state.tanInterfaceCIDR;
      if (!tan_interface_ip) {
        tan_interface_ip = "/" + mask;
      } else {
        tan_interface_ip = !tan_interface_ip.includes("/")
          ? tan_interface_ip + "/"
          : tan_interface_ip;
        tan_interface_ip = tan_interface_ip.split("/")[0]
          ? tan_interface_ip.split("/")[0] + "/" + mask
          : "" + "/" + mask;
      }
      form.setFields({
        tan_interface_ip: { value: tan_interface_ip },
      });
      this.setState({ tanInterfaceCIDR: tan_interface_ip });
      this.cidrRangeInfo(value, "cidrRangeInfo");
      // Check if cidr & ip address are valid
      // TODO - RK - Need to change, directly call the dependent validator
      let startIp = form.getFieldValue("reserved.start");
      let endIp = form.getFieldValue("reserved.end");
      let tanInterfaceIp = form.getFieldValue("tan_interface_ip");
      // Check if start Ip is not exists and end ip is exists
      if (!startIp && endIp) {
        form.setFields({
          "reserved.start": { value: endIp },
        });
        startIp = endIp;
      }
      // Check if start Ip is exists and end ip is not exists
      if (startIp && !endIp) {
        form.setFields({
          "reserved.end": { value: startIp },
        });
        endIp = startIp;
      }
      // Check if tan interface Ip is not exists or contains only mask, then set the tan interface from StartIp
      if (startIp && (!tanInterfaceIp || tanInterfaceIp == "/" + mask)) {
        tanInterfaceIp = startIp + "/" + mask;
        form.setFields({ gateway: { value: startIp } });
        this.setState({ tanInterfaceCIDR: tanInterfaceIp });
      }
      // Check start ip and validate with cidr value
      if (startIp) {
        this.isCidrAndIpValid(value, startIp, "reserved.start");
      }
      // Check end ip and validate with cidr value
      if (endIp) {
        this.isCidrAndIpValid(value, endIp, "reserved.end");
      }
      // Check tan interface ip and validate with cidr value
      if (tanInterfaceIp && tanInterfaceIp != "/" + mask) {
        this.isCidrAndTanCidrValid(value, tanInterfaceIp, "tan_interface_ip");
      }
      callback();
    }
  };

  /*------------------------------------TAN Interface IP--------------------------*/
  checkTANCidr = (rule, value, callback) => {
    let form = this.props.form;
    this.setState({ tanInterfaceCIDR: value });
    if (value == null || value.split("/")[0].length == 0) {
      callback();
    }
    let isCidrValid = value && isCidr(value);

    let failMessage = null;
    if (!isCidrValid) {
      failMessage = getCurrentlocaleText(
        "network.form.cidr.errormessage2.text",
      );
    }

    if (failMessage) {
      let start = form.getFieldValue("reserved.start");
      if (start) {
        form.setFields({
          gateway: {
            value: start,
          },
        });
      }
      callback(failMessage);
    }
    if (!failMessage && isCidrValid) {
      let cidr = form.getFieldValue("cidr");
      this.isCidrAndTanCidrValid(
        cidr ? cidr : value,
        value,
        "tan_interface_ip",
      );
      callback();
    }
  };

  /*-----------------------------VLAN validation ----------------------------------*/
  checkVlan = (rule, value, callback) => {
    let isVlanValid =
      !this.state.isVlanEnabled || (value && value >= 1 && value <= 4094);
    if (!isVlanValid) {
      callback(getCurrentlocaleText("network.form.vlan.errormessage2.text"));
    }
    callback();
  };
  //*-----------------Custom Validation ---------------------------------------------------------------------*/
  isrepCidrAllowed(value) {
    let currentCIDR = getCIDRdata(value);
    if (currentCIDR) {
      if (isRestrictedCIDR(currentCIDR)) {
        return 1;
      }
      if (this.props.form.getFieldValue("cidr")) {
        let networkCIDR = getCIDRdata(this.props.form.getFieldValue("cidr"));
        if (networkCIDR) {
          if (networkCIDR.subnetMaskLength !== currentCIDR.subnetMaskLength) {
            return 2;
          }
          if (networkCIDR.networkAddress === currentCIDR.networkAddress) {
            return 3;
          } else {
            return 0;
          }
        }
      }
      return 0;
    }
  }
  /*---------------------------------------------------------------------------------------------------------*/
  isCidrAndIpValid = (cidrValue, ipValue, fieldName) => {
    let isCidrValid =
      cidrValue && isCidr(cidrValue) && isCidrRange(cidrValue, ipValue);
    let obj = {};
    if (ipValue) {
      obj[fieldName] = { value: this.props.form.getFieldValue(`${fieldName}`) };
      if (isCidrValid && ip.isV4Format(ipValue)) {
        this.props.form.setFields(obj);
        this.setState({ reservedRangeErrorCodes: "" });
      } else if (ip.isV4Format(ipValue)) {
        let cidrRangeInfo = null,
          cidrSubnet = isCidr(cidrValue) && ip.cidrSubnet(cidrValue);
        if (cidrSubnet) {
          cidrRangeInfo = `${cidrSubnet.firstAddress} - ${cidrSubnet.lastAddress}`;
        }
        let errors =
          cidrRangeInfo != null
            ? getCurrentlocaleText("network.form.startip.errormessage2.text") +
              " " +
              cidrRangeInfo
            : getCurrentlocaleText("network.form.startip.errormessage2.text");

        this.setState({ reservedRangeErrorCodes: errors });
        this.props.form.setFields(obj);
        return errors;
      } else {
        let errors = getCurrentlocaleText(
          "network.form.startip.errormessage1.text",
        );
        this.setState({ reservedRangeErrorCodes: errors });
        this.props.form.setFields(obj);
        return errors;
      }
    }
  };

  /*---------------------------------------Is CIDR and iNode IP address valid------------------------------------------------------------------*/
  isCidrAndTanCidrValid = (cidrValue, tan_cidr, fieldName) => {
    let currentCIDR = getCIDRdata(tan_cidr),
      obj = {};
    let isCidrValid =
      cidrValue &&
      isCidr(cidrValue) &&
      isCidr(tan_cidr) &&
      isTanCidrRange(cidrValue, tan_cidr);
    let tan_ip = tan_cidr;
    if (tan_ip && isCidr(tan_ip) === true) {
      tan_ip = tan_ip.split("/")[0];
    }
    if (tan_cidr) {
      obj[fieldName] = { value: this.props.form.getFieldValue(`${fieldName}`) };
      if (currentCIDR) {
        if (isRestrictedCIDR(currentCIDR)) {
          obj[fieldName].errors = [
            new Error(
              getCurrentlocaleText(
                "network.form.interface.cidr.errormessage2.text",
              ),
            ),
          ];
          setTimeout(() => {
            this.props.form.setFields(obj);
          }, 100);
        }
      }
      if (!obj[fieldName].errors) {
        if (isCidrValid) {
          this.setState({ tanInterfaceCIDR: tan_cidr });
          this.tanCidrRangeInfo(tan_cidr, "tanCidrRangeInfo");
          this.setState({ gatewayIP: tan_cidr.split("/")[0] });
          this.props.form.setFields({
            gateway: {
              value: tan_cidr.split("/")[0],
            },
          });
          this.props.form.setFields(obj);
        } else {
          if (!isCidrValid) {
            let cidrRangeInfo = null,
              cidrSubnet = ip.cidrSubnet(cidrValue);
            if (cidrSubnet) {
              cidrRangeInfo = `${cidrSubnet.firstAddress} - ${cidrSubnet.lastAddress}`;
            }
            obj[fieldName].errors = [
              new Error(
                cidrRangeInfo != null
                  ? getCurrentlocaleText(
                      "network.form.startip.errormessage2.text",
                    ) +
                    " " +
                    cidrRangeInfo
                  : getCurrentlocaleText(
                      "network.form.startip.errormessage2.text",
                    ),
              ),
            ];
            setTimeout(() => {
              this.props.form.setFields(obj);
            }, 100);
          }
        }
      }
    }
  };

  handleLabelChange = values => {
    this.props.form.setFields({
      metadata: {
        value: { labels: values },
      },
    });
  };

  //Update cidr changes coming from the cidrprovider component
  handleCidrChange = value => {
    this.props.form.setFields({
      cidr: {
        value: value,
      },
    });
    this.props.form.validateFields(["cidr"], (err, value) => {
      if (!err) {
        this.setState({ isCidrExist: true });
      }
    });
  };

  //Update tan interface IP changes coming from the tancidrprovider component
  handleTanInterfaceChange = value => {
    this.props.form.setFields({
      tan_interface_ip: { value: value },
    });
    this.props.form.validateFields(["tan_interface_ip"], (err, value) => {
      if (!err) {
        this.setState({ isTanCidrExist: true });
      }
    });
  };

  setTanInterfaceIpFromStartIp = value => {
    if (value) {
      let form = this.props.form;
      let netmask = form.getFieldValue("cidr")
        ? form.getFieldValue("cidr").split("/")[1]
        : null;
      // If cidr netmask exists then, validate the tan interface ip
      if (netmask) {
        let tanInterfaceIp = value + "/" + netmask;
        if (
          !this.state.tanInterfaceCIDR ||
          this.state.tanInterfaceCIDR.split("/")[0].length == 0
        ) {
          form.setFields({
            gateway: {
              value: value,
            },
            tan_interface_ip: { value: tanInterfaceIp },
          });
          this.setState({ tanInterfaceCIDR: tanInterfaceIp });
          this.isCidrAndTanCidrValid(
            form.getFieldValue("cidr"),
            tanInterfaceIp,
            "tan_interface_ip",
          );
        }
      }
    }
  };

  checkStartIpV4 = (rule, value, callback) => {
    let form = this.props.form;
    let failMessage = null;
    try {
      if (
        !(
          this.props.isVirtualNode ||
          (this.props.formData && this.props.formData.is_wan_network) ||
          !this.state.isStaticType
        ) &&
        (!value || value.length === 0)
      ) {
        failMessage = getCurrentlocaleText(
          "network.form.startip.errormessage1.text",
        );
      } else if (value && ip.isV4Format(value) !== true) {
        failMessage = getCurrentlocaleText(
          "network.form.startip.errormessage1.text",
        );
      } else if (
        value &&
        value.length > 0 &&
        ip.isV4Format(value) === true &&
        (isCidr(value) === true ||
          !isCidrRange(form.getFieldValue("cidr"), value))
      ) {
        // TODO - AV - Hiding range check for now.
        failMessage =
          this.state.cidrRangeInfo != null
            ? getCurrentlocaleText("network.form.startip.errormessage2.text") +
              " " +
              this.state.cidrRangeInfo
            : getCurrentlocaleText("network.form.startip.errormessage2.text");
      }
    } catch (e) {
      failMessage =
        this.state.cidrRangeInfo != null
          ? getCurrentlocaleText("network.form.startip.errormessage2.text") +
            " " +
            this.state.cidrRangeInfo
          : getCurrentlocaleText("network.form.startip.errormessage2.text");
    }
    if (value && !failMessage) {
      let startIP = ip.toLong(value);
      if (
        !this.state.isReservedEndTempChange &&
        form.getFieldValue("reserved.end")
      ) {
        let endIP = ip.toLong(form.getFieldValue("reserved.end"));
        if (endIP && startIP > endIP) {
          failMessage = getCurrentlocaleText(
            "network.form.startip.errormessage3.text",
          );
        } else {
          this.setTanInterfaceIpFromStartIp(value);
          failMessage = this.isCidrAndIpValid(
            form.getFieldValue("cidr"),
            form.getFieldValue("reserved.end"),
            "reserved.end",
          );
        }
      } else {
        this.setTanInterfaceIpFromStartIp(value);
      }
    }
    if (failMessage) {
      callback("");
      this.setState({ reservedRangeErrorCodes: failMessage });
    } else {
      if (value) {
        this.setState({ reservedRangeErrorCodes: "" });
        this.setState({ isReservedStartTempChange: false });
        if (
          this.state.isReservedEndTempChange ||
          !form.getFieldValue("reserved.end")
        ) {
          this.setState({ isReservedEndTempChange: true });
          form.setFields({ ["reserved.end"]: { value: value } });
        }
      }
      callback();
    }
  };

  checkIpV4 = (rule, value, callback) => {
    let form = this.props.form;
    let failMessage = null;
    try {
      if (
        !(
          this.props.isVirtualNode ||
          (this.props.formData && this.props.formData.is_wan_network) ||
          !this.state.isStaticType
        ) &&
        (!value || value.length === 0)
      ) {
        failMessage = getCurrentlocaleText(
          "network.form.startip.errormessage1.text",
        );
      } else if (value && ip.isV4Format(value) !== true) {
        failMessage = getCurrentlocaleText(
          "network.form.startip.errormessage1.text",
        );
      } else if (
        value &&
        ip.isV4Format(value) === true &&
        (isCidr(value) === true ||
          !isCidrRange(form.getFieldValue("cidr"), value))
      ) {
        // TODO - AV - Hiding range check for now.
        failMessage =
          this.state.cidrRangeInfo != null
            ? getCurrentlocaleText("network.form.startip.errormessage2.text") +
              " " +
              this.state.cidrRangeInfo
            : getCurrentlocaleText("network.form.startip.errormessage2.text");
      }
    } catch (e) {
      failMessage =
        this.state.cidrRangeInfo != null
          ? getCurrentlocaleText("network.form.startip.errormessage2.text") +
            " " +
            this.state.cidrRangeInfo
          : getCurrentlocaleText("network.form.startip.errormessage2.text");
    }
    if (value && !failMessage) {
      this.setState({ isReservedEndTempChange: false });
      let endIP = ip.toLong(value);
      if (
        !this.state.isReservedStartTempChange &&
        form.getFieldValue("reserved.start")
      ) {
        let startIP = ip.toLong(form.getFieldValue("reserved.start"));
        if (startIP && endIP < startIP) {
          failMessage = getCurrentlocaleText(
            "network.form.startip.errormessage3.text",
          );
        } else {
          failMessage = this.isCidrAndIpValid(
            form.getFieldValue("cidr"),
            form.getFieldValue("reserved.start"),
            "reserved.start",
          );
        }
      }
    }
    if (failMessage) {
      callback("");
      this.setState({ reservedRangeErrorCodes: failMessage });
    } else {
      if (
        this.state.isReservedStartTempChange ||
        !form.getFieldValue("reserved.start")
      ) {
        this.setState({ isReservedStartTempChange: true });
        form.setFields({ ["reserved.start"]: { value: value } });
      }
      this.setState({ reservedRangeErrorCodes: "" });
      callback();
    }
  };

  firewallDebugger = debug_status => {
    this.setState({ enableSecurityPolicyDebugger: debug_status });
    this.props.onDebuggerChange(debug_status);
  };

  getFirewallGroups = (
    loading = true,
    page = 0,
    search = "",
    sortBy = null,
    sortOrder = null,
  ) => {
    let params = { org_id: this.props.orgId, own: true };
    params.page = page;
    (params.search = search), (params.size = 1);

    let firewall_labels = [];
    let firewall_labelAll = [];
    // For sort
    if (sortBy && sortOrder) {
      params.sort = `${sortBy}:${sortOrder}`;
    }

    FirewallGroupController.getFirewallGroups(params, loading).then(result => {
      let totalCount = FirewallGroupController.getTotalCount();
      if (totalCount > 0) {
        let allParams = [];
        for (let page = 0; page < totalCount / 100; page++) {
          allParams.push({
            org_id: this.props.orgId || this.props.match.params.id,
            own: true,
            size: 100,
            page: page,
            sort: "name:ASC",
          });
        }
        FirewallGroupController.getFirewallGroupsForDropdown(
          allParams,
          true,
          true,
        ).then(res => {
          res.forEach(l => {
            if (l.metadata && !l.is_default) {
              for (var key in l.metadata.labels) {
                if (
                  (key && key.charAt(0) != "_") ||
                  key === "_iotium.firewall.name"
                ) {
                  firewall_labels.push(key + " : " + l.metadata.labels[key]);
                }
              }
            }
          });
          let currentLabel = firewall_labels;
          if (
            this.props.formData &&
            this.props.formData.firewall_selector &&
            this.props.formData.firewall_selector.match_label
          ) {
            this.getSelectorValues(
              this.props.formData.firewall_selector.match_label,
            ).forEach(l => {
              if (currentLabel.indexOf(l) === -1) {
                currentLabel.push(l);
              }
            });
          }
          this.setState({
            firewallLables: res,
            firewallLabel_options: currentLabel,
            NetworkLabels: copyObject(firewall_labels),
          });
          let selected_Policy = this.getSelectorValues(
            this.props.formData && this.props.formData.firewall_selector
              ? this.props.formData.firewall_selector.match_label
              : {},
          );
          if (
            NetworkContainer.checkForMultiplePolicies(
              selected_Policy,
              currentLabel,
            )
          ) {
            this.setState({
              firewall_validate_state: "warning",
              validateMessage: getCurrentlocaleText(
                "secuirty_policy.multiple_resolve.warning.text",
              ),
            });
          } else {
            this.setState({
              firewall_validate_state: "",
              validateMessage: "",
            });
          }
        });
      }
    });
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
    setTimeout(() => {
      let elm = document.querySelector(".help-content");
      if (elm) {
        elm.innerHTML =
          data && data.data && data.data[0] && data.data[0].content
            ? data.data[0].content
            : "";
      }
    }, 10);
  };

  resetHelp = () => {
    this.props.UiStore.SetStoreData("helpData", {});
  };

  checkLoadingstate = status => {
    this.setState({ RemoteNetworkloading: status });
  };

  pushAvailableInodes = inodes => {
    this.setState({ availableInodes: inodes });
  };

  cidrRangeInfo = (cidr, state) => {
    let isCidrValid = cidr && isCidr(cidr),
      currentCIDR = getCIDRdata(cidr);
    if (isCidrValid && currentCIDR && !isRestrictedCIDR(currentCIDR)) {
      let cidrSubnet = ip.cidrSubnet(cidr);
      if (cidrSubnet) {
        this.setState({
          [state]: `${cidrSubnet.firstAddress} - ${cidrSubnet.lastAddress}`,
        });
      } else {
        this.setState({ [state]: null });
      }
      this.setState({ currentCIDR: cidr });
    } else {
      this.setState({ [state]: null });
    }
  };

  tanCidrRangeInfo = (tanInterfaceCIDR, state) => {
    let isCidrValid = tanInterfaceCIDR && isCidr(tanInterfaceCIDR),
      currentCIDR = getCIDRdata(tanInterfaceCIDR);
    if (isCidrValid && currentCIDR && !isRestrictedCIDR(currentCIDR)) {
      let cidrSubnet = ip.cidrSubnet(tanInterfaceCIDR);
      if (cidrSubnet) {
        this.setState({
          [state]: `${cidrSubnet.firstAddress} - ${cidrSubnet.lastAddress}`,
        });
      } else {
        this.setState({ [state]: null });
      }
    } else {
      this.setState({ [state]: null });
    }
  };

  markSecurityHeader = formData => {
    let hasValue = false;
    if (formData) {
      // hairpinning & firewall
      if (
        formData.firewall_selector &&
        formData.firewall_selector.match_label
      ) {
        if (!this.props.formData.is_wan_network) {
          // firewall
          if (
            this.getSelectorValues(formData.firewall_selector.match_label)
              .length > 0
          ) {
            if (!hasValue) {
              hasValue = true;
            }
          }
          // hairpining
          if (this.state.isVirtualNode && this.state.allowFirewall) {
            if (!hasValue) {
              hasValue = true;
            }
          }
        }
      }
      // formData.policy.firewall.debug
      if (
        formData.policy &&
        formData.policy.firewall &&
        formData.policy.firewall.debug
      ) {
        if (!hasValue) {
          hasValue = true;
        }
      }
    }
    markCollapseHeader("security-collapse", hasValue);
  };

  setRoutes = routes => {
    this.props.form.setFields({
      routes: {
        value: routes,
      },
    });
    this.props.UiStore.defaultRoutes = routes;
  };

  setStaticRoutes = routes => {
    this.props.form.setFields({
      static_routes: {
        value: routes,
      },
    });
  };

  serviceAddressingOpts = () => {
    return this.service_Ip_Addressing.map((val, index) => {
      let content = `service.addressing.${
          !this.state.isStaticType &&
          val.name &&
          val.name.toLowerCase() != "static"
            ? "dynamic." + val.name.toLowerCase()
            : val.name.toLowerCase()
        }.helptext`,
        name = val.name.toLowerCase();
      return (
        <RadioButton
          key={val.value}
          value={val.value}
          onFocus={this.updateHelp.bind(this, {
            title: getCurrentlocaleText(`network.page.service.addressing`),
            data: [
              {
                subtitle: getCurrentlocaleText(name),
                content: getCurrentlocaleText(content),
              },
            ],
          })}
        >
          {val.name}
        </RadioButton>
      );
    });
  };

  vlanOpts = () => {
    return this.vlan_Options.map((val, index) => {
      let content = `network.form.vlan.${val.name.toLowerCase()}.help.content`,
        name = `network.form.vlan.${val.name.toLowerCase()}.label.text`;
      return (
        <RadioButton
          key={val.value}
          value={val.value}
          onFocus={this.updateHelp.bind(this, {
            title: getCurrentlocaleText(`network.form.vlan.text`),
            data: [
              {
                subtitle: getCurrentlocaleText(name),
                content: getCurrentlocaleText(content),
              },
            ],
          })}
        >
          {val.name}
        </RadioButton>
      );
    });
  };

  checkForNetworkLengthBasedOnType = (
    networks,
    node_id,
    cluster_id = null,
    type = "STATIC",
  ) => {
    let currentNetworks = networks.filter(network => {
      if (!cluster_id && network && network.node) {
        return network.type === type && network.node.id === node_id;
      } else if (network && network.cluster) {
        return network.type === type && network.cluster.id === cluster_id;
      }
    });
    return currentNetworks.length;
  };

  renderInterfaces = () => {
    if (this.props.cluster_id) {
      if (
        this.props.clusterDetails &&
        this.props.clusterDetails.node_interfaces &&
        this.props.clusterDetails.node_interfaces.tan
      ) {
        return this.props.clusterDetails.node_interfaces.tan.map(
          tanInterface => {
            return (
              <Option
                key={tanInterface.index}
                value={tanInterface.display_name}
              >
                {tanInterface.display_name}
              </Option>
            );
          },
        );
      }
    } else if (this.props.nodeId) {
      if (
        this.inodeModel.inode &&
        this.inodeModel.inode.interfaces &&
        this.inodeModel.inode.interfaces.tan
      ) {
        return this.inodeModel.inode.interfaces.tan.map(tanInterface => {
          return (
            <Option key={tanInterface.index} value={tanInterface.display_name}>
              {tanInterface.display_name}
            </Option>
          );
        });
      }
    }
  };

  render() {
    let isMaster = isMasteriNode(this.inodeModel.inode);
    const { getFieldDecorator } = this.props.form;
    let children = [];
    if (this.FirewallGroupViewModel.loading) {
      children.push(
        <Option key="1">
          <span key={"spinner"}>
            <Spin size="small" style={{ marginRight: 5 }} />{" "}
            {getCurrentlocaleText("loading.text")}
          </span>
        </Option>,
      );
    } else {
      children = [];
    }
    let firewallLabel_options = [...new Set(this.state.firewallLabel_options)];
    for (let i = 0; i < firewallLabel_options.length; i++) {
      children.push(
        <Option
          key={i}
          title={firewallLabel_options[i].replace(
            "_iotium.firewall.name :",
            " ",
          )}
          value={firewallLabel_options[i]}
          /**
           * TS: This will come in future So commenting this lines
           */
          /*disabled={
          this.state.selectedLabels.includes(
            firewallLabel_options[i].split(":")[0].trim(),
          ) && !this.state.selectors.includes(firewallLabel_options[i])
        }*/
        >
          <span
            className={
              this.state.invalidLabels.includes(firewallLabel_options[i])
                ? "pheonix-tag-invalid"
                : null
            }
          >
            {firewallLabel_options[i].includes("_iotium.firewall.name :") ? (
              <Icons type="fa" name="FaShieldAlt" style={{ marginRight: 5 }} />
            ) : (
              <span>
                <Icons type="ai" name="AiOutlineUserAdd" />
                <Icons
                  type="ai"
                  name="AiOutlineTag"
                  className="node-selector-tag-icon"
                  key={i}
                  style={{ marginRight: 5 }}
                />
              </span>
            )}{" "}
            {firewallLabel_options[i].replace("_iotium.firewall.name :", " ")}
          </span>
        </Option>,
      );
    }
    let showStaticRoutes = false;
    let showDynamic = false;
    if (
      parseFloat(getNodeVersion(this.inodeModel.inode)) >=
        dynamicNetworkModeBaseVersion ||
      (this.inodeModel.inode && this.inodeModel.inode.node_state === "NEW") ||
      this.props.cluster_id
    ) {
      showDynamic = true;
    }

    let showITR = false;
    if (
      parseFloat(getNodeVersion(this.inodeModel.inode)) >
        InterTanRouteBaseVersion ||
      (this.inodeModel.inode && this.inodeModel.inode.node_state === "NEW") ||
      this.props.cluster_id
    ) {
      showITR = true;
    }

    // Disable if the network type is DYNAMIC
    if (this.state.network_type == "DYNAMIC") {
      showITR = false;
    }

    if (
      !this.props.formData.is_wan_network &&
      !this.state.isVirtualNode &&
      (parseFloat(getNodeVersion(this.inodeModel.inode)) >=
        StaticRoutesBaseVersion ||
        (this.inodeModel.inode && this.inodeModel.inode.node_state === "NEW") ||
        this.props.cluster_id)
    ) {
      showStaticRoutes = true;
    } else if (
      this.state.isVirtualNode &&
      parseFloat(getNodeVersion(this.inodeModel.inode)) >=
        VirtualRoutesBaseVersion
    ) {
      showStaticRoutes = true;
    }

    let showDynamicInCluster = false;
    if (this.props.cluster_id) {
      if (this.props.netWorkId) {
        showDynamicInCluster = true;
      } else {
        showDynamicInCluster =
          this.checkForNetworkLengthBasedOnType(
            this.networkModel.networks,
            this.props.node_id,
            this.props.cluster_id,
          ) > 0;
      }
      let clusterDetails = this.props.clusterDetails;
      if (clusterDetails.config.election_network_type == "TAN") {
        showDynamicInCluster = true;
      }
    }

    const TanInfoMessage = () => {
      return (
        <span className="info-message-wrap">
          <span>
            <Icons
              type="fa"
              name="FaInfoCircle"
              className=""
              style={{ marginTop: 10 }}
            />
          </span>
          {getCurrentlocaleText("network.form.interface.cidr.message.text")}
        </span>
      );
    };

    const disableItr =
      this.props.formData &&
      this.props.formData.config &&
      this.props.formData.config.network &&
      this.props.formData.config.network.service_addressing &&
      this.props.formData.config.network.service_addressing.toLowerCase() ===
        "manual"
        ? true
        : false;

    const isUCGNode =
      this.inodeModel.inode &&
      this.inodeModel.inode.profile &&
      this.inodeModel.inode.profile.name &&
      this.inodeModel.inode.profile.name == "Unified Cloud Gateway"
        ? true
        : false;
    return (
      <Form
        noValidate
        onSubmit={this.props.onSubmit}
        autoComplete="off"
        className="network-form"
      >
        <Row gutter={2} type="flex" align="top">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 14 }}
            lg={{ span: 14 }}
            xl={{ span: 14 }}
            className="card-grid"
            style={{
              paddingRight: 20,
              borderRight: "solid 1px rgba(0,0,0,0.2)",
            }}
          >
            <FormItem
              label={getCurrentlocaleText("network.form.name.placeholder.text")}
              hasFeedback
              {...formItemLayout}
            >
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: getCurrentlocaleText(
                      "network.form.networkname.errormessage1.text",
                    ),
                    whitespace: true,
                  },
                  {
                    max: 255,
                    message: getCurrentlocaleText(
                      "network.form.networkname.errormessage2.text",
                    ),
                  },
                  {
                    pattern: /^[A-Za-z0-9 ._-]*$/,
                    message: getCurrentlocaleText(
                      "network.form.networkname.errormessage3.text",
                    ),
                  },
                ],
              })(
                <Input
                  prefix={<Icons type="ai" name="AiOutlineIdcard" />}
                  placeholder={getCurrentlocaleText(
                    "network.form.name.placeholder.text",
                  )}
                  className="firstInput"
                  disabled={this.props.viewOnly || this.state.isDisabled}
                  title={
                    this.state.isDisabled
                      ? getCurrentlocaleText("network.form.disabled.help.text")
                      : getCurrentlocaleText(
                          "network.form.name.placeholder.text",
                        )
                  }
                  onFocus={this.updateHelp.bind(this, {
                    title: getCurrentlocaleText(
                      "network.networkname.help.title",
                    ),
                    data: [
                      {
                        subtitle: "",
                        content: getCurrentlocaleText(
                          "network.networkname.help.content",
                        ),
                      },
                    ],
                  })}
                />,
              )}
            </FormItem>
            {this.props.showInterfaces && !this.props.formData.is_wan_network && (
              <FormItem
                label={getCurrentlocaleText(
                  "network.form.interface.placeholder.text",
                )}
                hasFeedback
                {...formItemLayout}
              >
                {getFieldDecorator("attached_interfaces", {
                  rules: [
                    {
                      required: true,
                      message: getCurrentlocaleText(
                        "network.form.interface.errormessage1.text",
                      ),
                    },
                  ],
                })(
                  <Select
                    placeholder={getCurrentlocaleText(
                      "network.form.interface.placeholder.text",
                    )}
                    disabled={this.props.viewOnly || this.state.isDisabled}
                    title={getCurrentlocaleText(
                      "network.form.interface.placeholder.text",
                    )}
                    onFocus={this.updateHelp.bind(this, {
                      title: getCurrentlocaleText(
                        "network.interface.help.title",
                      ),
                      data: [
                        {
                          subtitle: "",
                          content: getCurrentlocaleText(
                            "network.interface.help.content",
                          ),
                        },
                      ],
                    })}
                  >
                    {this.renderInterfaces()}
                  </Select>,
                )}
              </FormItem>
            )}
            <FormItem
              colon={false}
              label={
                <span
                  className={"iot-label-not-required"}
                  title={getCurrentlocaleText(
                    "general.form.label.text",
                  ).replace(":", " ")}
                >
                  {getCurrentlocaleText("general.form.label.text")}
                </span>
              }
              {...formItemLayout}
            >
              {getFieldDecorator(
                "metadata",
                {},
              )(
                <LabelProvider
                  tags={this.state.tags}
                  metadata={this.state.metadata}
                  handleChange={this.handleLabelChange}
                  showPopupLabel={false}
                  isModal={true}
                  viewOnly={this.props.viewOnly}
                />,
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              title={
                this.props.action === "view" ||
                (this.props.action === "create" &&
                  this.props.UiStore.showRepNetwork) ||
                this.props.isVirtualNode
                  ? getCurrentlocaleText("network.form.disabled.help.text")
                  : null
              }
              label={
                <span
                  className={
                    this.props.formData && this.props.formData.is_wan_network
                      ? "iot-label-not-required"
                      : ""
                  }
                >
                  {getCurrentlocaleText("network.form.addressing.text")}
                </span>
              }
            >
              {getFieldDecorator("network_type", {
                initialValue: this.state.network_type,
                rules: [
                  {
                    required: !(
                      this.props.formData && this.props.formData.is_wan_network
                    ),
                    message: getCurrentlocaleText(
                      "network.form.addressing.errormessage1.text",
                    ),
                  },
                ],
              })(
                <div
                  title={
                    this.state.isDisabled
                      ? getCurrentlocaleText("network.form.disabled.help.text")
                      : null
                  }
                >
                  <RadioGroup
                    size="small"
                    value={this.state.network_type}
                    title={
                      this.state.isDisabled
                        ? getCurrentlocaleText(
                            "network.form.disabled.help.text",
                          )
                        : null
                    }
                    onChange={this.handleTypeChange}
                    disabled={
                      this.props.viewOnly || this.props.action === "edit"
                        ? true
                        : false
                    }
                  >
                    <RadioButton
                      onFocus={this.updateHelp.bind(this, {
                        title: getCurrentlocaleText(
                          "network.form.addressing.text",
                        ),
                        data: [
                          {
                            subtitle: getCurrentlocaleText(
                              "network.form.addressing.static.help.subtitle",
                            ),
                            content: getCurrentlocaleText(
                              "network.form.addressing.static.help.content",
                            ),
                          },
                        ],
                      })}
                      value={"STATIC"}
                      disabled={
                        this.state.isDisabled || this.props.action === "edit"
                          ? true
                          : this.props.cluster_id
                          ? this.props.netWorkId
                            ? true
                            : this.checkForNetworkLengthBasedOnType(
                                this.networkModel.networks,
                                this.props.node_id,
                                this.props.cluster_id,
                                "DYNAMIC",
                              ) > 0
                          : false
                      }
                    >
                      {getCurrentlocaleText(
                        "network.form.addressing.static.help.subtitle",
                      )}
                    </RadioButton>
                    <RadioButton
                      onFocus={this.updateHelp.bind(this, {
                        title: getCurrentlocaleText(
                          "network.form.addressing.text",
                        ),
                        data: [
                          {
                            subtitle: getCurrentlocaleText(
                              "network.form.addressing.dhcp.help.subtitle",
                            ),
                            content: getCurrentlocaleText(
                              "network.form.addressing.dhcp.help.content",
                            ),
                          },
                        ],
                      })}
                      disabled={
                        !showDynamic || this.props.action === "edit"
                          ? true
                          : showDynamicInCluster
                      }
                      value={"DYNAMIC"}
                    >
                      {getCurrentlocaleText(
                        "network.form.addressing.dhcp.help.subtitle",
                      ).replace(".", " ")}
                    </RadioButton>
                  </RadioGroup>
                </div>,
              )}
            </FormItem>

            {/** cidr */}
            {this.state.isStaticType && (
              <FormItem
                label={
                  <span
                    className={
                      this.props.isVirtualNode ||
                      (this.props.formData &&
                        this.props.formData.is_wan_network) ||
                      !this.state.isStaticType
                        ? "iot-label-not-required"
                        : ""
                    }
                    title={getCurrentlocaleText("network.form.cidr.text")}
                    style={{ marginRight: 2 }}
                  >
                    {getCurrentlocaleText("network.form.cidr.text")}
                  </span>
                }
                {...formItemLayout}
                hasFeedback
              >
                {getFieldDecorator("cidr", {
                  rules: [
                    {
                      required:
                        !this.props.isVirtualNode &&
                        !(
                          this.props.formData &&
                          this.props.formData.is_wan_network
                        ) &&
                        this.state.isStaticType
                          ? true
                          : false,
                      message: getCurrentlocaleText(
                        "network.form.cidr.errormessage1.text",
                      ),
                    },
                    {
                      validator:
                        !this.props.isVirtualNode &&
                        !(
                          this.props.formData &&
                          this.props.formData.is_wan_network
                        ) &&
                        this.state.isStaticType
                          ? this.checkCidr
                          : null,
                    },
                  ],
                })(
                  <CidrProvider
                    tancidr={
                      this.state.isStaticType
                        ? this.state.tanCIDR
                          ? this.state.tanCIDR
                          : this.props.formData
                          ? this.props.formData.config &&
                            this.props.formData.config.network &&
                            this.props.formData.config.network.cidr
                          : this.state.tanCIDR
                        : null
                    }
                    handleChange={this.handleCidrChange}
                    onFocus={this.updateHelp.bind(this, {
                      title: getCurrentlocaleText("network.form.cidr.text"),
                      data: [
                        {
                          subtitle: "",
                          content: getCurrentlocaleText(
                            "network.form.cidr.help.content",
                          ),
                        },
                      ],
                    })}
                    isDisabled={
                      this.props.viewOnly
                        ? true
                        : this.state.isDisabled
                        ? true
                        : this.state.isStaticType
                        ? false
                        : true
                    }
                    title={
                      this.state.isDisabled
                        ? getCurrentlocaleText(
                            "network.form.disabled.help.text",
                          )
                        : ""
                    }
                  />,
                )}
              </FormItem>
            )}
            {/* Internal reserved ip address range */}
            {this.state.isStaticType && (
              <Form.Item
                label={
                  <span
                    className={
                      this.props.isVirtualNode ||
                      (this.props.formData &&
                        this.props.formData.is_wan_network) ||
                      !this.state.isStaticType
                        ? "iot-label-not-required"
                        : ""
                    }
                    title={getCurrentlocaleText(
                      "network.form.ipblock.heading.text",
                    )}
                  >
                    {!(
                      this.props.isVirtualNode ||
                      (this.props.formData &&
                        this.props.formData.is_wan_network) ||
                      !this.state.isStaticType
                    ) && <span className="danger-validation">*</span>}
                    {getCurrentlocaleText("network.form.ipblock.heading.text")}
                  </span>
                }
                style={{ marginBottom: 0 }}
                {...formItemLayout}
              >
                <div className="d-flex">
                  {/* Start ip address */}
                  <FormItem
                    validateStatus={
                      this.state.reservedRangeErrorCodes ? "error" : "success"
                    }
                    style={{ width: "calc(50% - 15px)" }}
                  >
                    {getFieldDecorator("reserved.start", {
                      rules: [
                        {
                          required:
                            this.props.isVirtualNode ||
                            (this.props.formData &&
                              this.props.formData.is_wan_network) ||
                            !this.state.isStaticType
                              ? false
                              : true,
                          message: getCurrentlocaleText(
                            "network.form.startip.errormessage1.text",
                          ),
                        },
                        {
                          validator: !this.props.isVirtualNode
                            ? this.checkStartIpV4
                            : null,
                        },
                      ],
                      validateTrigger: "onChange",
                      trigger: "onChange",
                    })(
                      <Input
                        type="text"
                        prefix={<Icons type="ai" name="AiOutlineCalculator" />}
                        placeholder={getCurrentlocaleText(
                          "network.form.startip.text",
                        )}
                        onFocus={this.updateHelp.bind(this, {
                          title: getCurrentlocaleText(
                            "network.form.ipblock.heading.text",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "network.form.ipblock.help.content",
                              ),
                            },
                          ],
                        })}
                        disabled={
                          this.props.viewOnly
                            ? true
                            : this.state.isDisabled
                            ? true
                            : this.state.isStaticType
                            ? false
                            : true
                        }
                        title={
                          this.state.isDisabled
                            ? getCurrentlocaleText(
                                "network.form.disabled.help.text",
                              )
                            : ""
                        }
                      />,
                    )}
                  </FormItem>
                  <span
                    style={{
                      width: "24px",
                      textAlign: "center",
                    }}
                  >
                    -
                  </span>
                  {/* End ip address */}
                  <FormItem
                    hasFeedback
                    validateStatus={
                      this.state.reservedRangeErrorCodes
                        ? "error"
                        : this.props.form.getFieldValue("reserved.end")
                        ? "success"
                        : ""
                    }
                    style={{ width: "calc(50% - 15px)" }}
                  >
                    {getFieldDecorator("reserved.end", {
                      rules: [
                        {
                          required:
                            this.props.isVirtualNode ||
                            (this.props.formData &&
                              this.props.formData.is_wan_network) ||
                            !this.state.isStaticType
                              ? false
                              : true,
                          message: getCurrentlocaleText(
                            "network.form.startip.errormessage1.text",
                          ),
                        },
                        {
                          validator: !this.props.isVirtualNode
                            ? this.checkIpV4
                            : null,
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        prefix={<Icons type="ai" name="AiOutlineCalculator" />}
                        placeholder={getCurrentlocaleText(
                          "network.form.endip.text",
                        )}
                        onFocus={this.updateHelp.bind(this, {
                          title: getCurrentlocaleText(
                            "network.form.ipblock.heading.text",
                          ),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "network.form.ipblock.help.content",
                              ),
                            },
                          ],
                        })}
                        disabled={
                          this.props.viewOnly
                            ? true
                            : this.state.isDisabled
                            ? true
                            : this.state.isStaticType
                            ? false
                            : true
                        }
                        title={
                          this.state.isDisabled
                            ? getCurrentlocaleText(
                                "network.form.disabled.help.text",
                              )
                            : ""
                        }
                      />,
                    )}
                  </FormItem>
                </div>
              </Form.Item>
            )}

            {/**Tan Interface IP */}
            <FormItem
              className="tan_interface_ip"
              style={{
                marginTop: this.state.reservedRangeErrorCodes ? "24px" : "0px",
              }}
              {...formItemLayout}
              label={
                <span
                  className={
                    this.props.isVirtualNode ||
                    (this.props.formData &&
                      this.props.formData.is_wan_network) ||
                    this.state.isStaticType
                      ? "iot-label-not-required"
                      : ""
                  }
                  title={getCurrentlocaleText(
                    this.props.cluster_id
                      ? "network.form.cluster_interface.cidr.text"
                      : "network.form.interface.cidr.text",
                  )}
                  style={{ marginRight: 2 }}
                >
                  {getCurrentlocaleText(
                    this.props.cluster_id
                      ? "network.form.cluster_interface.cidr.text"
                      : "network.form.interface.cidr.text",
                  )}
                </span>
              }
              hasFeedback={
                this.state.tanInterfaceCIDR &&
                this.state.tanInterfaceCIDR.split("/")[0].length != 0
                  ? true
                  : false
              }
            >
              {getFieldDecorator("tan_interface_ip", {
                rules: [
                  {
                    required:
                      this.props.isVirtualNode ||
                      (this.props.formData &&
                        this.props.formData.is_wan_network) ||
                      this.state.isStaticType
                        ? false
                        : true,
                    message: getCurrentlocaleText(
                      "network.form.interface.cidr.errormessage1.text",
                    ),
                  },
                  {
                    validator:
                      !this.props.isVirtualNode &&
                      !(
                        this.props.formData &&
                        this.props.formData.is_wan_network
                      ) &&
                      this.checkTANCidr,
                  },
                ],
                initialValue: this.state.tanInterfaceCIDR,
              })(
                <CidrProvider
                  tancidr={
                    this.state.tanInterfaceCIDR
                      ? this.state.tanInterfaceCIDR
                      : this.props.formData
                      ? this.props.formData.config &&
                        this.props.formData.config.network &&
                        this.props.formData.config.network.tan_interface_ip
                      : null
                  }
                  reset={!this.state.tanInterfaceCIDR}
                  maskDisabled={this.state.isStaticType}
                  handleChange={this.handleTanInterfaceChange}
                  onFocus={this.updateHelp.bind(this, {
                    title: this.props.cluster_id
                      ? getCurrentlocaleText(
                          "network.form.cluster_interface.cidr.text",
                        )
                      : getCurrentlocaleText(
                          "network.form.interface.cidr.text",
                        ),
                    data: [
                      {
                        subtitle: "",
                        content: this.state.isStaticType
                          ? getCurrentlocaleText(
                              "network.form.interface.cidr.static.help.content",
                              {
                                0: this.props.cluster_id
                                  ? "master iNode"
                                  : "iNode",
                              },
                            )
                          : getCurrentlocaleText(
                              "network.form.interface.cidr.help.content",
                              {
                                0: this.props.cluster_id
                                  ? "master iNode"
                                  : "iNode",
                              },
                            ),
                      },
                    ],
                  })}
                  isDisabled={
                    this.props.viewOnly
                      ? true
                      : this.state.isDisabled
                      ? true
                      : false
                  }
                  title={
                    this.state.isDisabled
                      ? getCurrentlocaleText("network.form.disabled.help.text")
                      : ""
                  }
                />,
              )}
              {this.state.preferredIPAddressExists === true &&
                !this.state.isStaticType &&
                this.state.tanCidrChanged &&
                message.info({
                  content: <TanInfoMessage />,
                  duration: 12,
                  icon: <span></span>,
                }) &&
                this.setState({ tanCidrChanged: false })}
            </FormItem>

            {/* Default gateway field */}
            {!this.state.isVirtualNode &&
              !isMaster &&
              this.props.formData &&
              this.props.formData.is_inheritance &&
              this.props.isCandidate && (
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label={
                    <span
                      className={"iot-label-not-required"}
                      title={getCurrentlocaleText(
                        "network.form.gatewayip.text",
                      )}
                    >
                      {getCurrentlocaleText("network.form.gatewayip.text")}
                    </span>
                  }
                >
                  {getFieldDecorator(`gateway`, {
                    initialValue: this.state.gatewayIP,
                  })(
                    <Input
                      type="text"
                      prefix={<Icons type="ai" name="AiOutlineCalculator" />}
                      placeholder={getCurrentlocaleText(
                        "network.form.gatewayip.text",
                      )}
                      onFocus={this.updateHelp.bind(this, {
                        title: getCurrentlocaleText(
                          "network.form.gatewayip.text",
                        ),
                        data: [
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "network.form.gateway.help.content",
                            ),
                          },
                          {
                            subtitle: "",
                            content: getCurrentlocaleText(
                              "network.form.gateway.help_note.content",
                            ),
                          },
                        ],
                      })}
                      disabled={true}
                      title={
                        this.state.isDisabled
                          ? getCurrentlocaleText(
                              "network.form.disabled.help.text",
                            )
                          : ""
                      }
                    />,
                  )}
                </FormItem>
              )}

            {/* Virtual local area networks */}
            {(checkforFeatureEnabled(
              this.state.orgInitialVal,
              "vlan_support",
            ) ||
              this.state.isVlanEnabled) &&
              (!this.props.formData.is_wan_network ||
                this.state.isOneArmEnabled) && (
                <div>
                  {/* VLAN field */}
                  <FormItem
                    {...formItemLayout}
                    colon={false}
                    label={
                      <span
                        title={getCurrentlocaleText("network.form.vlan.text")}
                        style={{ marginRight: 2 }}
                      >
                        {getCurrentlocaleText("network.form.vlan.text")}:&nbsp;
                      </span>
                    }
                  >
                    {getFieldDecorator("vlan_enabled", {
                      rules: [
                        {
                          required: true,
                          message: `Please select an option`,
                        },
                      ],
                      initialValue: this.state.vlanVal,
                    })(
                      <RadioGroup
                        size="small"
                        onChange={this.handleVlanChange}
                        disabled={
                          !checkforFeatureEnabled(
                            this.state.orgInitialVal,
                            "vlan_support",
                          ) || this.props.viewOnly
                            ? true
                            : this.state.isDisabled &&
                              !this.props.formData.is_wan_network
                            ? true
                            : parseFloat(
                                getNodeVersion(this.inodeModel.inode),
                              ) < MultiTANBaseVersion
                            ? true
                            : false
                        }
                        title={
                          this.state.isDisabled
                            ? getCurrentlocaleText(
                                "network.form.disabled.help.text",
                              )
                            : parseFloat(
                                getNodeVersion(this.inodeModel.inode),
                              ) < MultiTANBaseVersion ||
                              !checkforFeatureEnabled(
                                this.state.orgInitialVal,
                                "vlan_support",
                              )
                            ? getCurrentlocaleText(
                                "network.form.vlan.disabled.help.text",
                              )
                            : ""
                        }
                      >
                        {this.vlanOpts()}
                      </RadioGroup>,
                    )}
                  </FormItem>
                  {/* VLAN ID field */}
                  <FormItem
                    {...formItemLayout}
                    colon={false}
                    label={
                      <span
                        className={
                          this.state.isVlanEnabled
                            ? ""
                            : "iot-label-not-required"
                        }
                        title={getCurrentlocaleText(
                          "network.form.vlan.id.text",
                        )}
                      >
                        {getCurrentlocaleText("network.form.vlan.id.text")}
                        :&nbsp;
                      </span>
                    }
                    hasFeedback
                  >
                    {getFieldDecorator("vlan_id", {
                      rules: [
                        {
                          required: this.state.isVlanEnabled,
                          message: getCurrentlocaleText(
                            "network.form.vlan.errormessage1.text",
                          ),
                        },
                        {
                          validator: !this.props.isVirtualNode
                            ? this.checkVlan
                            : null,
                        },
                      ],
                      initialValue: this.state.vlanId,
                    })(
                      <Input
                        type="number"
                        placeholder={getCurrentlocaleText(
                          "network.form.vlan.id.text",
                        )}
                        onFocus={this.updateHelp.bind(this, {
                          title: getCurrentlocaleText("network.form.vlan.text"),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "network.form.vlan.id.help.content",
                              ),
                            },
                          ],
                        })}
                        disabled={
                          !checkforFeatureEnabled(
                            this.state.orgInitialVal,
                            "vlan_support",
                          ) ||
                          !this.state.isVlanEnabled ||
                          this.props.viewOnly
                            ? true
                            : this.state.isDisabled &&
                              !this.props.formData.is_wan_network
                            ? true
                            : parseFloat(
                                getNodeVersion(this.inodeModel.inode),
                              ) < MultiTANBaseVersion
                            ? true
                            : false
                        }
                        title={
                          this.state.isDisabled
                            ? getCurrentlocaleText(
                                "network.form.disabled.help.text",
                              )
                            : parseFloat(
                                getNodeVersion(this.inodeModel.inode),
                              ) < MultiTANBaseVersion ||
                              !checkforFeatureEnabled(
                                this.state.orgInitialVal,
                                "vlan_support",
                              )
                            ? getCurrentlocaleText(
                                "network.form.vlan.disabled.help.text",
                              )
                            : ""
                        }
                      />,
                    )}
                  </FormItem>
                </div>
              )}
            {/* Default destination field */}
            {!this.props.formData.is_wan_network &&
              (!this.state.isVirtualNode || this.props.cluster_id) && (
                <FormItem
                  label={
                    <span
                      className={"iot-label-not-required"}
                      title={getCurrentlocaleText("default_destination_text")}
                    >
                      {getCurrentlocaleText("default_destination_text")}
                    </span>
                  }
                  {...formItemLayout}
                >
                  {getFieldDecorator("routes", {
                    rules: [{ required: false }],
                  })(
                    <DefaultRoute
                      availableNetwork={this.props.availableNetwork}
                      selectedVnode={this.state.selectedVnode}
                      setRoutes={this.setRoutes}
                      tanCIDR={
                        this.state.isStaticType
                          ? this.state.tanCIDR
                            ? this.state.tanCIDR
                            : this.props.formData
                            ? this.props.formData.config &&
                              this.props.formData.config.network &&
                              this.props.formData.config.network.cidr
                            : this.state.tanCIDR
                          : this.state.tanInterfaceCIDR
                          ? this.state.tanInterfaceCIDR
                          : this.props.formData
                          ? this.props.formData.config &&
                            this.props.formData.config.network &&
                            this.props.formData.config.network.tan_interface_ip
                          : this.state.tanInterfaceCIDR
                      }
                      cidrRangeInfo={
                        this.state.isStaticType
                          ? this.state.cidrRangeInfo
                          : this.state.tanCidrRangeInfo
                      }
                      viewOnly={this.props.viewOnly ? true : false}
                      {...this.props}
                      cluster_id={this.props.cluster_id}
                    />,
                  )}
                </FormItem>
              )}

            {!this.props.formData.is_wan_network &&
              !this.props.formData.is_default_network &&
              showITR && (
                <>
                  <FormItem
                    label={
                      <span
                        className={"iot-label-not-required"}
                        title={getCurrentlocaleText("allow.tan.network")}
                      >
                        {getCurrentlocaleText("allow.tan.network")}
                      </span>
                    }
                    {...formItemLayout}
                  >
                    {getFieldDecorator("enable_inter_tan", {
                      rules: [{ required: false }],
                    })(
                      <Switch
                        checked={this.state.enableInterTan}
                        onChange={() => {
                          this.setState({
                            enableInterTan: !this.state.enableInterTan,
                          });
                        }}
                        disabled={
                          this.state.initialServiceAddressVal == "MANUAL" ||
                          disableItr ||
                          this.props.viewOnly
                            ? true
                            : false
                        }
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />,
                    )}
                  </FormItem>
                  {this.state.itrLinkAddress && (
                    <Form.Item
                      label={
                        <span
                          className={"iot-label-not-required"}
                          title={getCurrentlocaleText(
                            "allow.tan.network.address",
                          )}
                        >
                          {getCurrentlocaleText("allow.tan.network.address")}
                        </span>
                      }
                      {...formItemLayout}
                    >
                      <span className="ant-form-text">
                        {this.state.itrLinkAddress}
                      </span>
                    </Form.Item>
                  )}
                </>
              )}

            {this.props.clusterDetails &&
              this.props.clusterDetails.config &&
              !this.props.formData.is_wan_network &&
              this.state.network_type == "STATIC" &&
              this.props.cluster_id &&
              this.props.clusterDetails.config.election_network_type ==
                "TAN" && (
                <FormItem
                  label={
                    <span
                      className={"iot-label-not-required"}
                      title={getCurrentlocaleText("allow.vrrp.network")}
                    >
                      {getCurrentlocaleText("allow.vrrp.network")}
                      <PopOverHelp
                        isHelpOnForm={true}
                        height={80}
                        width={335}
                        placement="right"
                        helpData={{
                          title: getCurrentlocaleText("allow.vrrp.network"),
                          data: [
                            {
                              subtitle: "",
                              content: getCurrentlocaleText(
                                "allow.vrrp.network.help",
                              ),
                            },
                          ],
                        }}
                      />
                    </span>
                  }
                  {...formItemLayout}
                >
                  {getFieldDecorator("enable_vrrp_tan", {
                    rules: [{ required: false }],
                    initialValue: this.state.enableVrrpTan,
                  })(
                    <Switch
                      checked={this.state.enableVrrpTan}
                      onChange={() => {
                        this.setState({
                          enableVrrpTan: !this.state.enableVrrpTan,
                        });
                      }}
                      disabled={
                        this.state.hideVrrpselect || this.props.viewOnly
                          ? true
                          : false
                      }
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />,
                  )}
                </FormItem>
              )}
          </Col>
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
          >
            <HelpCard
              customStyle={{
                borderLeft: "none",
              }}
              span={24}
            />
          </Col>
        </Row>
        <Row gutter={2} type="flex" align="top" style={{ paddingTop: 15 }}>
          <Col span={24}>
            {this.props.AuthStore.IsPermitted("NETWORK:CONNECT_DISCONNECT") &&
              (!(this.props.formData && this.props.formData.is_wan_network) ||
                isUCGNode) &&
              (!this.props.formData.is_inheritance ||
                (this.props.formData.is_inheritance &&
                  this.props.isCandidate)) && (
                <FormItem>
                  {getFieldDecorator("vNode", {
                    rules: [{ required: false }],
                  })(
                    <NetworkMulticonnect
                      isForForm={true}
                      tanCIDR={
                        this.state.tanCIDR
                          ? this.state.tanCIDR
                          : this.props.formData &&
                            this.props.formData.config &&
                            this.props.formData.config.network
                          ? this.props.formData.config.network.cidr
                          : this.props.cluster_id
                          ? this.state.tanInterfaceCIDR
                            ? this.state.tanInterfaceCIDR
                            : null
                          : null
                      }
                      tanInterfaceIP={
                        this.props.formData &&
                        this.props.formData.config &&
                        this.props.formData.config.network &&
                        this.props.formData.config.network.tan_interface_ip
                      }
                      isVirtualNode={this.state.isVirtualNode}
                      setVnode={this.setVnode}
                      currentNetwork={this.props.formData}
                      availableNetwork={this.props.availableNetwork}
                      loadingState={this.checkLoadingstate}
                      setAvailableInodes={this.pushAvailableInodes}
                      {...this.props}
                      firewallLables={this.state.firewallLabel_options}
                      allowFirewall={this.state.allowFirewall}
                      isCollapsible={true}
                      viewOnly={this.props.viewOnly || isUCGNode}
                      currentNode={this.inodeModel.inode}
                      isCidrExist={
                        !this.state.isStaticType && !this.state.isCidrExist
                          ? !this.state.isTanCidrExist
                            ? false
                            : true
                          : true
                      }
                      inodeVersion={parseFloat(
                        getNodeVersion(this.inodeModel.inode),
                      )}
                      cluster_id={this.props.cluster_id}
                    />,
                  )}
                </FormItem>
              )}

            <Collapse className="security-collapse">
              <Panel
                key={"1"}
                className={"custom-panel"}
                header={
                  <div className="panel-header-text">
                    {this.FirewallGroupViewModel.loading
                      ? getCurrentlocaleText("loading.text")
                      : getCurrentlocaleText("security_config.text")}
                  </div>
                }
                disabled={this.FirewallGroupViewModel.loading ? true : false}
              >
                <Row>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 13 }}
                    lg={{ span: 13 }}
                    xl={{ span: 12 }}
                  >
                    {!(
                      this.props.formData && this.props.formData.is_wan_network
                    ) && (
                      <FormItem
                        label={
                          <span
                            className={"iot-label-not-required"}
                            title={getCurrentlocaleText(
                              "networks.hairpinning.label.text",
                            )}
                          >
                            {getCurrentlocaleText(
                              "networks.hairpinning.label.text",
                            )}
                          </span>
                        }
                        {...formItemLayout}
                      >
                        {getFieldDecorator("hairpinning", {
                          rules: [{ required: false }],
                        })(
                          <RadioGroup
                            onChange={this.handleHairpinChange}
                            disabled={
                              this.props.viewOnly || this.props.cluster_id
                                ? true
                                : !this.state.allowFirewall
                                ? true
                                : false
                            }
                            size="small"
                          >
                            <RadioButton
                              value="hairpin-allow"
                              onFocus={this.updateHelp.bind(this, {
                                title: getCurrentlocaleText(
                                  "networks.hairpinning.label.text",
                                ),
                                data: [
                                  {
                                    subtitle: "",
                                    content: getCurrentlocaleText(
                                      "networks.hairpin.help.message.text",
                                    ),
                                  },
                                ],
                              })}
                              checked={false}
                            >
                              {getCurrentlocaleText(
                                "networks.hairpinning.allow.label.text",
                              )}
                            </RadioButton>
                            <RadioButton
                              checked={true}
                              onFocus={this.updateHelp.bind(this, {
                                title: getCurrentlocaleText(
                                  "networks.hairpinning.label.text",
                                ),
                                data: [
                                  {
                                    subtitle: "",
                                    content: getCurrentlocaleText(
                                      "networks.hairpin.help.message.text",
                                    ),
                                  },
                                ],
                              })}
                              value="hairpin-deny"
                            >
                              {getCurrentlocaleText(
                                "networks.hairpinning.deny.label.text",
                              )}
                            </RadioButton>
                          </RadioGroup>,
                        )}
                      </FormItem>
                    )}
                    <FormItem
                      label={
                        <span
                          className={"iot-label-not-required"}
                          title={getCurrentlocaleText(
                            "networks.network_security.label.text",
                          )}
                        >
                          {getCurrentlocaleText(
                            "networks.network_security.label.text",
                          )}
                        </span>
                      }
                      hasFeedback
                      {...formItemLayout}
                      validateStatus={this.state.firewall_validate_state}
                      help={this.state.validateMessage}
                    >
                      {getFieldDecorator("firewall_selector", {
                        rules: [
                          { required: false },
                          {
                            validator: !this.props.isVirtualNode
                              ? this.checkForPolicy
                              : this.checkForPolicy,
                          },
                        ],
                      })(
                        <Select
                          mode="tags"
                          style={{ width: "100%" }}
                          disabled={
                            this.props.viewOnly
                              ? true
                              : !(
                                  this.props.formData &&
                                  this.props.formData.is_wan_network
                                )
                              ? !this.state.allowFirewall
                                ? true
                                : false
                              : false
                          }
                          onFocus={this.updateHelp.bind(this, {
                            title: getCurrentlocaleText(
                              "network.security.policy.help.title",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "network.security.policy.help.message",
                                ),
                              },
                            ],
                          })}
                          placeholder={getCurrentlocaleText(
                            "network.form.custom.security.policy.placeholder.text",
                          )}
                          onChange={this.handleChange}
                          filterOption={(input, option) =>
                            option.props.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onDeselect={this.handleOnDeselect}
                          notFoundContent={getCurrentlocaleText(
                            "general.notfound.placeholder.text",
                          )}
                        >
                          {children}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem
                      label={
                        <span
                          onClick={this.updateHelp.bind(this, {
                            title: getCurrentlocaleText(
                              "networks.edit.security.debug.help_title.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "networks.edit.security.debug.help.message.text",
                                ),
                              },
                            ],
                          })}
                          className={"iot-label-not-required"}
                          title={getCurrentlocaleText(
                            "networks.edit.security.debug.label.text",
                          )}
                        >
                          {getCurrentlocaleText(
                            "networks.edit.security.debug.label.text",
                          )}
                        </span>
                      }
                      {...formItemLayout}
                    >
                      {getFieldDecorator(
                        "firewall_debugger",
                        {},
                      )(
                        <Switch
                          checked={this.state.enableSecurityPolicyDebugger}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          onChange={this.firewallDebugger}
                          disabled={
                            this.props.viewOnly
                              ? true
                              : !this.state.allowFirewall
                              ? true
                              : false
                          }
                          onFocus={this.updateHelp.bind(this, {
                            title: getCurrentlocaleText(
                              "networks.edit.security.debug.help_title.text",
                            ),
                            data: [
                              {
                                subtitle: "",
                                content: getCurrentlocaleText(
                                  "networks.edit.security.debug.help.message.text",
                                ),
                              },
                            ],
                          })}
                        />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {this.props.formData &&
                      this.props.formData.status &&
                      this.props.formData.status.firewall_status &&
                      this.props.formData.status.firewall_status.rules &&
                      this.props.formData.status.firewall_status.rules.length >
                        0 &&
                      this.state.allowFirewall && (
                        <span onClick={this.handleFW}>
                          <a>
                            <Icons
                              style={{ marginRight: 5 }}
                              type="fa"
                              name="FaListAlt"
                            />
                            <u>
                              {getCurrentlocaleText(
                                "network.security_policy.show_link.text",
                              )}
                              <Icons
                                type="fa"
                                style={{
                                  fontSize: 14,
                                  marginLeft: 5,
                                  cursor: "pointer",
                                  marginTop: 7,
                                  marginRight: -23,
                                }}
                                name={
                                  this.state.showRules
                                    ? "FaChevronCircleDown"
                                    : "FaChevronCircleRight"
                                }
                              />
                            </u>
                          </a>
                        </span>
                      )}
                    {this.state.showRules && (
                      <WanFirewall
                        {...this.props}
                        firewallResource="firewallgroup"
                        orgId={this.props.orgId}
                        FirewallStatus={this.props.formData}
                        availableNetwork={this.props.availableNetwork}
                        orgsNetwork={this.props.availableNetwork}
                        NetworkID={this.props.netWorkId}
                        isCollapsible={true}
                        nodeState={
                          this.inodeModel.inode
                            ? this.inodeModel.inode.node_state
                            : null
                        }
                        debugEnabled={
                          this.props.formData &&
                          this.props.formData.policy &&
                          this.props.formData.policy.firewall &&
                          this.props.formData.policy.firewall.debug
                            ? this.props.formData.policy.firewall.debug
                            : false
                        }
                        cluster_id={this.props.cluster_id}
                        viewOnly={this.props.viewOnly ? true : false}
                      />
                    )}
                  </Col>
                </Row>
              </Panel>
            </Collapse>

            {/* service collpase */}
            {this.props.formData && !this.props.formData.is_wan_network && (
              <Collapse>
                <Panel
                  header={
                    <div className="panel-header-text">
                      {getCurrentlocaleText(
                        "node.list.table.node_services.label.text",
                      )}
                    </div>
                  }
                  disabled={!this.state.isStaticType}
                  key="1"
                >
                  <Row>
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 13 }}
                      lg={{ span: 13 }}
                      xl={{ span: 12 }}
                    >
                      <FormItem
                        {...formItemLayout}
                        label={
                          <span
                            className={"iot-label-not-required"}
                            title={getCurrentlocaleText(
                              "network.page.service.addressing",
                            )}
                          >
                            {getCurrentlocaleText(
                              "network.page.service.addressing",
                            )}
                          </span>
                        }
                      >
                        {getFieldDecorator(`service_addressing`, {
                          rules: [
                            {
                              required: false,
                              message: `Please select an option`,
                            },
                          ],
                          initialValue: this.state.initialServiceAddressVal,
                        })(
                          <RadioGroup
                            size="small"
                            onChange={val => {
                              this.setState({
                                initialServiceAddressVal: val.target.value,
                              });
                            }}
                            disabled={
                              this.props.viewOnly || this.state.enableInterTan
                                ? true
                                : this.props.action !== "create" ||
                                  !this.state.isStaticType
                            }
                          >
                            {this.serviceAddressingOpts()}
                          </RadioGroup>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            )}
            {/* Additional Static Routes */}
            {showStaticRoutes && (
              <Row style={{ marginTop: 24 }}>
                <Col span={24}>
                  <FormItem>
                    {getFieldDecorator("static_routes", {
                      rules: [{ required: false }],
                    })(
                      <RouteConfig
                        {...this.props}
                        availableNetwork={this.props.availableNetwork}
                        selectedVnode={this.state.selectedVnode}
                        setRoutes={this.setStaticRoutes}
                        tanCIDR={
                          this.state.isStaticType
                            ? this.state.tanCIDR
                              ? this.state.tanCIDR
                              : this.props.formData &&
                                this.props.formData.config &&
                                this.props.formData.config.network
                              ? this.props.formData.config.network.cidr
                              : null
                            : this.state.tanInterfaceCIDR
                            ? this.state.tanInterfaceCIDR
                            : this.props.formData &&
                              this.props.formData.config &&
                              this.props.formData.config.network
                            ? this.props.formData.config.network
                                .tan_interface_ip
                            : null
                        }
                        enableInterTan={this.state.enableInterTan}
                        cidrRangeInfo={
                          this.state.isStaticType
                            ? this.state.cidrRangeInfo
                            : this.state.tanCidrRangeInfo
                        }
                        viewOnly={this.props.viewOnly}
                        isVirtualRoutesAllowed={
                          parseFloat(getNodeVersion(this.inodeModel.inode)) >=
                            VirtualRoutesBaseVersion || this.props.cluster_id
                            ? true
                            : false
                        }
                        isVirtualNode={this.state.isVirtualNode}
                        cluster_id={this.props.cluster_id}
                        showInterTan={
                          !this.props.formData.is_wan_network &&
                          !this.props.formData.is_default_network &&
                          showITR
                        }
                        availableInodes={this.state.availableInodes}
                      />,
                    )}
                  </FormItem>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {/* <Button className="hidden-submit" htmlType="submit" /> */}
        <Row>
          <Col span={24} className="col-center">
            <div style={{ paddingTop: 15 }}>
              <Button
                onClick={this.props.onCancel}
                style={{ marginRight: 10 }}
                key="cancel"
              >
                {getCurrentlocaleText("network.form.button.cancel.text")}
              </Button>
              {!this.props.viewOnly && (
                <Button
                  type="primary"
                  onSubmit={this.submitForm}
                  onClick={this.submitForm}
                  loading={
                    this.props.isloading ||
                    (!(
                      this.props.formData && this.props.formData.is_wan_network
                    ) &&
                      this.state.RemoteNetworkloading)
                  }
                  disabled={this.FirewallGroupViewModel.loading}
                >
                  <Icons type="ai" name="AiOutlineSave" style={{ margin: 0 }} />
                  {!this.props.isNewnetwork
                    ? getCurrentlocaleText("network.form.button.update.text")
                    : getCurrentlocaleText("network.form.button.submit.text")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {this.state.showHairpinConfirm && (
          <NetworkHairpinConfirmDialog
            onOk={this.confirmHairpinChange}
            currentState={this.state.currentHairpinState}
            isVisible={this.state.showHairpinConfirm}
            onCancel={this.cancelHairpinChange}
          />
        )}

        <Modal
          title={<span>{getCurrentlocaleText("save.changes")}</span>}
          visible={this.state.showCirdUpdateWarning}
          onOk={this.validateForm}
          onCancel={this.hidewarningModal}
          closable={false}
          maskClosable={false}
          footer={[
            <Button key="back" onClick={this.hidewarningModal}>
              {getCurrentlocaleText("network.cidr.warning.popup.cancel.btn")}
            </Button>,
            <Button key="submit" type="primary" onClick={this.validateForm}>
              {getCurrentlocaleText("network.cidr.warning.popup.ok.btn")}
            </Button>,
          ]}
        >
          <LoadingComponent loading={this.state.loading_services}>
            {<div>{this.cidrEditwarningContent()}</div>}
          </LoadingComponent>
        </Modal>
      </Form>
    );
  }
}
const NetworksFormPage = Form.create()(NetworkFormPage);
export default NetworksFormPage;
