import React, { Component } from "react";
import { Modal } from "antd";
import { getCurrentlocaleText } from "../../Core/Utils";
import EdgeGateway5100 from "../../assets/img/hardwares/Edge_Gateway_5100.png";
import EdgeGateway5000 from "../../assets/img/hardwares/Edge_Gateway_5000.png";
import NCA_1510 from "../../assets/img/hardwares/NCA_1510.png";
import R240 from "../../assets/img/hardwares/R240.png";
import V2_2271G from "../../assets/img/hardwares/2271G_V2.png";
import V2_2484G from "../../assets/img/hardwares/2484G_V2.png";
import V1_2484G from "../../assets/img/hardwares/2484G_V1.png";

class InterfaceDiagram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: this.props.visibility,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        visibility: this.props.visibility,
      });
    }
  }

  onClose = () => {
    this.props.onClose();
  };

  getImage = () => {
    let vendorDetails = this.props.vendorDetails;
    if (vendorDetails) {
      let details = vendorDetails.split("/");
      if (details && details.length > 0) {
        let model = details[1];
        let convertedModel = model.replace(" ", "_");
        switch (convertedModel) {
          case "Dell_Gateway_5100":
            return EdgeGateway5100;
          case "Dell_Gateway_5000":
            return EdgeGateway5000;
          case "PUB1245-100^LANNER-CSP1":
          case "PUB5421-010^LANNER-CSP1":
            return NCA_1510;
          case "PowerEdge_R240":
            return R240;
          case "UNO-2271G_V2":
            return V2_2271G;
          case "UNO-2484G-734xAE":
            return V1_2484G;
          case "UNO-2484G_V2":
            return V2_2484G;
        }
      }
    }

    return EdgeGateway5100;
  };

  render() {
    return (
      <Modal
        title={
          <div>
            <span>{getCurrentlocaleText("inode.hardware.diagram")}</span>
            <br />
            <span>{this.props.vendorDetails}</span>
          </div>
        }
        visible={this.state.visibility}
        maskClosable={false}
        footer={null}
        onCancel={this.onClose}
        width={800}
      >
        <img src={this.getImage()} style={{ width: "100%", height: "auto" }} />
      </Modal>
    );
  }
}

export default InterfaceDiagram;
