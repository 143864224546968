import React, { Component } from "react";
import { computed } from "mobx";
import { observer, inject } from "mobx-react";
import { Row, Col, Table, Menu, Tag, Button, Typography } from "antd";

import ActionBtn from "Core/API/ActionBtn";
import NetworksForm from "components/Forms/NetworksFormPage";
import DiscoveryForm from "components/Forms/DiscoveryForm";
import { ConnectedNodes } from "components/INodes";
import {
  getCurrentlocaleText,
  checkforFeatureEnabled,
  getNodeVersion,
  MultiTANBaseVersion,
  isEmpty,
  nwDeviceDiscoveryMaxVersion,
} from "Core/Utils";
import LoadingComponent from "components/UI-Components/LoadingComponent";

import InodeController from "controller/InodeController";
import ProfileController from "controller/ProfileController";
import NetworkController from "controller/NetworkController";
import ActionRoute from "../../Core/API/ActionRoute";
import NetworkCreateAlert from "components/Forms/Components/Network/NetworkCreateAlert";
import NetworkMulticonnect from "../Forms/Components/Network/NetworkMulticonnect";
import LabelProvider from "components/UI-Components/LabelProvider";
import OrgController from "controller/OrgController";

import DropdownActionBtn from "components/UI-Components/DropdownActionBtn";
import Icons from "../UI-Components/Icons";
import RepNetHierarchy from "components/DataLists/RepNetHierarchy";

const { Column, ColumnGroup } = Table;
const { Paragraph } = Typography;

@inject(
  "ModelStore",
  "UiStore",
  "AuthStore",
  "InodeViewModel",
  "NetworkViewModel",
  "ServiceViewModel",
  "ClusterViewModel",
)
@observer
class NetworksList extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.serviceModel = this.props.ServiceViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.state = {
      currentClusterDetails: this.props.currentClusterDetails
        ? this.props.currentClusterDetails
        : {},
      node_id: this.props.params.node_id
        ? this.props.params.node_id
        : this.props.params.cluster_id,
      showModal: false,
      disconnectDetails: {},
      showDiscovery: true,
      isVirtualNode: false,
      isNewNode: true,
      network_id: null,
      showEditNetworkConfirm: false,
      editNetworkFormData: {},
      type: "primary",
      expandedRowKeys: [],
      showRemoteInodelist: false,
      showRemoteInodelistview: false,
      loading: false,
      forceClose: false,
      isvalidrepCidr: false,
      autoOpen: false,
      showAlertBox: false,
      hideNow: false,
      orgInitialVal: {},
      isMultiConnectViewLoading: false,
      selectedNetwork: [],
      currentNode: {},
      isUCGNode: false,
    };
    this.networkBtn = null;
    this.store = this.props.ModelStore;
    this.network = {
      interface: "eth1",
      node_id: this.props.params.node_id
        ? this.props.params.node_id
        : this.props.params.cluster_id,
    };
    this.networkDataLoaded = false;
  }

  UNSAFE_componentWillMount() {
    if (!this.props.cluster_id) {
      let orgId = null;
      InodeController.getInode(this.props.params.node_id).then(res => {
        this.setState({ currentNode: res });
        orgId = res.organization.id;
        // Getting profiles for network multi connect.
        ProfileController.getProfiles({ org_id: orgId, own: true }).then(
          resp => {
            // Filtering single profile details
            ProfileController.getProfile(res.profile.id).then(
              profileResponse => {
                // Do not show discovery button for virtual iNode.
                this.setState({
                  showDiscovery: !profileResponse.config.vpn_server,
                  isVirtualNode: profileResponse.config.vpn_server,
                });
              },
            );
          },
        );
        if (!res.networks || (res.networks && res.networks.length <= 1)) {
          this.setState({ autoOpen: true });
        }
        if (
          res.status &&
          res.status.node &&
          res.status.node.last_contacted_at
        ) {
          this.setState({ isNewNode: res.status.node.last_contacted_at });
        }
        if (
          res.profile &&
          res.profile.name &&
          res.profile.name === "Unified Cloud Gateway"
        ) {
          this.setState({ isUCGNode: true });
        }
        OrgController.getOrgById(orgId, true).then(response => {
          if (response) {
            this.setState({ orgInitialVal: response });
          }
        });
      });
    } else {
      let orgId =
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id;
      OrgController.getOrgById(orgId, true).then(response => {
        if (response) {
          this.setState({ orgInitialVal: response });
        }
      });
      this.setState({ autoOpen: true });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.tabIndex != "networks") {
      this.setState({ showRemoteInodelistview: false });
    }
    if (props.isNetworkDataLoaded !== this.networkDataLoaded) {
      this.defaultExpandRows();
      this.networkDataLoaded = props.isNetworkDataLoaded;
      this.isAlertShowed();
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (
      previousProps.currentClusterDetails !== this.props.currentClusterDetails
    ) {
      this.setState({
        currentClusterDetails: this.props.currentClusterDetails,
      });
    }
  }

  //hidePopup
  hidePopup = () => {
    this.setState({ hideNow: true });
  };

  // check for pop up showed or not
  isAlertShowed = () => {
    if (localStorage.IsCreateNetworkPopShowed) {
      let showediNodes = JSON.parse(localStorage.IsCreateNetworkPopShowed);
      if (showediNodes.indexOf(this.state.node_id) === -1)
        this.setState({ showAlertBox: true });
      else this.setState({ showAlertBox: false });
    } else if (
      this.checkForNetworkLength(
        this.networkModel.networks,
        this.props.params.node_id,
        this.props.params.cluster_id,
      ) === 1
    ) {
      this.setState({ showAlertBox: true });
    }
  };
  defaultExpandRows = () => {
    this.setExpandedRowKeys(
      this.networkModel.networks.reduce((keys, n) => {
        // LAT-7562 default expanded if this is the only tan on the inode
        if (!this.props.cluster_id && n.node) {
          if (
            (!n.is_wan_network || this.state.isUCGNode) &&
            this.checkForNetworkLength(
              this.networkModel.networks,
              n.node.id,
            ) === 2 &&
            n.node.id === this.props.params.node_id
          )
            keys.push(n.id);
        } else {
          if (
            (!n.is_wan_network || this.state.isUCGNode) &&
            this.checkForNetworkLength(
              this.networkModel.networks,
              null,
              n.cluster && n.cluster.id,
            ) === 2 &&
            n.cluster.id === this.props.cluster_id
          )
            keys.push(n.id);
        }
        return keys;
      }, []),
    );
  };

  checkForNodeReboot = () => {
    // reboot node
    InodeController.reboot([this.props.params.node_id])
      .then(response => {
        this.closeModal("networks");
      })
      .catch(error => {
        console.log("Error in reboot.");
      });
    this.setState({
      showEditNetworkConfirm: false,
      editNetworkFormData: {},
    });
  };
  updateWanNetwork = (values, network_id) => {
    NetworkController.update(
      values,
      { id: network_id },
      {},
      true,
    ).then(response => {});
  };

  deleteNetworkStatus = record_id => {
    let bound_network =
      this.state.currentClusterDetails &&
      this.state.currentClusterDetails.config &&
      this.state.currentClusterDetails.config.election_network_id
        ? this.state.currentClusterDetails.config.election_network_id
        : null;

    if (
      bound_network === record_id &&
      this.checkForNetworkLength(
        this.networkModel.networks,
        this.props.params.node_id,
        this.props.params.cluster_id,
      ) > 2
    ) {
      return true;
    }
  };

  isTanSwitchInprogress = record_id => {
    let tanSwitchInProgress = false;
    if (
      this.state.currentClusterDetails &&
      this.state.currentClusterDetails.status &&
      this.state.currentClusterDetails.status.nodes &&
      this.state.currentClusterDetails.status.nodes.length > 0
    ) {
      this.state.currentClusterDetails.status.nodes.forEach(node => {
        if (
          node &&
          node.election_network_id &&
          node.election_network_id === record_id
        ) {
          tanSwitchInProgress = true;
        }
      });
    }
    return tanSwitchInProgress;
  };

  getTagKeyValues = labels => {
    let tags = [];
    if (labels.name) {
      delete labels.name;
    }
    for (let key in labels) {
      if (key && key.charAt(0) != "_") tags.push(key + " : " + labels[key]);
    }
    return tags;
  };
  closeModal = resource => {
    this.setState({ loading: false });
    this.props.UiStore.closeFormModal(resource);
  };

  setVnode = value => {
    this.setState({ selectedVnode: value });
  };

  @computed
  get NetworksTable() {
    let extraRow = record => {
      let macAddress =
        record &&
        record.status &&
        record.status.interface_status &&
        record.status.interface_status.mac_address
          ? record.status.interface_status.mac_address
          : record.mac_address;
      return (
        <div>
          {!this.state.isVirtualNode &&
            (!record.is_wan_network || this.state.isUCGNode) &&
            record.config.network.reserved &&
            record.config.network.reserved[0] &&
            record.config.network.reserved[0].start &&
            record.config.network.reserved[0].end && (
              <div className="pb-5">
                <div>
                  <Row>
                    <Col span={6}>
                      <strong>
                        {getCurrentlocaleText(
                          "network.form.ipblock.heading.text",
                        )}
                      </strong>
                    </Col>
                    <Col span={1}>
                      <strong>{":"}</strong>
                    </Col>
                    <Col>
                      <span>{record.config.network.reserved[0].start}</span>
                      <span style={{ padding: "0 5px 0 5px" }}>&#8211;</span>
                      <span>{record.config.network.reserved[0].end}</span>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          {
            <Row className="pb-5">
              <Col span={6}>
                <strong>
                  {record.cluster
                    ? getCurrentlocaleText(
                        "network.form.cluster_interface.cidr.text",
                      )
                    : getCurrentlocaleText("network.form.interface.cidr.text")}
                </strong>
              </Col>
              <Col span={1}>
                <strong>{":"}</strong>
              </Col>
              <Col>
                {record &&
                record.config &&
                record.config.network &&
                record.config.network.tan_interface_ip
                  ? record.config.network.tan_interface_ip
                  : "-"}
              </Col>
            </Row>
          }
          {record &&
            record.config &&
            record.config.network &&
            record.config.network.gateway && (
              <Row className="pb-5">
                <Col span={6}>
                  <strong>
                    {getCurrentlocaleText("network.form.gatewayip.text")}
                  </strong>
                </Col>
                <Col span={1}>
                  <strong>{":"}</strong>
                </Col>
                <Col>{record.config.network.gateway}</Col>
              </Row>
            )}
          {record && macAddress && !record.is_wan_network && (
            <Row className="align-items-center">
              <Col span={6}>
                <strong>{getCurrentlocaleText("mac-address.text")}</strong>
              </Col>
              <Col span={1}>
                <strong>{":"}</strong>
              </Col>
              <Col>
                <Paragraph copyable ellipsis={true}>
                  {macAddress}
                </Paragraph>
              </Col>
            </Row>
          )}
          <Row className="pb-5" />
          <LoadingComponent
            loading={
              !this.inodeModel.loading &&
              !this.networkModel.loading &&
              this.state.isMultiConnectViewLoading
                ? true
                : false
            }
          >
            {(!record.is_inheritance ||
              (record.is_inheritance && this.props.isCandidate)) && (
              <NetworkMulticonnect
                viewOnly={true}
                netWorkId={record.id}
                tanCIDR={record.config.network.cidr}
                tanInterfaceIP={record.config.network.tan_interface_ip}
                isVirtualNode={this.state.isVirtualNode}
                setVnode={this.setVnode}
                {...this.props}
                currentNetwork={record}
                availableNetwork={this.props.availableNetworks}
                loadingState={loading => {
                  this.setState({ isMultiConnectViewLoading: loading });
                }}
                currentNode={this.inodeModel.inode}
                inodeVersion={parseFloat(getNodeVersion(this.inodeModel.inode))}
              />
            )}
          </LoadingComponent>
        </div>
      );
    };

    let altered = [];
    altered = this.networkModel.networks
      .filter(value => {
        if (!this.props.cluster_id) {
          if (value && value.node)
            return value.node.id === this.props.params.node_id;
        } else {
          if (
            value &&
            value.cluster &&
            !value.is_inheritance &&
            !this.props.inheritance
          )
            return value.cluster.id === this.props.cluster_id;
        }
      })
      .map(val => {
        let discoveryState = false;
        if (val.policy && val.policy.discovery && val.policy.discovery.enable) {
          discoveryState = true;
        }
        return {
          id: val.id,
          key: val.id,
          name: val.name,
          interfaces:
            val.attached_to && val.attached_to.interfaces
              ? val.attached_to.interfaces.join(",")
              : "-",
          networkAddressing: val.type,
          cidr: val.config.network.cidr,
          vlan_id: val.config.network.vlan_id,
          connected_networks: val.connected_networks,
          remoteInode: "-",
          discoveryState: discoveryState,
          isDeleted: val.deleted_at ? true : false,
          extraRow: extraRow(val),
          is_wan_network: val.is_wan_network ? val.is_wan_network : false,
          firewall_selector: val.firewall_selector
            ? val.firewall_selector.match_label
            : false,
          metadata: (val.metadata && val.metadata.labels) || {},
          isCandidate: this.props.isCandidate,
          is_inheritance: val.is_inheritance
            ? true
            : this.props.current_inode_cluster
            ? true
            : false,
        };
      });
    return altered;
  }

  getINode = nodeId => {
    let network = null;
    this.networkModel.networks.forEach(n => {
      if (n.id === nodeId) {
        network = n;
      }
    });
    return network;
  };

  @computed
  get servicesHavePreferredIP() {
    if (this.serviceModel.services && this.serviceModel.services.length > 0) {
      return this.serviceModel.services
        .filter((value, i) => {
          if (
            value &&
            value.spec &&
            value.spec.networks &&
            value.spec.networks[0].ip_address
          ) {
            return true;
          }
        })
        .map((value, i) => value.name);
    }
    return null;
  }

  expandRow = (record, event) => {
    if (!record.is_wan_network || this.state.isUCGNode) {
      let keys = this.state.expandedRowKeys;
      let index = keys.findIndex(val => {
        return val === record.id;
      });
      if (index === -1) {
        keys.push(record.id);
      } else {
        keys.splice(index, 1);
      }
      this.setState({ expandedRowKeys: keys });
    }
  };

  // this function is called when expand/collapse app button is clicked
  expandRows = () => {
    let keys = this.networkModel.networks.map(record => {
      return (!record.is_wan_network || this.state.isUCGNode) && record.id;
    });
    this.setExpandedRowKeys(
      this.state.expandedRowKeys.length !== keys.length ? keys : [],
    );
  };

  onExpand = (expanded, record) => {
    if (!record.is_wan_network || this.state.isUCGNode) {
      let keys = this.state.expandedRowKeys;
      let index = keys.findIndex(val => {
        return val === record.id;
      });
      if (expanded) {
        if (index === -1) {
          keys.push(record.id);
        }
        this.setExpandedRowKeys(keys);
      } else {
        if (index !== -1) {
          keys.splice(index, 1);
        }
        this.setExpandedRowKeys(keys);
      }
    }
  };

  setExpandedRowKeys = keys => {
    this.setState({ expandedRowKeys: keys });
  };

  updateHelp = data => {
    this.props.UiStore.SetStoreData("helpData", data);
  };

  deleteAfterCall = () => {
    this.resetSelectedNetwork();
    let params = this.props.params;
    params.page = 0;
    params.size = 1;
    //intialize paginated api call
    NetworkController.getNetworks(params, true, false, true).then(resp => {
      if (resp && resp.length > 0) {
        this.updateNodesNetworkData(
          this.networkModel.pageable.total_count / 100,
          [],
          true,
        );
      }
    });
  };

  //intialize paginated api call based on total available count
  updateNodesNetworkData(totalCount, availableNetworks, loading) {
    if (totalCount > 0) {
      let allParams = [];
      for (let page = 0; page < totalCount; page++) {
        let params = this.props.params || {};
        params.page = page;
        params.size = 100;
        allParams.push(params);
      }
      NetworkController.getAllNetworksForDropdown(allParams, loading).then(
        resp => {
          if (resp) {
            /*API CALL every 1 Minute Intiated HERE. Based on delete_at constraints*/
            if (this.props.getNetworksByInterval) {
              this.props.getNetworksByInterval();
            }
          }
        },
      );
    }
  }

  checkForNetworkLength = (networks, node_id, cluster_id = null) => {
    let currentNetworks = networks.filter(network => {
      if (!cluster_id) {
        if (network && network.node) return network.node.id === node_id;
      } else {
        if (network && network.cluster) {
          return network.cluster.id === cluster_id;
        }
      }
    });
    return currentNetworks.length;
  };

  checkForDynamicNetworkLength = (networks, node_id, cluster_id = null) => {
    let currentNetworks = networks.filter(network => {
      if (!cluster_id) {
        if (network && network.node)
          return network.node.id === node_id && network.type === "DYNAMIC";
      } else {
        if (network && network.cluster) {
          return (
            network.cluster.id === cluster_id && network.type === "DYNAMIC"
          );
        }
      }
    });
    return currentNetworks.length;
  };
  /**
   * GET NETWORK ROUTE
   */
  getCurrentRoute = () => {
    let current_path = window.location.pathname.split("/");
    current_path = current_path.splice(0, 5);
    current_path = current_path.join("/");
    return current_path;
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
  };

  selectNetwork = (record, selected, selectedRows) => {
    this.setState({
      selectedNetwork: selectedRows,
    });
  };

  selectNetworkAll = (selected, selectedRows, changeRows) => {
    if (selectedRows) {
      // Filter non deleted networks only
      selectedRows = selectedRows.filter(nw => {
        return nw && !nw.isDeleted;
      });
    }
    this.setState({
      selectedNetwork: selectedRows,
    });
  };

  resetSelectedNetwork = () => {
    this.setState({ selectedNetwork: [] });
  };

  isVrrpSwitchInprogress = network_id => {
    let vrrpSwitchInprogress = false;
    let election_network_id =
      this.clusterModel.cluster &&
      this.clusterModel.cluster.config &&
      this.clusterModel.cluster.config.election_network_id &&
      this.clusterModel.cluster.config.election_network_id;
    if (
      this.state.currentClusterDetails &&
      this.state.currentClusterDetails.status &&
      this.state.currentClusterDetails.status.nodes &&
      this.state.currentClusterDetails.status.nodes.length > 0
    ) {
      this.state.currentClusterDetails.status.nodes.forEach(node => {
        if (
          node &&
          node.election_network_id &&
          node.election_network_id !== election_network_id &&
          (election_network_id === network_id ||
            node.election_network_id === election_network_id)
        ) {
          vrrpSwitchInprogress = true;
        } else {
          vrrpSwitchInprogress = false;
        }
      });
    } else {
      vrrpSwitchInprogress = false;
    }
    return vrrpSwitchInprogress;
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelect: this.selectNetwork,
      onSelectAll: this.selectNetworkAll,
      getCheckboxProps: record => ({
        disabled:
          record.isDeleted ||
          record.is_wan_network ||
          record.is_inheritance ||
          (this.clusterModel.cluster &&
            this.clusterModel.cluster.config &&
            this.clusterModel.cluster.config.election_network_id &&
            this.clusterModel.cluster.config.election_network_id ===
              record.id) ||
          this.isTanSwitchInprogress(record.id), // Column configuration not to be checked
      }),
      type: this.props.selectionType ? this.props.selectionType : "checkbox",
    };
    const electionNwId =
      this.clusterModel.cluster &&
      this.clusterModel.cluster.config &&
      this.clusterModel.cluster.config.election_network_id
        ? this.clusterModel.cluster.config.election_network_id
        : "";

    this.columns = [
      {
        title: (
          <span
            title={getCurrentlocaleText("network.form.name.placeholder.text")}
          >
            {getCurrentlocaleText("network.form.name.placeholder.text")}
          </span>
        ),
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: "20%",
        render: (text, record) => {
          return (
            <div>
              <span
                className="iot-table-column-overflow"
                title={
                  record.isDeleted ? record.name + " (Deleting)" : record.name
                }
              >
                {record.name}
              </span>
              {record.isDeleted ? (
                <span className="iotium-danger iot-deleting-text-block">
                  {" "}
                  {getCurrentlocaleText("deleting.text")}
                </span>
              ) : null}
              <br />
              {record.id && this.isVrrpSwitchInprogress(record.id) && (
                <Tag className="iot-tag-label-color" closable={false}>
                  {getCurrentlocaleText(
                    "cluster.network.switching.clustering.text",
                  )}
                </Tag>
              )}
              {record.id && this.isTanSwitchInprogress(record.id) && (
                <Tag className="iot-tag-label-color" closable={false}>
                  {getCurrentlocaleText("allow.vrrp.network.help2")}
                </Tag>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText("general.form.label.text").replace(
              ":",
              "",
            )}
          >
            {getCurrentlocaleText("general.form.label.text").replace(":", "")}
          </span>
        ),
        dataIndex: "label",
        key: "label",
        render: (text, record) => {
          return (
            <div>
              <span>
                {this.getTagKeyValues(record.metadata).length > 0 ? (
                  <LabelProvider
                    key={record.id}
                    metadata={record.metadata}
                    tags={this.getTagKeyValues(record.metadata)}
                    showPopupLabel={false}
                    viewOnly={true}
                  />
                ) : (
                  "-"
                )}
              </span>
            </div>
          );
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "network.form.interface.placeholder.text",
            )}
          >
            {getCurrentlocaleText("network.form.interface.placeholder.text")}
          </span>
        ),
        dataIndex: "interfaces",
        key: "interfaces",
      },
      {
        title: <span title={"Network CIDR"}>{"Network CIDR"}</span>,
        //dataIndex: "cidr",
        key: "cidr",
        ellipsis: true,
        render: record => {
          return <span>{record.cidr ? record.cidr : "-"}</span>;
        },
      },
      {
        title: (
          <span
            title={getCurrentlocaleText(
              "node.list.table.node_connected_network.label.text",
            )}
          >
            {getCurrentlocaleText(
              "node.list.table.node_connected_network.label.text",
            )}
          </span>
        ),
        key: "connectedNwk",
        ellipsis: true,
        render: (text, record) => {
          return (
            <div style={{ display: "flex" }}>
              <ConnectedNodes
                canShowPopOverHelp={false}
                isVirtualNode={this.state.isVirtualNode}
                showRepNetwork={true}
                networkId={record.id}
                cursor="pointer"
                showPeers={
                  !record.is_inheritance ||
                  (record.is_inheritance && record.isCandidate)
                }
              />
              {this.props.AuthStore.IsPermitted("REPNET_HIERARCHY:VIEW") &&
                this.state.isVirtualNode &&
                !this.props.params.cluster_id &&
                this.inodeModel &&
                this.inodeModel.inode &&
                this.inodeModel.inode.cloud_route &&
                this.inodeModel.inode.cloud_route
                  .representation_network_automation &&
                record.connected_networks &&
                record.connected_networks.length > 0 &&
                !record.is_wan_network && (
                  <div style={{ marginLeft: 10 }}>
                    <RepNetHierarchy
                      networkData={record.connected_networks}
                      virtualNodeName={
                        this.props.InodeViewModel &&
                        this.props.InodeViewModel.inode &&
                        this.props.InodeViewModel.inode.name
                      }
                      cloudRouteInfo={
                        this.inodeModel &&
                        this.inodeModel.inode &&
                        this.inodeModel.inode.cloud_route
                      }
                    />
                  </div>
                )}
            </div>
          );
        },
        onCell: (record, rowIndex) => {
          return {
            onClick: () => this.expandRow(record), // click cell evnt
          };
        },
      },
      ...(!this.state.isVirtualNode &&
      checkforFeatureEnabled(this.state.orgInitialVal, "vlan_support")
        ? [
            {
              title: "VLAN ID",
              key: "vlan",
              ellipsis: true,
              render: record => {
                return <span>{record.vlan_id ? record.vlan_id : "-"}</span>;
              },
            },
          ]
        : []),
      {
        title: "",
        key: "action",
        width: "10%",
        ellipsis: true,
        render: (text, record) => {
          const view = (
            <span
              onClick={() => {
                this.setState({ showRemoteInodelistview: false });
              }}
            >
              <ActionRoute
                actionIcon="AiOutlineEye"
                actionLabel={getCurrentlocaleText("view.network.text")}
                iconButton
                title={getCurrentlocaleText("view.network.text")}
                route={this.getCurrentRoute() + "/view/" + record.id}
                iconBtnStyle={{ fontSize: 14 }}
              />
            </span>
          );
          const edit = (
            <span
              onClick={() => {
                this.setState({ showRemoteInodelistview: false });
              }}
            >
              <ActionRoute
                actionIcon="AiOutlineEdit"
                actionLabel={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                iconButton
                title={getCurrentlocaleText(
                  "network.form.useraction_edit.label.text",
                )}
                route={this.getCurrentRoute() + "/modify/" + record.id}
              />
            </span>
          );
          const deletenetwork = (
            <span
              onClick={() => {
                this.setState({ showRemoteInodelistview: false });
              }}
            >
              <ActionBtn
                className="inline"
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineDelete" />{" "}
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.title.text",
                      { 0: "Network" },
                    )}
                  </span>
                }
                HoverText={
                  <span>
                    {getCurrentlocaleText(
                      "inode.list.table.delete.button.hover.text",
                      { 0: "Network" },
                    )}
                  </span>
                }
                orgId={this.props.orgId}
                icon="AiOutlineDelete"
                // TODO - AV - This has to read from record.
                formData={record}
                map={{
                  id: record.id,
                }}
                resource="networks"
                iconButton
                action="delete"
                actionForm={NetworksForm}
                afterCall={this.deleteAfterCall.bind()}
                controller={NetworkController}
                messageContent={
                  <span>
                    <p>
                      {getCurrentlocaleText(
                        "network.delete.confirmation.message.text",
                      )}
                    </p>
                  </span>
                }
                cancelText={getCurrentlocaleText(
                  "inode.delete.confirmation.cancel.text",
                  { 0: "Network" },
                )}
                okText={
                  <span>
                    <Icons
                      type="ai"
                      name="AiOutlineDelete"
                      style={{ margin: 0 }}
                      className="iot-delete-icon"
                    />
                    {getCurrentlocaleText("inode.delete.confirmation.ok.text", {
                      0: "Network",
                    })}
                  </span>
                }
              ></ActionBtn>
            </span>
          );
          const discovery = (
            <span>
              <ActionBtn
                title={
                  <span>
                    <Icons type="ai" name="AiOutlineSwitcher" /> Discovery
                  </span>
                }
                orgId={this.props.orgId}
                action="edit"
                extendData={this.network}
                Help={false}
                iconButton={false}
                icon="AiOutlineSwitcher"
                footer={null}
                actionForm={DiscoveryForm}
                discoveryState={record.discoveryState}
                networkId={record.id}
                nodeId={this.props.params.node_id}
                width={1000}
                disabled={
                  parseFloat(getNodeVersion(this.inodeModel.inode)) >=
                  nwDeviceDiscoveryMaxVersion
                }
                {...this.props}
              >
                <Icons type="ai" name="AiOutlineSwitcher" />
              </ActionBtn>
            </span>
          );
          const menu = (
            <Menu>
              {!record.isDeleted &&
                (this.props.AuthStore.IsPermitted("NETWORK:VIEW") ||
                  this.props.AuthStore.IsPermitted("ORG:SUPPORT")) && (
                  <Menu.Item key="0" style={this.color}>
                    {view}
                  </Menu.Item>
                )}
              {!record.isDeleted &&
                !record.is_inheritance &&
                this.props.AuthStore.IsPermitted("NETWORK:CREATE") && (
                  <Menu.Item key="1" style={this.color}>
                    {edit}
                  </Menu.Item>
                )}
              {this.state.showDiscovery &&
                !record.is_wan_network &&
                !record.isDeleted &&
                !record.is_inheritance &&
                this.props.AuthStore.IsPermitted("SHOW:DISCOVERY") && (
                  <Menu.Item key="2" style={this.color}>
                    {discovery}
                  </Menu.Item>
                )}
              {!this.state.isVirtualNode &&
                !record.isDeleted &&
                !record.is_wan_network &&
                !this.deleteNetworkStatus(record.id) &&
                !this.isTanSwitchInprogress(record.id) &&
                !record.is_inheritance &&
                this.props.AuthStore.IsPermitted("NETWORK:DELETE") && (
                  <Menu.Item key="3" style={this.color}>
                    {deletenetwork}
                  </Menu.Item>
                )}
            </Menu>
          );
          return (
            <div>
              {/* actions */}
              <DropdownActionBtn
                menu={menu}
                disabled={record.isDeleted ? true : false}
              />
            </div>
          );
        },
      },
    ];
    let container = (
      <div style={{ minHeight: 250 }}>
        {/*------------------------------------  */}
        <Row gutter={16}>
          <Col span={18}>
            {/* <h3>Application Networks</h3> */}
            <div style={{ display: "inline-flex", width: "50%" }}>
              {this.networkModel.networks.length > 1 && (
                <span>
                  <a
                    style={{ fontSize: 14, marginLeft: 20 }}
                    onClick={this.expandRows}
                  >
                    <span>
                      <Icons
                        className={"valign-middle"}
                        type="ai"
                        name={
                          this.state.expandedRowKeys.length !==
                          this.networkModel.networks.length
                            ? "AiOutlinePlusSquare"
                            : "AiOutlineMinusSquare"
                        }
                      />
                      <span style={{ marginLeft: 5 }}>
                        {this.state.expandedRowKeys.length !==
                        this.networkModel.networks.length
                          ? getCurrentlocaleText(
                              "node.list.table.expand_all.label.text",
                            )
                          : getCurrentlocaleText(
                              "node.list.table.collapse_all.label.text",
                            )}
                      </span>
                    </span>
                  </a>
                </span>
              )}
            </div>
          </Col>
          <Col span={6}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {(!this.networkModel.loading &&
                this.checkForNetworkLength(
                  this.networkModel.networks,
                  this.props.params.node_id,
                  this.props.params.cluster_id,
                ) === 1 &&
                !(
                  this.inodeModel.inode.cluster &&
                  this.inodeModel.inode.cluster.id
                )) ||
              (this.props.params.cluster_id &&
                !this.networkModel.loading &&
                this.checkForDynamicNetworkLength(
                  this.networkModel.networks,
                  this.props.params.node_id,
                  this.props.params.cluster_id,
                ) < 1) ||
              (!this.state.isVirtualNode &&
                checkforFeatureEnabled(
                  this.state.orgInitialVal,
                  "vlan_support",
                ) &&
                !this.props.cluster_id &&
                this.inodeModel.inode &&
                !(
                  this.inodeModel.inode.cluster &&
                  this.inodeModel.inode.cluster.id
                ) &&
                (parseFloat(getNodeVersion(this.inodeModel.inode)) >=
                  MultiTANBaseVersion ||
                  isNaN(parseFloat(getNodeVersion(this.inodeModel.inode)))))
                ? this.props.AuthStore.IsPermitted("NETWORK:CREATE") && (
                    <ActionRoute
                      primaryButton
                      actionIcon="AiOutlinePlus"
                      title={getCurrentlocaleText(
                        "network.form.remote_rep_network.addbutton.text",
                      )}
                      route={this.getCurrentRoute() + "/modify"}
                    />
                  )
                : null}
              {this.props.AuthStore.IsPermitted("NETWORK:DELETE") && (
                <ActionBtn
                  title={
                    <span>
                      <Icons type="ai" name="AiOutlineDelete" />{" "}
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.title.text",
                        {
                          0:
                            this.state.selectedNetwork.length > 1
                              ? "Networks"
                              : "Network",
                        },
                      )}
                    </span>
                  }
                  HoverText={
                    <span>
                      {getCurrentlocaleText(
                        "inode.list.table.delete.button.hover.text",
                        {
                          0:
                            this.state.selectedNetwork.length > 1
                              ? "Networks"
                              : "Network",
                        },
                      )}
                    </span>
                  }
                  resource="network"
                  action="delete"
                  disabled={isEmpty(this.state.selectedNetwork)}
                  iconButton={true}
                  icon="AiOutlineDelete"
                  formData={this.state.selectedNetwork}
                  afterCall={this.deleteAfterCall}
                  controller={NetworkController}
                  messageContent={
                    <span>
                      <p>
                        {getCurrentlocaleText(
                          this.state.selectedNetwork.length > 1
                            ? "network.multiple.delete.confirmation.message.text"
                            : "network.delete.confirmation.message.text",
                        )}
                      </p>
                    </span>
                  }
                  warningMessage={getCurrentlocaleText(
                    this.state.selectedNetwork.length > 1
                      ? "network.multiple.delete.warning.message.text"
                      : "network.delete.warning.message.text",
                  )}
                  cancelText={getCurrentlocaleText(
                    "inode.delete.confirmation.cancel.text",
                    {
                      0:
                        this.state.selectedNetwork.length > 1
                          ? "Networks"
                          : "Network",
                    },
                  )}
                  okText={
                    <span>
                      <Icons
                        type="ai"
                        name="AiOutlineDelete"
                        style={{ margin: 0 }}
                        className="iot-delete-icon"
                      />
                      {getCurrentlocaleText(
                        "inode.delete.confirmation.ok.text",
                        {
                          0:
                            this.state.selectedNetwork.length > 1
                              ? "Networks"
                              : "Network",
                        },
                      )}
                    </span>
                  }
                ></ActionBtn>
              )}
            </div>
          </Col>
        </Row>
        {/*------------------------------------  */}
        <div>
          {!this.networkModel.loading &&
          this.checkForNetworkLength(
            this.networkModel.networks,
            this.props.params.node_id,
            this.props.params.cluster_id,
          ) === 1 &&
          !(this.inodeModel.inode.cluster && this.inodeModel.inode.cluster.id)
            ? this.props.AuthStore.IsPermitted("NETWORK:CREATE") &&
              this.state.showAlertBox &&
              !this.state.isVirtualNode &&
              this.state.autoOpen &&
              !this.state.hideNow && (
                <NetworkCreateAlert
                  nodeID={this.state.node_id}
                  isCluster={this.props.params.cluster_id ? true : false}
                  route={this.getCurrentRoute() + "/modify"}
                  {...this.props}
                  visiblity={
                    this.state.showAlertBox &&
                    !this.state.isVirtualNode &&
                    this.state.autoOpen
                  }
                  hidePopup={this.hidePopup}
                  checkForAlert={this.isAlertShowed}
                />
              )
            : null}
          <Table
            style={{ marginTop: "15px" }}
            columns={this.columns}
            dataSource={this.NetworksTable}
            pagination={false}
            expandedRowRender={record => record.extraRow}
            expandedRowKeys={this.state.expandedRowKeys}
            onExpand={this.onExpand}
            selectedRows={this.selectNetwork}
            selectAll={this.selectNetworkAll}
            rowSelection={rowSelection}
            className={"iot-table"}
            rowClassName={(record, index) => {
              return record.is_wan_network ? "is-wan-network" : "";
            }}
            scroll={this.props.scroll ? this.props.scroll : {}}
          />
        </div>
      </div>
    );
    return (
      <LoadingComponent loading={this.networkModel.loading}>
        {container}
      </LoadingComponent>
    );
  }
}

export default NetworksList;
