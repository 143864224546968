import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import axios from "axios";
import { Modal } from "antd";

@inject("AuthStore", "ModelStore", "UiStore")
@observer
class Logout extends Component {
  componentDidMount() {
    let org = this.props.AuthStore.loggedInUserOrg;
    if (org && org.is_central_auth) {
      // whatever happens clears client data
      this.props.ModelStore.Logout(this.props.history);
      this.props.UiStore.SetLoginStatus(false);
      this.props.AuthStore.resetStore();
      this.props.UiStore.resetStore();

      setTimeout(() => {
        Modal.destroyAll();
      }, 100);
      window.location.pathname = "/api/v1/logout";
    } else {
      let Logout = axios.post("/api/v1/logout");
      Logout.then(response => {
        localStorage.removeItem("currentOrgId");
        // just skip
      }).catch(error => {
        console.log("logout failed on remote server.");
        console.log(error.response.data);
      });

      // whatever happens clears client data
      this.props.ModelStore.Logout(this.props.history);
      this.props.UiStore.SetLoginStatus(false);
      this.props.AuthStore.resetStore();
      this.props.UiStore.resetStore();

      setTimeout(() => {
        Modal.destroyAll();
      }, 100);
    }
  }

  render() {
    if (
      !this.props.AuthStore.loggedInUserOrg ||
      !this.props.AuthStore.loggedInUserOrg.is_central_auth
    ) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: this.props.location } }}
        />
      );
    }
  }
}

export default Logout;
